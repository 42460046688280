import styled from 'styled-components';
import { isNumber } from 'lodash-es';

const Card = styled.div<{ width?: any, height?: any, minHeight?: any, variant?: string, orientation?: string }>`
  display: flex;
  border-radius: ${({ theme }) => theme.radii.default};
  background-color: ${({ theme }) => theme.colors.white};
  width: ${({ width }) => `${width ? isNumber(width) ? `${width}px` : width : '100%'}`};
  height: ${({ height }) => `${height ? isNumber(height) ? `${height}px` : height : '100%'}`};
  overflow: hidden;

  ${({ minHeight }) => minHeight && `
    min-height: ${isNumber(minHeight) ? `${minHeight}px` : minHeight};
  `};

  ${({ orientation }) => orientation === 'horizontal' && `
    flex-direction: column;
  `};

  ${({ variant, theme }) => (variant === 'outlined' ? `
    border: 1px solid ${theme.colors.separator};
  ` : `
    box-shadow: ${theme.boxShadow[1]};
  `)};
`;

export const Styled = {
  Card,
};
