import FlexBox from '@ess/ui/FlexBox';
import styled from 'styled-components';
import Text from '@ess/ui/Text';

const OmnibusSectionHolder = styled(FlexBox)`
  flex-direction: column;
`;

const OmnibusLabel = styled(Text)`
  padding: 10px 0;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.5px;
`;

const PriceLabel = styled(FlexBox)`
`;

export const Styled = {
  OmnibusSectionHolder,
  OmnibusLabel,
  PriceLabel,
};
