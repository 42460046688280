import styled from 'styled-components';
import { space } from 'styled-system';
import { isNumber } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Image = styled.img<{ width: number | string, height: number | string, objectFit: string, cursor: string }>`
  ${space};
  width: ${({ width }) => (width ? isNumber(width) ? `${width}px` : width : null)};
  height: ${({ height }) => (height ? isNumber(height) ? `${height}px` : height : 'auto')};
  object-fit: ${({ objectFit }) => objectFit};
  cursor: ${({ cursor }) => (cursor)};
`;

const Not_Valid_Image = styled.div<{ width: number | string, height: number | string, objectFit: string, cursor: string }>`
  ${space};
  display: flex;
  width: ${({ width }) => (width ? isNumber(width) ? `${width}px` : width : '100%')};
  height: ${({ height }) => (height ? isNumber(height) ? `${height}px` : height : '100%')};
  object-fit: ${({ objectFit }) => objectFit};
  cursor: ${({ cursor }) => (cursor)};
  background-color: #b7b7b7;
`;

const Not__Valid__Image__Icon = styled(FontAwesomeIcon)<{iconHeight: number, iconWidth: number}>`
  color: ${({ theme }) => theme.colors.white};
  width: ${({ iconWidth }) => iconWidth}px;
  height: ${({ iconHeight }) => iconHeight}px;
  margin: auto;
`;

export const Styled = {
  Not_Valid_Image,
  Not__Valid__Image__Icon,
  Image,
};
