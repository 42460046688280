import {
  faBusAlt,
  faCar,
  faPlaneDeparture,
  faTrain,
} from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from "@fortawesome/free-brands-svg-icons";

export const TRANSPORT_FLIGHT = 'TRANSPORT_FLIGHT';
export const TRANSPORT_BUS = 'TRANSPORT_BUS';
export const TRANSPORT_TRAIN = 'TRANSPORT_TRAIN';
export const OWN_TRANSPORT = 'OWN_TRANSPORT';

export const TRANSPORT_TYPES: Record<string, string> = {
  Flight: TRANSPORT_FLIGHT,
  Bus: TRANSPORT_BUS,
  Train: TRANSPORT_TRAIN,
};

export const TRANSPORT_ICONS: Record<string, IconDefinition> = {
  TRANSPORT_FLIGHT: faPlaneDeparture,
  TRANSPORT_BUS: faBusAlt,
  TRANSPORT_TRAIN: faTrain,
  OWN_TRANSPORT: faCar,
};
