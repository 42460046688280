import { isEmpty } from 'lodash-es';

export const FLIGHT = 'Flight';

export const BUS = 'Bus';

export const FLIGHT_ONLY = 'flightOnly';

export const FLIGHT_PACKAGE = 'flightPackage';

export const BUS_PACKAGE = 'busPackage';

export const HOTEL_ONLY = 'hotelOnly';

export const TRIP_ROUND_TRIP = 'tripRoundTrip';

export const INSURANCE = 'insurance';

export const PARKING = 'parking';

export const CHECK_TYPE_FUNC = (offerData: any) => {
  const {
    Transport, Accommodation, Insurance, Parking,
  } = offerData;
  const transportType = !isEmpty(Transport) ? Object.keys(Transport)[0] : undefined;

  if (transportType === FLIGHT && isEmpty(Accommodation)) {
    return FLIGHT_ONLY;
  }
  if (!isEmpty(Parking)) {
    return PARKING;
  }
  if (!isEmpty(Insurance)) {
    return INSURANCE;
  }
  if (isEmpty(Transport) && !isEmpty(Accommodation)) {
    return HOTEL_ONLY;
  }
  if (transportType === FLIGHT && !isEmpty(Accommodation)) {
    return FLIGHT_PACKAGE;
  }
  if (transportType === BUS && !isEmpty(Accommodation)) {
    return BUS_PACKAGE;
  }
  if (!isEmpty(Accommodation) && Accommodation.Type.Id === 'R') {
    return TRIP_ROUND_TRIP;
  }
  return undefined;
};
