type zIndexNamedValuesEnum = 'default' | 'dropdown' | 'tooltip' | 'modal'

type zIndexValues = {
  [key in zIndexNamedValuesEnum]: number;
}

const zIndexArrayValues: number[] = [0, 100, 200, 999, 9999, 99999];

const zIndexNamedValues: zIndexValues = {
  default: zIndexArrayValues[0],
  dropdown: zIndexArrayValues[3],
  tooltip: zIndexArrayValues[4],
  modal: zIndexArrayValues[5],
};

export default {
  zIndex: { ...zIndexArrayValues, ...zIndexNamedValues },
};
