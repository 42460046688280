import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const searchResultsSlice = createSlice({
  name: 'searchResults',
  initialState,
  reducers: {
    setRegionListType: (state, action) => ({
      ...state,
      regionsType: action.payload,
    }),
    setMultiRoomMode: (state, action) => ({
      ...state,
      multiRoomMode: action.payload,
    }),
    setHoveredMarker: (state, action) => {
      const { results } = state;
      return {
        ...state,
        results: {
          ...results,
          hoveredMarker: action.payload,
        },
      };
    },
    setInitialOrder: (state, action) => ({
      ...state,
      initialOrder: action.payload,
    }),
    setView: (state, action) => ({
      ...state,
      view: action.payload,
      extendedViewDetails: false,
    }),
    setExtendedViewDetails: (state, action) => ({
      ...state,
      extendedViewDetails: action.payload,
    }),
    setFilterField: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        fields: {
          ...state.filters.fields,
          [action.payload.fieldName]: action.payload.values,
        },
      },
    }),
    toggleMap: (state) => ({
      ...state,
      showMap: !state.showMap,
    }),
  },
});

export const {
  setHoveredMarker,
  setView,
  setRegionListType,
  setInitialOrder,
  setFilterField,
  setMultiRoomMode,
  setExtendedViewDetails,
} = searchResultsSlice.actions;

export default searchResultsSlice.reducer;
