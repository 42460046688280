import { IconDefinition } from "@fortawesome/free-brands-svg-icons";

import {
  faSkiBootSki,
  faSailboat,
  faMaskSnorkel,
  faGolfFlagHole,
  faGolfClub,
  faTennisBall,
  faCourtSport,
  faMasksTheater,
  faPersonBiking,
  faUserCowboy,
  faPersonWalking,
  faWave,
  faWheelchair,
  faSkiBoot,
  faChampagneGlasses,
  faAirConditioner,
  faAt,
  faDogLeashed,
  faPersonSledding,
  faWaterLadder,
  faDumbbell,
  faSquareH,
  faFamily,
  faCircleParking,
  faFireplace,
  faWashingMachine,
  faMarsDouble,
  faSpa,
  faPerson,
  faWifi,
  faChildReaching,
  faGrill,
  faCity,
  faTowerControl,
  faUmbrellaBeach,
  faPersonSkiLift,
  faPersonSnowboarding,
  faWater,
  faMountainSun,
  faArrowRightToCity,
  faTreePalm,
  faPassport,
  faCableCar,
  faPersonSwimming,
  faPersonDrowning,
} from "@fortawesome/pro-regular-svg-icons";

export const ATTRIBUTES_PRIO_ITEMS = [
  'facility_water_slides',
  'location_exotic_offers',
  'location_directly_on_the_beach',
  'location_without_passport_from_pl',
  'location_seaside_resorts',
  'location_ski_resorts',
  'facility_for_adult',
  'location_mountains',
  'facility_pets',
  'facility_indoor_pool',
];

export const ATTRIBUTES_EXTENDEDVIEW_MAX_ITEMS = 5;

export const ATTRIBUTES_ICONS_MAP: Record<string, IconDefinition> = {
  // Activity attribute
  activity_windsurfing: faPersonSnowboarding,
  activity_nordic_walking: faPersonWalking,
  activity_riding: faUserCowboy,
  activity_winter_sports: faSkiBootSki,
  activity_sailing: faSailboat,
  activity_diving: faMaskSnorkel,
  activity_water_sports: faPersonDrowning,
  activity_minigolf: faGolfFlagHole,
  activity_golf: faGolfClub,
  activity_tennis: faTennisBall,
  activity_sport: faCourtSport,
  activity_animation_for_adults: faMasksTheater,
  activity_bicycles: faPersonBiking,

  // Facility attributes
  facility_facilities_for_disabled: faWheelchair,
  facility_winter_equipment_rental: faSkiBoot,
  facility_night_life: faChampagneGlasses,
  facility_air_conditioning: faAirConditioner,
  facility_internet_access: faAt,
  facility_pets: faDogLeashed,
  facility_water_slides: faPersonSledding,
  facility_indoor_pool: faPersonSwimming,
  facility_outdoor_pool: faWaterLadder,
  facility_fitness_or_gym: faDumbbell,
  facility_club_hotel: faSquareH,
  facility_for_families_with_childrens: faFamily,
  facility_parking: faCircleParking,
  facility_fireplace: faFireplace,
  facility_home_appliances: faWashingMachine,
  facility_gay_friendly: faMarsDouble,
  facility_wellness: faSpa,
  facility_for_adult:  faPerson,
  facility_free_wifi: faWifi,
  facility_kindergarten: faChildReaching,
  facility_barbecue: faGrill,

  // Location attributes
  location_near_the_center: faArrowRightToCity,
  location_near_airport: faTowerControl,
  location_directly_on_the_beach: faUmbrellaBeach,
  location_ski_resorts: faPersonSkiLift,
  location_seaside_resorts: faWave,
  location_lakes: faWater,
  location_mountains: faMountainSun,
  location_city_breaks: faCity,
  location_exotic_offers: faTreePalm,
  location_without_passport_from_pl: faPassport,
  location_near_the_slope: faCableCar,
}
