import { IDictionary } from '@ess/types';

export const MULTIPLE_OPTION_VALUE = 'MULTIPLE_OPTION';

/**
 * Search initiators
 */

export const TOP_OFFERS_BTN = 'TOP_OFFERS_BTN';
export const SEARCH_BTN = 'SEARCH_BTN';
export const SEARCH_BY_MAP_BTN = 'SEARCH_BY_MAP_BTN';
export const REGIONS_NEXT_BUTTON = 'REGIONS_NEXT_BUTTON';

/**
 * Price types
 */

export const TOTAL_PRICE = 'Total';
export const FIRST_PERSON_PRICE = 'FirstPerson';

/**
 * Search request view types
 */

export const REGION_LIST = 'regionList';
export const SKI_REGION_LIST = 'skiRegionList';
export const GROUPED_LIST = 'groupedList';
export const OFFER_LIST = 'offerList';
export const UNFILTERED_FIELD_VALUES = 'fieldValues';
export const FIELD_VALUES = 'fieldValues';

/**
 * Search results view types
 */

export const GROUPED_VIEW = 'GROUPED_VIEW';
export const UNGROUPED_VIEW = 'UNGROUPED_VIEW';
export const GROUPED_EXTENDED_VIEW = 'GROUPED_EXTENDED_VIEW';

export const RESULTS_SORT_IN_COLUMN = 'RESULTS_SORT_IN_COLUMN';
export const RESULTS_SORT_IN_LIST = 'RESULTS_SORT_IN_LIST';

export enum EMapTypes {
  roadmap = 'roadmap',
  terrain = 'terrain',
  satellite = 'satellite',
  hybrid = 'hybrid',
}
/**
 * Search by Map
 */

export const DEFAULT_MAP_POSITION = {
  lat: 41.175832106744316,
  lng: -4.584625247465283,
};

/**
 * SessionStorage key names.
 * Used for persisted search form protohash.
 */

export const SEARCH_TYPES_SESSION_STORAGE_KEY: IDictionary<string> = {
  trip: 'REGULAR_SEARCH_MODE_B',
  cruise: 'CRUISE_SEARCH_MODE_B',
  camp: 'CAMP_SEARCH_MODE_B',
  bus: 'BUS_SEARCH_MODE_B',
  ski: 'SKI_SEARCH_MODE_B',
  roundtrip: 'ROUNDTRIP_SEARCH_MODE_B',
};

export const FIELDS_SORTING: IDictionary<any> = {
  'Base.Candy': {
    sortBy: 'label',
  },
  'Base.StartDateDayOfWeek': {
    sortBy: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  },
};

export const HOTEL_EXTENDED_SORTING_COLUMNS = [
  {
    label: 'hotel',
    width: 50,
    isSortable: true,
    value: 'Base.XCode.Name',
  },
  {
    label: 'lbl_category',
    width: 80,
    isCentered: true,
    isSortable: true,
    value: 'Accommodation.Category',
  },
  {
    label: 'lbl_rating',
    width: 50,
    isCentered: true,
    isSortable: true,
    value: 'Accommodation.Rating',
  },
  {
    label: 'price',
    width: 60,
    isCentered: true,
    isSortable: true,
    value: 'Base.Price.FirstPerson',
  },
  {
    label: 'lbl_sum',
    width: 60,
    isCentered: true,
    isSortable: true,
    value: 'Base.Price.Total',
  },
];

export const HOTEL_SORTING_COLUMNS = [
  {
    label: '',
    width: 30,
    isSortable: false,
  },
  {
    label: 'region',
    width: 110,
    isSortable: false,
  },
  {
    label: 'hotel',
    width: 'fillSpace',
    isSortable: true,
    value: 'Base.XCode.Name',
  },
  {
    label: 'lbl_category_short',
    width: 36,
    isCentered: true,
    isSortable: true,
    value: 'Accommodation.Category',
  },
  {
    label: 'lbl_rating_short',
    width: 36,
    isCentered: true,
    isSortable: true,
    value: 'Accommodation.Rating',
  },
  {
    label: 'price',
    width: 62,
    isCentered: true,
    isSortable: true,
    value: 'Base.Price.FirstPerson',
  },
  {
    label: 'lbl_sum',
    width: 62,
    isCentered: true,
    isSortable: true,
    value: 'Base.Price.Total',
  },
];

export const OFFER_SORTING_COLUMNS = [
  {
    label: 'lbl_depature',
    width: 75,
    isSortable: true,
    value: 'Base.DepartureLocation',
  },
  {
    label: 'lbl_date',
    width: 55,
    isSortable: true,
    value: 'Base.StartDate',
  },
  {
    label: 'lbl_service',
    width: 'fillSpace',
    isSortable: true,
    value: 'Accommodation.XService',
  },
  {
    label: 'price',
    width: 62,
    isCentered: true,
    isSortable: true,
    value: 'Base.Price.FirstPerson',
  },
  {
    label: 'lbl_sum',
    width: 62,
    isCentered: true,
    isSortable: true,
    value: 'Base.Price.Total',
  },
];
