import React from 'react';

import { Styled } from './ModalOverlay.styles';

type ModalOverlayProps = {
  onClick?: () => void
  zIndex?: number;
}

const ModalOverlay = ({ onClick = undefined, zIndex = 99999 }: ModalOverlayProps) => {
  const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (onClick) {
      onClick();
    }
  };

  return (
    <Styled.ModalOverlay zIndex={zIndex} onClick={onClickHandler}/>
  );
};

export default ModalOverlay;
