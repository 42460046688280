import styled from 'styled-components';
import { space } from 'styled-system';
import { darken } from 'polished';

const Checkbox = styled.label<{ disabled?: boolean, transition?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  min-height: auto !important;
  min-width: 0;
  margin: 0;
  color: ${({ theme, disabled }) => (!disabled ? theme.colors.textColor : theme.colors.gray)};
  user-select: none;
  ${space}

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const CheckboxInput = styled.input<{ disabled: boolean, fillColor: string | undefined }>`
  position: absolute;
  width: 1em !important;
  height: 1em !important;
  margin: 0  !important;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;

  &:checked + svg {
    rect {
      fill: ${({ theme, disabled, fillColor }) => (!disabled ? fillColor || theme.colors.white : theme.colors.gray)};
      stroke: ${({ theme, disabled, fillColor }) => (!disabled ? fillColor || theme.colors.navy : theme.colors.gray)};
    }

    polyline {
      stroke: ${({ theme }) => theme.colors.navy};
    }
  }
}
`;

const CheckboxIcon = styled.svg<{ disabled?: boolean, color?: string, transition?: boolean, size?: string }>`
  flex-shrink: 0;
  align-self: center;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin-right: ${({ spacing, theme }) => spacing ?? theme.space.small};
  color: ${({
    theme,
    disabled,
    color,
  }) => (!disabled ? darken(0.15, color || theme.colors.gray) : color || theme.colors.gray)};
`;

export const Styled = {
  Checkbox,
  CheckboxInput,
  CheckboxIcon,
};
