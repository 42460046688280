import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/pro-regular-svg-icons';

import { useLiveRoomStore } from '@liveroom/hooks';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

const defaultProps = {
  title: undefined,
};

const Header = () => {
  const { agency, description } = useLiveRoomStore((state) => ({
    agency: state.config.agency,
    description: state.offers.description,
  }));

  return (
    <FlexBox
      width="100%"
      height="auto"
      justifyContent="center"
      flexDirection="column"
      flexShrink={0}
    >
      {agency && (
        <FlexBox py="small" px="large" width="100%">
          <FlexBox
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{
              xxs: 'column',
              xs: 'column',
              sm: 'column',
              md: 'column',
              lg: 'row',
            }}
          >
            <FlexBox
              width={{
                xxs: '250px',
                xs: '250px',
                sm: '250px',
                md: '300px',
                lg: '300px',
              }}
              height={100}
              pr="large"
            >
              <img
                src={agency?.logoUrl}
                alt={agency?.name}
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: ' 100%',
                }}
              />
            </FlexBox>

            <FlexBox
              alignItems="center"
              flexDirection={{
                xxs: 'column',
                xs: 'column',
                sm: 'row',
                md: 'row',
                lg: 'row',
                xl: 'row',
              }}
            >
              <FlexBox
                alignItems="center"
                mr={{
                  xxs: '0px',
                  xs: '0px',
                  sm: 'medium',
                  md: 'medium',
                  lg: 'large',
                  xl: 'large',
                }}
              >
                <FlexBox width={40} alignItems="center" justifyContent="center">
                  <Text fontSize="heading" lineHeight={2}>
                    <FontAwesomeIcon icon={faEnvelope}/>
                  </Text>
                </FlexBox>
                <Text
                  whiteSpace="nowrap"
                  fontSize={{
                    xxs: 'head',
                    xs: 'head',
                    sm: 'title',
                    md: 'title',
                    lg: '20px',
                    xl: 'heading',
                  }}
                  lineHeight={2}
                >
                  {agency?.contact?.email}
                </Text>
              </FlexBox>
              <FlexBox
                alignItems="center"
                mt={{
                  xxs: 'tiny',
                  xs: 'tiny',
                  sm: '0px',
                  md: '0px',
                  lg: '0px',
                  xl: '0px',
                }}
              >
                <FlexBox width={40} alignItems="center" justifyContent="center">
                  <Text fontSize="heading" lineHeight={2}>
                    <FontAwesomeIcon icon={faPhone}/>
                  </Text>
                </FlexBox>

                <Text
                  whiteSpace="nowrap"
                  fontSize={{
                    xxs: 'head',
                    xs: 'head',
                    sm: 'title',
                    md: 'title',
                    lg: '20px',
                    xl: 'heading',
                  }}
                  lineHeight={2}
                >
                  {agency?.contact?.tel}
                </Text>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
      {description && (
        <FlexBox
          width="100%"
          justifyContent={{
            xxs: 'center',
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'flex-start',
          }}
          style={{
            background: 'var(--section-header-background-color)',
          }}
        >
          <Text
            px="large"
            py="small"
            lineHeight="24px"
            color="textSectionHeader"
            fontSize="title"
            textAlign={{
              xxs: 'center',
              xs: 'center',
              sm: 'center',
              md: 'center',
              lg: 'left',
            }}
          >
            {description}
          </Text>
        </FlexBox>
      )}
    </FlexBox>
  );
};

Header.defaultProps = defaultProps;

export {
  Header,
};
