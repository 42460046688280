import React, { useEffect, useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';

import Box from '@ess/ui/Box';

import { ScrollBar } from '@ess/components/ScrollBar';

const DEFAULT_HEIGHT = 35;

const MenuList = (props: any) => {
  const {
    children, getStyles, isLoading, options, selectProps,
  } = props;
  const scrollRef = useRef<any>(null);
  const childrenArray = React.Children.toArray(children);
  const styles = getStyles('menuList', props);

  const virtualizedList = useVirtualizer({
    count: childrenArray.length,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => DEFAULT_HEIGHT,
  });

  const virtualItems = virtualizedList.getVirtualItems();

  const height = isLoading || !childrenArray.length
    ? DEFAULT_HEIGHT
    : childrenArray.length > 10
      ? styles.maxHeight
      : virtualizedList.getTotalSize();

  useEffect(() => {
    if (selectProps?.value && childrenArray.length > 10) {
      const index = options.findIndex((item: any) => item.value.toString() === selectProps.value.value.toString());
      if (index > 0) {
        virtualizedList.scrollToIndex(index);
      }
    }
  }, [selectProps.value, options]);

  return (
    <ScrollBar
      ref={scrollRef}
      width="100%"
      height={height}
    >
      <Box style={{
        height: `${virtualizedList.getTotalSize()}px`,
        width: '100%',
        position: 'relative',
      }}
      >
        {virtualItems.map((virtualItem) => {
          const index = virtualItem.index;

          return (
            <Box
              ref={virtualizedList.measureElement}
              key={virtualItem.key}
              data-index={virtualItem.index}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <Box width="100%">
                {childrenArray[index]}
              </Box>
            </Box>
          );
        })}
      </Box>
    </ScrollBar>
  );
};

export default MenuList;
