import { isObject, isString, mergeWith } from 'lodash-es';

export const offerMergeCustomizer = (obj:any, src:any):any => {
  if (isObject(src) && obj !== undefined) {
    const newsrc:any = src;
    if (newsrc.Out !== undefined && obj.Out !== undefined) {
      if (newsrc.Out.length > obj.Out.length && obj.Out.length === 1) {
        // eslint-disable-next-line no-param-reassign
        obj.Out.length = newsrc.Out.length;
        // eslint-disable-next-line no-param-reassign
        obj.Out[obj.Out.length - 1] = { Destination: obj.Out[0].Destination };
        // eslint-disable-next-line no-param-reassign
        delete obj.Out[0].Destination;
      }
    }
    if (newsrc?.Ret && newsrc?.Ret?.length && obj?.Ret !== undefined) {
      if (obj?.Ret && obj.Ret.length === 1 && newsrc.Ret.length > obj.Ret.length) {
        // eslint-disable-next-line no-param-reassign
        obj.Ret.length = newsrc.Ret.length;
        // eslint-disable-next-line no-param-reassign
        obj.Ret[obj.Ret.length - 1] = { Destination: obj.Ret[0].Destination };
        // eslint-disable-next-line no-param-reassign
        delete obj.Ret[0].Destination;
      }
    }

    Object.entries(src).forEach(([key, val]) => {
      if (isString(val)) {
        if (newsrc[`${key}`] !== undefined) {
          if ((isString(newsrc[`${key}`]) && newsrc[`${key}`] === 'unspecified')) {
            delete newsrc[`${key}`];
            return;
          }
        }

        if (obj[key] !== undefined && obj[key] !== val) {
          newsrc[`${key}_old`] = obj[key];
        }
      }
      // } else if (key === 'Out' || key === 'Ret') {
      //     if (src[key] !== undefined && obj[key] !== undefined) {
      //         if (src[key].length > obj[key].length && obj[key].length === 1) {
      //             // eslint-disable-next-line no-param-reassign
      //             obj[key].length = src[key].length;
      //             // eslint-disable-next-line no-param-reassign
      //             obj[key][obj[key].length - 1] = { Destination: obj[key][0].Destination };
      //             // eslint-disable-next-line no-param-reassign
      //             delete obj[key][0].Destination;
      //         }
      //     }
      // }
    });
    mergeWith(obj, newsrc, offerMergeCustomizer);
    return obj;
  }
};
