import React, { memo } from 'react';

import { Styled } from './Switch.styles';

type SwitchProps = {
  name?: string,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  disabled?: boolean
};

const animationVariants = {
  isOn: {
    x: 22,
  },
  isOff: {
    x: 4,
  },
};

const Switch = ({
  onChange,
  name = '',
  checked = false,
  disabled = false,
}: SwitchProps) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Styled.Switch__Label>
      <Styled.Switch
        disabled={disabled}
        checked={checked}
      >
        <Styled.Switch__Base
          initial={false}
          animate={checked ? 'isOn' : 'isOff'}
          variants={animationVariants}
        >
          <Styled.Switch__Input
            type="checkbox"
            name={name}
            onChange={changeHandler}
            checked={checked}
            disabled={disabled}
          />
          <Styled.Switch__Thumb/>
        </Styled.Switch__Base>
        <Styled.Switch__Track/>
      </Styled.Switch>
    </Styled.Switch__Label>
  );
};

export default memo(Switch);
