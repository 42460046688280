import styled from 'styled-components';

const TextInput__Icon = styled.div<{ isClearIcon?: boolean, isStartIcon: boolean, isError?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 34px;
    height: 100%;
    z-index: 12;
    font-size: ${({ isClearIcon }) => (isClearIcon ? '12px' : '15px')};
    pointer-events: ${({ isClearIcon }) => (isClearIcon ? 'all' : 'none')};
    color: ${({ theme, isError }) => (isError ? theme.colors.errorRed : theme.colors.textColor)};

    ${({ isStartIcon }) => (isStartIcon ? 'left: 0;' : 'right: 0;')};
`;

const TextInput = styled.input<{ hasError?: boolean, readOnly?: boolean, height?: number, disableInterActivity?: boolean }>`
    width: 100%;
    height: ${({ height }) => (height ? `${height}px !important` : '34px !important')};
    padding: 0 6px !important;
    position: relative;
    color: ${({ theme, hasError }) => (!hasError ? theme.colors.textColor : theme.colors.errorRed)} !important;
    border-radius: ${({ theme }) => theme.radii['br-4']};
    border: 1px solid ${({ theme, disableInterActivity }) => (disableInterActivity ? 'transparent' : theme.colors.gray)};
    border-color: ${({ theme, hasError, disableInterActivity }) => (disableInterActivity ? 'transparent' : !hasError ? theme.colors.gray : theme.colors.errorRed)} !important;
    background-color: ${({ theme }) => theme.colors.white};
    transition: all 0.2s ease;
    transition-property: box-shadow, border-color;
    cursor: ${({ readOnly }) => (readOnly ? 'pointer' : 'cursor')} !important;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:focus {
      border-color: transparent !important;
      box-shadow: 0 0 0px 2.5px ${({ theme, hasError, disableInterActivity }) => (disableInterActivity ? 'transparent' : !hasError ? '#2684ff' : theme.colors.errorRed)} !important;
      z-index: 10;
    }

    &::placeholder {
      font-weight: normal;
      color: ${({ theme, hasError }) => (!hasError ? theme.colors.placeholder : theme.colors.errorRed)} !important;
      opacity: 1;
    }
`;

const TextInput__Wrapper = styled.div<{ clearIconVisible?: boolean, startIcon?: boolean, endIcon?: boolean, disabled?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > * {
      user-select: none;
    }

    ${TextInput} {
      padding-right: ${({ clearIconVisible, endIcon }) => (clearIconVisible || endIcon ? '34px !important' : '6px')};
      padding-left:  ${({ startIcon }) => (startIcon ? '34px !important' : '0')};
    }

    ${({ disabled }) => disabled && `
      ${TextInput} {
        color: #5c5c5c !important;
        background-color: #d3d6da !important;
        cursor: not-allowed !important;
        border: none !important;

        &:focus {
          outline: none !important;
          box-shadow: none !important;
          z-index: 10;
        }

         &::placeholder {
           color: #5c5c5c !important;
         }
      }

      ${TextInput__Icon} {
        color: #5c5c5c;
      }
    `}
`;

const TextInput__Inner = styled.div<{ clearIconVisible?: boolean, startIcon?: boolean, endIcon?: boolean }>`
    display: flex;
    position: relative;
    width: 100%;
`;

export const Styled = {
  TextInput,
  TextInput__Icon,
  TextInput__Wrapper,
  TextInput__Inner,
};
