import { kebabCase } from 'lodash-es';

type SetCssVariablesProps = {
  params: Record<string, any>;
  suffix?: string;
  container?: string
}

/**
 * Updates css variables dynamically.
 * @param params
 * @param suffix
 * @param container
 */
const setCssVariables = ({ params, suffix, container }: SetCssVariablesProps) => {
  const root = container
    ? document.querySelector(container) as HTMLElement
    : document.documentElement;

  if (root) {
    Object.keys(params)
      .map((name) => {
        let value = params[name];
        const propertyName = kebabCase(name);

        if (typeof value === 'number') {
          value = `${value}px`;
        }

        root.style.setProperty(`--${propertyName}${suffix ? `-${suffix}` : ''}`, value);
      });
  }
};

export {
  setCssVariables,
};
