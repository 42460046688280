import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';

const isInitialized = (key: string) => {
  const localStorageItem = localStorage?.getItem(key);
  return localStorageItem && localStorageItem === 'true';
};

const withBeforeInitialization = (
  Component: React.ComponentType<any>,
  callback?: (props: any) => any,
) => (props: any) => {
  const componentName = Component.displayName || Component.name || 'Component';
  const storageKey = `${componentName}:init`;
  const [newProps, setNewProps] = useState({});

  useEffect(() => {
    if (!isInitialized(storageKey)) {
      if (callback) {
        setNewProps(callback(props));
      }

      localStorage.setItem(storageKey, 'true');
    }

    // @ts-ignore
    window[componentName] = {
      clear: () => {
        localStorage.removeItem(storageKey);
      },
    };
  }, []);

  if (!isInitialized(storageKey)) {
    return null;
  }

  return <Component {...!isEmpty(newProps) ? { ...newProps } : { ...props }} />;
};

export {
  withBeforeInitialization,
};
