import styled from 'styled-components';
import { variant, space } from 'styled-system';

const AlertVariant = variant({
  prop: 'severity',
  key: 'alert.variants',
});

const AlertSize = variant({
  prop: 'size',
  key: 'alert.sizes',
});

const Alert = styled.div<{ showBorder?: boolean }>`
  display: flex;
  align-items: flex-start;
  border-radius: ${({ theme }) => theme.radii['br-6']};

  ${({ showBorder }) => showBorder && `
    border-width: 1px;
    border-style: solid;
  `}

  ${AlertVariant};
  ${AlertSize}
  ${space}
`;

const Alert__Message = styled.div`
  align-self: center;
  width: auto;
`;

const Alert__Icon = styled.div`
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.space.small};
  opacity: 0.8;
  font-size: 130%;
`;

export const Styled = {
  Alert,
  Alert__Icon,
  Alert__Message,
};
