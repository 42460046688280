import React, { createContext, useMemo } from 'react';

type ReactPrintProviderProps = {
  children: React.ReactNode
  triggerPrint: () => void
}

const ReactPrintContext = createContext<{
  triggerPrint:() => void
    }>({
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      triggerPrint: () => {
        window.print();
      },
    });

const ReactPrintProvider = ({ children, triggerPrint }: ReactPrintProviderProps) => {
  const value = useMemo(() => ({
    triggerPrint,
  }), []);

  return (
    <ReactPrintContext.Provider value={value}>
      {children}
    </ReactPrintContext.Provider>
  );
};

export default ReactPrintProvider;
export {
  ReactPrintContext,
};
