import React, { forwardRef } from 'react';
import {
  ColorProps, FontSizeProps, SpaceProps, FlexboxProps, LayoutProps, PositionProps, ShadowProps, BoxShadowProps, BackgroundProps, BorderRadiusProps,
} from 'styled-system';

import { Styled } from './FlexBox.styles';

type StyledSystemProps = ColorProps & FontSizeProps & SpaceProps & FlexboxProps
    & LayoutProps & PositionProps & ShadowProps & BoxShadowProps & BackgroundProps & BorderRadiusProps;

type FlexBoxProps = {
  children?: React.ReactNode
  borderRadius?: string|number
  style?: React.CSSProperties
  id?: string
  className?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  onMouseOver?:(e: React.MouseEvent<HTMLDivElement>) => void
  onMouseEnter?:(e: React.MouseEvent<HTMLDivElement>) => void
  onMouseLeave?:(e: React.MouseEvent<HTMLDivElement>) => void
  onWheel?:(e: React.WheelEvent<HTMLDivElement>) => void
  onDrop?: (e: React.DragEvent) => void
  onDragEnter?: (e: React.DragEvent) => void
  onDragLeave?: (e: React.DragEvent) => void
  onDragOver?: (e: React.DragEvent) => void
  as?: any
} & StyledSystemProps;

const defaultProps = {
  as: 'div',
  children: null,
  onClick: undefined,
  onMouseOver: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  onDrop: undefined,
  onDragEnter: undefined,
  onDragLeave: undefined,
  onDragOver: undefined,
  onWheel: undefined,
  style: undefined,
  borderRadius: 0,
  id: '',
  className: '',
};

const FlexBox = forwardRef<HTMLDivElement, FlexBoxProps>(({
  children, id, ...props
}, ref) => (
  <Styled.FlexBox
    ref={ref}
    {...{ ...id ? { id } : {} }}
    {...props}
  >
    { children }
  </Styled.FlexBox>
));

FlexBox.defaultProps = defaultProps;

export default FlexBox;
