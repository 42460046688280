import React, {
  useState, SyntheticEvent, useCallback, RefObject, useEffect, createRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { has, compact, uniq } from 'lodash-es';
import { theme } from '@tourop/config/theme';
import multiSelectsConfig from '@tourop/config/searchForm/multiSelects';
import usePopperPositioning from '@ess/hooks/usePopperPositioning';
import StateManager, { OptionTypeBase } from 'react-select';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { isMobile, isMobileOnly } from 'react-device-detect';
import useOnClickOutside from '@ess/hooks/useOnClickOutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../../Button';
import FlexBox from '../../../../FlexBox';
import Checkbox from '../../../../Form/Checkbox';
import Modal from '../../../../Modal';
import TextInput from '../../../../Form/TextInput';
import { TOptions } from '../../AdvancedSelect/AdvancedSelect';
import MenuGridSection from './MenuGridSection';
import Drawer from '../../../../Drawer';

const getMultiselectConfig = (fieldName: string) => (
  has(multiSelectsConfig.modal, fieldName)
    ? multiSelectsConfig.modal[fieldName]
    : multiSelectsConfig.defaultModal
);

type TMenuGrid = {
  name: string
  label: string
  options: TOptions
  ungroupedOptions: TOptions
  targetElement: RefObject<HTMLElement>
  value: Array<string>
  onConfirm: (event: SyntheticEvent | undefined, values: Array<string>) => void
  onClear: (event: SyntheticEvent | undefined) => void
  toggleFavorites: (option: OptionTypeBase, event: React.MouseEvent) => void
  passRef: RefObject<StateManager> | null
  columns?: number
  filterOption: (option: OptionTypeBase, string: string) => boolean
  favorites: { [index: string]: TOptions }
  setIsGridMenuOpen: (state: boolean) => void
  isOpen: boolean
}

const MenuGrid = ({
  name,
  label,
  options,
  ungroupedOptions,
  targetElement,
  value,
  onConfirm,
  toggleFavorites,
  onClear,
  passRef,
  filterOption,
  favorites,
  isOpen,
  setIsGridMenuOpen,
  columns = 3,
}: TMenuGrid) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const [popperElement, setPopperElement] = useState(null);
  const [currentOptions, setCurrentOptions] = useState(options);
  const searchInput = createRef<HTMLInputElement>();
  const { styles, attributes } = usePopperPositioning({
    targetElementRef: targetElement,
    popperElementRef: popperElement,
    applyMaxSizeEnabled: true,
    zIndex: theme.zIndex.modal,
    padding: 15,
  });

  const [checkedOptions, setCheckedOptions] = useState<Array<string>>(value);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const { gapY, gapX } = multiSelectsConfig.grid;
  const { modalMaxWidth, rows } = getMultiselectConfig(name);

  const changeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    sessionStorage.removeItem('DateRangeChangedByHand');
    const name: string = event.target.name;
    const isChecked: boolean = checkedOptions.some((item: string) => item === name);
    if (!isChecked) {
      setCheckedOptions([...checkedOptions, name]);
      onConfirm(undefined, [...checkedOptions, name]);
    } else {
      setCheckedOptions([...checkedOptions].filter((item) => (item !== name)));
      onConfirm(undefined, [...checkedOptions].filter((item) => (item !== name)));
    }
  }, [value, checkedOptions]);

  const applyClickHandler = (event: SyntheticEvent) => {
    onConfirm(event, checkedOptions);
    setIsGridMenuOpen(false);
    setInputValue('');
  };

  const clearHandler = (event: SyntheticEvent) => {
    onClear(event);
    setInputValue('');
    setCheckedOptions([]);
  };

  const onFavoritesClick = (option: OptionTypeBase, e: React.MouseEvent) => {
    toggleFavorites(option, e);
    e.preventDefault();
    e.stopPropagation();
  };

  const selectAll = () => {
    const options = inputValue.length > 0 ? currentOptions : ungroupedOptions;
    if (allChecked) {
      setCheckedOptions((state) => state.filter((item) => !options.map((item) => item.value).includes(item)));
    } else {
      setCheckedOptions((state) => uniq([...state, ...options.map((item) => item.value)]));
    }
  };

  useEffect(() => {
    setCheckedOptions(value);
  }, [value]);

  useEffect(() => {
    const toCheck = [...(inputValue.length > 0 ? currentOptions : ungroupedOptions)];
    const currentOptionsSelected = toCheck.filter((item) => checkedOptions.includes(item.value));
    setAllChecked(currentOptionsSelected.length > 0 && currentOptionsSelected.length === toCheck.length);
  }, [checkedOptions, inputValue, currentOptions, ungroupedOptions]);

  const [appendTo, setAppendTo] = useState<HTMLDivElement | undefined>(undefined);
  useEffect(() => {
    if (passRef?.current?.select.controlRef) {
      setAppendTo(passRef.current.select.controlRef);
    }
  }, [passRef]);

  useEffect(() => {
    if (inputValue.length) {
      const newOptions = compact(ungroupedOptions.map(
        (option: OptionTypeBase) => (filterOption(option, inputValue) ? option : undefined),
      ));
      setCurrentOptions(newOptions);
    } else {
      setCurrentOptions(options.filter((item) => item.options.length > 0));
    }
  }, [options, inputValue]);

  useEffect(() => {
    if (passRef?.current) {
      if (isOpen) {
        if (isMobile) {
          passRef.current.focus();
        } else if (searchInput.current) {
          searchInput.current.focus();
        }
      }
    }
  }, [isOpen]);

  useOnClickOutside(popperElement, () => {
    setIsGridMenuOpen(false);
    setInputValue('');
    setCheckedOptions(value);
  }, passRef?.current?.select.controlRef ? [passRef.current.select.controlRef] : []);

  const searchInputElem = (
    <FlexBox p="10px" width="99%">
      <TextInput
        onChange={(event) => setInputValue(event.target.value)}
        value={inputValue}
        unControlled
        placeholder={t('lbl_search')}
        ref={searchInput}
        isClearable
        onClear={() => setInputValue('')}
        startIcon={<FontAwesomeIcon icon={faSearch}/>}
      />
    </FlexBox>
  );

  const searchActive = inputValue.length > 0;

  const sectionElem = (height: number) => (
    <MenuGridSection
      name={name}
      groupedOptions={currentOptions}
      maxModalContentHeight={height}
      columns={columns}
      rows={rows}
      gapX={gapX}
      gapY={gapY}
      searchActive={searchActive}
      changeHandler={changeHandler}
      checkedOptions={checkedOptions}
      onFavoritesClick={onFavoritesClick}
      theme="white"
      favorites={favorites[name]}
    />
  );

  const controls = (
    <FlexBox alignItems="center" justifyContent="space-between" p="small" width="100%">
      <Checkbox
        spacing="10px"
        label={t(allChecked ? 'lbl_uncheck_all' : 'lbl_check_all')}
        checked={allChecked}
        onChange={selectAll}
      />
      <FlexBox alignItems="center" justifyContent="flex-end">
        <Button
          variant="secondary"
          label={t('clear')}
          size="small"
          mr="small"
          width="90px"
          onClick={clearHandler}
        />
        <Button
          variant="primary"
          label={t('lbl_apply')}
          size="small"
          width="90px"
          onClick={applyClickHandler}
        />
      </FlexBox>
    </FlexBox>
  );

  return (
    isMobileOnly
      ? (
        <Drawer
          top={0}
          position="bottom"
          showOverlay
          title={label}
          isOpen={isOpen}
          onClose={() => setIsGridMenuOpen(false)}
          fixHeight
          contentStyles={{ overflow: 'hidden auto', height: '100%' }}
          clickOutsideIgnoreElements={[passRef?.current?.select.controlRef]}
          controls={controls}
        >
          <FlexBox flexDirection="column">
            {searchInputElem}
            {sectionElem(500)}
          </FlexBox>
        </Drawer>
      )
      : (
        <Modal
          ref={setPopperElement as any}
          style={{ ...styles.popper, width: modalMaxWidth, maxWidth: 'unset' }}
          title={label}
          isOpen={isOpen}
          showOverlay={false}
          width="500px"
          maxHeight={styles.popper.maxHeight}
          positionedByPopper
          theme="white"
          appendTo={appendTo}
          showCloseIcon={false}
          search
          controls={controls}
          {...attributes.popper}
        >
          {({ maxModalContentHeight }) => (
            <FlexBox flexDirection="column" width="820px;">
              {searchInputElem}
              {sectionElem(maxModalContentHeight)}
            </FlexBox>
          )}
        </Modal>
      )
  );
};

export default MenuGrid;
