import React from 'react';
import { includes } from 'lodash-es';

import { useConditionsSelector } from '@ess/v5-data-provider/components';

import Chip, { ChipGroup } from '@ess/ui/Chip';

type CampThemesProps = {
  campThemesList: any;
}

const CampThemes = ({ campThemesList }: CampThemesProps) => {
  const campThemesFilter = useConditionsSelector(
    'filter', (state) => state.Accommodation?.Camp?.Theme ?? [], undefined, false, [],
  );
  const campThemeSearch = useConditionsSelector(
    'search', (state) => state.Accommodation?.Camp?.Theme ?? [], undefined, false, [],
  );
  const selectedCampThemes = [...campThemesFilter, ...campThemeSearch];

  return (
    <ChipGroup>
      {campThemesList.map((item:any) => {
        const isHighlighted = includes(selectedCampThemes, item.Id);
        return (
          <Chip
            key={item.Id}
            label={item.Name}
            size="medium"
            variant={isHighlighted ? 'borderHighlighted' : 'border'}
          />
        );
      })}
    </ChipGroup>
  );
};

export default CampThemes;
