import {
  RefObject, useCallback, useEffect, useLayoutEffect, useState,
} from 'react';

import useEventListener from '@ess/hooks/useEventListener';

interface ISize {
  width: number
  height: number
}

interface IUseElementSize {
  size: ISize
  forceUpdate: () => void
}

/**
 * useElementSize hook.
 * Returns element size.
 * @param elementRef
 */
function useElementSize<T extends HTMLElement = HTMLDivElement>(
  elementRef: RefObject<T>,
): IUseElementSize {
  const [size, setSize] = useState<ISize>({
    width: 0,
    height: 0,
  });

  /**
   * Prevent too many rendering using useCallback
   */
  const updateSize = useCallback(() => {
    const node = elementRef?.current;
    if (node) {
      setSize({
        width: node.offsetWidth || 0,
        height: node.offsetHeight || 0,
      });
    }
  }, [elementRef]);

  /**
   * Initial size on mount
   */
  useLayoutEffect(() => {
    updateSize();
  }, []);

  useEventListener('resize', updateSize);

  return {
    size,
    forceUpdate: updateSize,
  };
}

export default useElementSize;
