import {
  Dispatch, SetStateAction, useCallback, useEffect, useState,
} from 'react';
import { isEqual } from 'lodash-es';
import * as Sentry from '@sentry/react';

type StorageTypeEnum = 'sessionStorage' | 'localStorage';

type SetValue<T> = Dispatch<SetStateAction<T>>

/**
 * useStorage hook.
 * Manages local or session storage.
 *
 * @param storageType {StorageTypeEnum}
 * @param key {string}
 * @param initialValue {any}
 */
function useStorage<T>(
  storageType: StorageTypeEnum,
  key: string,
  initialValue: T,
): [T, SetValue<T>] {
  const customEventName = storageType === 'sessionStorage' ? 'session-storage' : 'local-storage';

  // Get from storage then
  // parse stored json or return initialValue
  const readValue = useCallback((): T => {
    // Prevent build error "window is undefined" but keep working
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window[storageType]?.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      Sentry.captureException(error);
      return initialValue;
    }
  }, [initialValue, key]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue());

  const setValue: SetValue<T> = ((value?) => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      console.warn(
        `Tried setting ${storageType} key “${key}” even though environment is not a client`,
      );
    }

    try {
      const newValue = value instanceof Function ? value(readValue()) : value;

      window[storageType].setItem(key, JSON.stringify(newValue));
      setStoredValue(newValue as T);

      // We dispatch a custom event so every useStorage hook are notified
      window.dispatchEvent(new Event(customEventName));
    } catch (error) {
      Sentry.captureException(error);
      console.warn(`Error setting ${storageType} key “${key}”:`, error);
    }
  });

  const handleStorageChange = () => {
    setStoredValue((value) => {
      // Return old value on isEqual (because readValue always return new Object)
      const newValue = readValue();
      return isEqual(value, newValue) ? value : newValue;
    });
  };

  useEffect(() => {
    handleStorageChange();
  }, []);

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    window.addEventListener(customEventName, handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener(customEventName, handleStorageChange);
    };
  }, []);

  return [storedValue, setValue];
}

export default useStorage;
