import React, { forwardRef, CSSProperties } from 'react';
import {
  BackgroundColorProps,
  SpaceProps,
  LayoutProps,
  WidthProps,
  BorderRadiusProps,
  FlexShrinkProps,
} from 'styled-system';
import { isString } from 'lodash-es';

import { IDictionary, SizesEnum } from '@ess/types';

import Icon from '@ess/ui/Icon';
import Loader from '@ess/ui/Loader';
import FlexBox from '@ess/ui/FlexBox';

import { Styled } from './Button.styles';

type StyledSystemProps = SpaceProps & LayoutProps & WidthProps & BorderRadiusProps & FlexShrinkProps & BackgroundColorProps;

type VariantsEnum = 'primary' | 'secondary' | 'navy';
type ButtonTypeEnum = 'button' | 'submit' | 'reset';

type ButtonProps = {
  onMouseOver?:any;
  label: string | React.ReactElement | undefined;
  id?: string;
  size?: SizesEnum;
  width?: WidthProps;
  type?: ButtonTypeEnum;
  startIcon?: string | React.ReactNode;
  endIcon?: string | React.ReactNode;
  variant?: VariantsEnum;
  disabled?: boolean;
  isLoading?: boolean;
  style?: CSSProperties;
  onClick: (event: React.MouseEvent) => void;
  onTouchEnd?: (event: React.TouchEvent) => void;
} & StyledSystemProps;

const iconPadding: IDictionary<number> = {
  tiny: 5,
  small: 7.5,
  medium: 10,
  large: 10,
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  onClick,
  label,
  onMouseOver = undefined,
  onTouchEnd = undefined,
  type = 'button',
  id = undefined,
  startIcon = undefined,
  endIcon = undefined,
  disabled = false,
  isLoading = false,
  size = 'large',
  variant = 'primary',
  width = 'auto',
  ...props
}, ref) => {
  const icon = startIcon || endIcon;
  const iconNode = icon ? isString(icon) ? (
    <Icon
      {...{ ...size ? (startIcon ? { pr: iconPadding[size] } : { pl: iconPadding[size] }) : {} }}
      iconName={icon}
      size="inherit"
    />
  ) : (
    <FlexBox {...{ ...size ? (startIcon ? { pr: iconPadding[size] } : { pl: iconPadding[size] }) : {} }}>
      {icon}
    </FlexBox>
  ) : false;

  if (isLoading) {
    return (
      <Styled.Button
        ref={ref}
        size={size}
        type={type}
        width={width}
        variant={variant}
        disabled
        isLoading
        {...{ ...id ? { id } : {} }}
        {...props}
      >
        <Loader color="darkGray" />
      </Styled.Button>
    );
  }

  return (
    <Styled.Button
      ref={ref}
      type={type}
      width={width}
      onMouseOver={onMouseOver}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      size={size}
      {...{ ...id ? { id } : {} }}
      {...props}
    >
      <Styled.ButtonContent>
        {startIcon && iconNode}
        <Styled.ButtonText>
          {label}
        </Styled.ButtonText>
        {endIcon && iconNode}
      </Styled.ButtonContent>
    </Styled.Button>
  );
});

export default Button;
