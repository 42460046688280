import styled from 'styled-components';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  justify-content: flex-end;
  width: 100%;
  position: relative;
`;

const Chart = styled.div<{ height?: number, isExpandable?: boolean }>`
  height: ${({ height }) => `${height}px`};
  width: 100%;
  padding: 15px 30px 15px;
  position: relative;
`;
const Print__Chart = styled.div<{ height?: number, isExpandable?: boolean }>`
  height: ${({ height }) => `${height}px`};
  width: 100%;
  padding: 15px 30px 15px;
  position: relative;
`;

const Chart__ToggleButton = styled.div`
  margin: 15px 15px 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.green};
  text-decoration: underline;

  span {
    margin-right: 5px;
  }
`;

const Chart__Controls = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 0 ${({ theme }) => theme.space.small};
  border-bottom: 1px solid ${({ theme }) => theme.colors.separator};
`;

const ChartBar__Value = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  flex-grow: 1;
`;

const ChartBar__Label = styled.div`
  position: absolute;
  top: -75px;
  left: -15px;
  width: 85px;
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.textColor};
  transform: rotate(-60deg) translate(0, -100%);
  transform-origin: 100% 0;
`;

const ChartBar__Value__Inner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.navy};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-weight: bold;
  justify-content: flex-end;
  align-items: center;
  padding: 2px;
  position: relative;
  border-radius: ${({ theme }) => theme.radii.default};
  width: 100%;
  min-height: 20px;
  transition: all .3s ease-in;
`;

const ChartBar__Value__Inner__PlaceHolder = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-weight: bold;
  justify-content: flex-end;
  align-items: center;
  padding: 2px;
  position: relative;
  border-radius: ${({ theme }) => theme.radii.default};
  width: 100%;
  min-height: 20px;
  transition: all .3s ease-in;
  margin-top: auto;
`;

const ChartBar__Date__PlaceHolder = styled.div`
  display: flex;
  height: 30px;
  min-height: 40px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.gray};
`;

const ChartBar__Date = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 5px;
  height: 40px;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.navy};
  border-radius: ${({ theme }) => theme.radii.default};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.caption};
  white-space: pre;
`;

const ChartBar = styled.div<{ selected?: boolean, showLabel?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 60px;
  transition: all .3s ease-in;

  ${({ showLabel }) => showLabel && `
      padding-top: 75px;
  `}

  ${({ selected, theme }) => (selected ? `
    ${ChartBar__Value__Inner} {
       background-color: ${theme.colors.primary};
    }

    ${ChartBar__Label} {
       color: ${theme.colors.primary};
    }

    ${ChartBar__Date} {
       border-color: ${theme.colors.primary};
    }
  ` : `
    &:hover {
       ${ChartBar__Value__Inner} {
         background-color: #3b62a2;
       }

       ${ChartBar__Date} {
         border-color: #3b62a2;
       }
      }
  `)}
`;
const Anomaly__Container = styled(FlexBox)`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 9;
`;
const Anomaly__Title = styled(Text)`
  font-size: 25px;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  line-height: 28px;
  margin: auto;
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.4);
`;

export const Styled = {
  Anomaly__Title,
  Anomaly__Container,
  Print__Chart,
  ChartBar__Date__PlaceHolder,
  ChartBar__Value__Inner__PlaceHolder,
  Chart,
  Chart__ToggleButton,
  Chart__Controls,
  ChartBar,
  ChartBar__Label,
  ChartBar__Value,
  ChartBar__Value__Inner,
  ChartBar__Date,
  Container,
};
