import React from 'react';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

import FlexBox from '@ess/ui/FlexBox';
import Box from '@ess/ui/Box';

const style = {
  cursor: 'pointer',
  fontSize: '12px',
  right: 0,
  top: 0,
  zIndex: 10,
};

const Control = ({ children, ...props }) => {
  const openMenu = (event) => {
    event.stopPropagation();
    props.selectProps.setIsGridMenuOpen(!props.selectProps.isGridMenuOpen);
  };

  return (
    <Box
      position="relative"
      width="100%"
      {...props.selectProps.gridMenu ? {
        onTouchEnd: openMenu,
        onClick: openMenu,
      } : {}}
    >
      <components.Control {...props}>
        {children}
      </components.Control>
      {props.selectProps.isMobileSearchEnabled && (
        <FlexBox
          as="span"
          width="30px"
          height="34px"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          onClick={props.selectProps.onSearchIconClick}
          style={style}
        >
          <FontAwesomeIcon icon={faSearch} color="#689b0e"/>
        </FlexBox>
      )}
    </Box>
  );
};

export default Control;
