import { IConditions } from '@ess/types';

import { getParticipantsSearchSchema } from '../search';
import { encodeOfferId } from '../proto-hash';
import { getOfferHash, GetOfferHashArgs } from './getOfferHash';

type offerHashGetterArgs=Pick<GetOfferHashArgs, 'changeBook'>
export type offerHashGetterFunc=(params?:offerHashGetterArgs)=>string

export const getOfferHashGetter = (item: any, participantsList:any = undefined):offerHashGetterFunc => {
  if (!item) {
    return () => '';
  }
  return (params) => {
    let participants = participantsList;
    const offer = item?.offer ? item.offer : item;

    if (!participantsList) {
      let lastparent:any;
      for (lastparent = item?.parent?.parent; lastparent?.parent?.conditions !== undefined; lastparent = lastparent.parent);
      participants = (lastparent.conditions.p as IConditions).Base.ParticipantsList as any;
    }

    const data = encodeOfferId(offer.Base?.OfferId);

    return getOfferHash({
      operator: offer.Base?.Operator ?? '',
      offerId: offer.Base?.OfferId ?? '',
      participantList: getParticipantsSearchSchema(participants),
      ...data?.extraHotel?.code ? {
        extraHotel: {
          code: data.extraHotel.code,
          id: data.extraHotel.id,
        },
      } : {},
      ...params,
    });
  };
};
