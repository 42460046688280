import styled from 'styled-components';
import {
  space, layout, color, border, position,
} from 'styled-system';

const Box = styled.div`
  ${position}
  ${space}
  ${layout}
  ${color}
  ${border}
`;

export const Styled = {
  Box,
};
