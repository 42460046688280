const fontSizes: any = ['11px', '13px', '16px', '18px', '24px', '28px', '32px'];

fontSizes.caption = fontSizes[0];
fontSizes.body = fontSizes[1];
fontSizes.head = fontSizes[2];
fontSizes.title = fontSizes[3];
fontSizes.heading = fontSizes[4];

fontSizes.h1 = fontSizes[6];
fontSizes.h2 = fontSizes[5];

export default {
  fontSizes,
};
