import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faTimes, faQuestion, faBan,
} from '@fortawesome/pro-solid-svg-icons';

import { IDictionary } from '@ess/types';

import Text from '@ess/ui/Text';

/*
Offer statuses
 */
export const STATUS_AVAILABLE = 'STATUS_AVAILABLE';
export const STATUS_NOT_AVAILABLE = 'STATUS_NOT_AVAILABLE';
export const STATUS_ON_REQUEST = 'STATUS_ON_REQUEST';
export const STATUS_UNKNOWN = 'STATUS_UNKNOWN';
export const STATUS_LOADING = 'STATUS_LOADING';
export const CONFIRMED_TOUROPERATOR_OFFER = 'confirmed';
export const STATUS_OFFLINE = 'STATUS_OFFLINE';

export const STATUS_TYPES: IDictionary<string> = {
  available: STATUS_AVAILABLE,
  notavailable: STATUS_NOT_AVAILABLE,
  onrequest: STATUS_ON_REQUEST,
  offline: STATUS_OFFLINE,
  unknown: STATUS_UNKNOWN,
};

/*
Booking statuses
 */
export const STATUS_BOOKING_CONFIRMED = 'STATUS_BOOKING_CONFIRMED';

export const STATUS_BOOKING_OPTION = 'STATUS_BOOKING_OPTION';

export const STATUS_BOOKING_ON_REQUEST = 'STATUS_BOOKING_ON_REQUEST';

export const STATUS_BOOKING_CANCELLED = 'STATUS_BOOKING_CANCELLED';

export const STATUS_BOOKING_UNKNOWN = 'STATUS_BOOKING_UNKNOWN';

export const BOOKING_STATUS_TYPES: IDictionary<string> = {
  OK: STATUS_BOOKING_CONFIRMED,
  OP: STATUS_BOOKING_OPTION,
  RF: STATUS_BOOKING_CONFIRMED,
  RQ: STATUS_BOOKING_ON_REQUEST,
  XX: STATUS_BOOKING_CANCELLED,
  '!?': STATUS_BOOKING_UNKNOWN,
};

export const AVAILABLE_STATUSES = ['available', 'onrequest'];

export const BOOKING_STATUS_DESCRIPTIONS: IDictionary<string> = {
  STATUS_AVAILABLE: 'offer_available',
  STATUS_UNKNOWN: 'lbl_offer_status_unknown',
  STATUS_NOT_AVAILABLE: 'offer_not_available',
  STATUS_ON_REQUEST: 'offer_status_on_request',
  STATUS_OFFLINE: 'touroperator_offline',
  STATUS_LOADING: 'offer_status_loading',
};

export const STATUS_DESCRIPTIONS: IDictionary<string> = {
  STATUS_AVAILABLE: 'offer_available',
  STATUS_UNKNOWN: 'offer_not_available',
  STATUS_NOT_AVAILABLE: 'offer_not_available',
  STATUS_ON_REQUEST: 'offer_status_on_request',
  STATUS_OFFLINE: 'touroperator_offline',
  STATUS_LOADING: 'offer_status_loading',
  STATUS_BOOKING_CONFIRMED: 'lbl_booking_status_confirmed',
  STATUS_BOOKING_OPTION: 'lbl_booking_status_option',
  STATUS_BOOKING_ON_REQUEST: 'lbl_booking_status_on_request',
  STATUS_BOOKING_CANCELLED: 'lbl_booking_status_cancelled',
  STATUS_BOOKING_UNKNOWN: 'lbl_booking_status_unknown',
};

export const STATUS_COLOR: IDictionary<string> = {
  STATUS_AVAILABLE: '#080',
  STATUS_NOT_AVAILABLE: '#990000',
  STATUS_ON_REQUEST: 'orange',
  STATUS_UNKNOWN: '#990000',
  STATUS_OFFLINE: 'darkGray',
};

export const BOOKING_STATUS_COLOR: IDictionary<string> = {
  STATUS_BOOKING_CONFIRMED: '#080',
  STATUS_BOOKING_CANCELLED: '#990000',
  STATUS_BOOKING_ON_REQUEST: 'orange',
  STATUS_BOOKING_OPTION: 'orange',
  STATUS_BOOKING_UNKNOWN: '#f5642d',
};

export const STATUS_ICONS: IDictionary<React.FunctionComponent> = {
  STATUS_AVAILABLE: (props) => <FontAwesomeIcon icon={faCheck} {...props}/>,
  STATUS_NOT_AVAILABLE: (props) => <FontAwesomeIcon icon={faTimes} {...props}/>,
  STATUS_ON_REQUEST: (props) => <Text fontSize="12px" fontWeight="bold" {...props}>RQ</Text>,
  STATUS_UNKNOWN: (props) => <FontAwesomeIcon size="sm" icon={faTimes} {...props}/>,
  STATUS_OFFLINE: (props) => <FontAwesomeIcon size="sm" icon={faBan} {...props}/>,
};
