import { isEmpty, includes } from 'lodash-es';
import { IParticipantList } from '@ess/types';

import ProtoHash, { ProtoHashTypes } from '@ess/protohash';

export type GetOfferHashArgs = {
    offerId: string
    operator: string
    participantList?: IParticipantList
    changeBook?: {
        bookingNumber: string
    },
    extraHotel?: {
      code: string
      id: string
    },
    room?: number
}

/**
 * Returns offer protoHash.
 */
export const getOfferHash = ({
  offerId, operator, changeBook, participantList = [], extraHotel,
}: GetOfferHashArgs) => {
  const offerProtoHash = new ProtoHash(ProtoHashTypes.Offer);
  const offerRoomPax = participantList.length ? {
    adt: participantList.filter(
      (v:any) => includes(['ADULT', 'SENIOR'], v.code),
    ).length,
    chdDates: participantList.filter((v:any) => v.code === 'CHILD').map(
      (v:any) => v.birthdate,
    ),
    infDates: participantList.filter((v:any) => v.code === 'INFANT').map(
      (v:any) => v.birthdate,
    ),
  } : {};

  const extraHotelData = extraHotel !== undefined && !isEmpty(extraHotel) ? {
    extraHotel,
  } : {};

  const changeBookData = changeBook !== undefined && !isEmpty(changeBook) ? {
    changeBook: {
      bnr: changeBook.bookingNumber,
    },
  } : {};

  const data = {
    operator,
    id: offerId,
    ...offerRoomPax,
    ...extraHotelData,
    ...changeBookData,
  };

  return offerProtoHash.encode(data);
};
