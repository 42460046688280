import React from 'react';
import { SpaceProps } from 'styled-system';

import {
  DotsLoader,
  OvalLoader,
} from './Loaders';

type LoaderTypesEnum = 'oval' | 'dots';

type LoaderProps = {
  type?: LoaderTypesEnum,
  color?: string,
  size?: string;
} & SpaceProps;

const defaultLoader = 'oval';

const loaderMap: Record<string, React.ElementType> = {
  oval: OvalLoader,
  dots: DotsLoader,
};

const Loader = ({
  type = defaultLoader as LoaderTypesEnum,
  color = '#102c58',
  size = '20px',
  ...props
}: LoaderProps) => {
  const LoaderComponent = type && type in loaderMap
    ? loaderMap[type]
    : null;

  if (!LoaderComponent) {
    return null;
  }

  return (
    <LoaderComponent size={size} color={color} {...props}/>
  );
};

export default Loader;
