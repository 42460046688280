import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import { Styled } from '../ImageSlider.styles';

const directionIcon: any = {
  left: faChevronLeft,
  right: faChevronRight,
};

type NavigationArrowProps = {
  direction: string
  arrowStyle: string
  onClick: (event: React.MouseEvent) => void
}

const NavigationArrow = ({ direction, onClick, arrowStyle }: NavigationArrowProps) => (
  <Styled.ImageSlider__Controls__Arrow
    arrowStyle={arrowStyle}
    direction={direction}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={directionIcon[direction]}/>
  </Styled.ImageSlider__Controls__Arrow>
);

export {
  NavigationArrow,
};
