import styled from 'styled-components';
import { motion } from 'framer-motion';

const ModalOverlay = styled(motion.div)<{ zIndex?: number, show?: boolean }>`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: ${({ theme, zIndex }) => (zIndex || theme.zIndex.modal) - 1};
    background-color: ${({ show }) => (show ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};
`;

export const Styled = {
  ModalOverlay,
};
