/**
 * Base Theme config
 */

import boxShadow from './boxShadow';
import radii from './borderRadius';
import breakpoints from './breakpoints';
import colors from './colors';
import fontFamily from './fontFamily';
import fontSizes from './fontSizes';
import space from './space';
import lineHeights from './lineHeights';
import zIndex from './zIndex';

/**
 * Common components variants
 */

import alert from './variants/alert';
import button from './variants/button';
import chip from './variants/chip';

const theme = {
  ...space,
  ...boxShadow,
  ...breakpoints,
  ...colors,
  ...radii,
  ...fontFamily,
  ...fontSizes,
  ...lineHeights,
  ...zIndex,
  ...button,
  ...chip,
  ...alert,
};

export {
  theme,
};
