import React, {
  CSSProperties, forwardRef, useImperativeHandle, useRef,
} from 'react';

import { Styled } from './ScrollBar.style';

type ScrollBarProps = {
  children: React.ReactNode;
  style?: CSSProperties;
  width?: number | string;
  height?: number | string;
}

const ScrollBar = forwardRef<HTMLDivElement | null, ScrollBarProps>(({
  children,
  style = {},
  width = '100%',
  height = '100%',
}, ref) => {
  const scrollBarRef = useRef<any>(null);

  useImperativeHandle(ref, () => scrollBarRef.current);

  return (
    <Styled.ScrollBar
      ref={scrollBarRef}
      style={style}
      width={width}
      height={height}
    >
      {children}
    </Styled.ScrollBar>
  );
});

export {
  ScrollBar,
};
