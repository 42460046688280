import styled from 'styled-components';

const NavigationArrowsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const NavigationArrow = styled.div<{ direction: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  bottom: 0;
  cursor: pointer;
  font-size: 14px;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 1;
  box-shadow: ${({ theme }) => theme.boxShadow[1]};
  border-radius: 100%;
  width: 36px;
  height: 36px;
  margin-top: -18px;

  ${({ direction }) => (direction === 'left' ? 'left: 10px;' : 'right: 10px;')};

  &:hover {
    opacity: 0.8;
  }
`;

export const Styled = {
  NavigationArrowsContainer,
  NavigationArrow,
};
