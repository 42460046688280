import React, { forwardRef } from 'react';
import { SpaceProps, TypographyProps } from 'styled-system';

import { Styled } from './Icon.styles';

type IconProps = {
  iconName: string | object,
  size?: string,
  color?: string,
  as?: any,
} & SpaceProps & TypographyProps;

const defaultProps = {
  as: 'div',
  onClick: undefined,
  size: '16px',
  color: 'inherit',
};

const Icon = forwardRef<HTMLElement, IconProps>(({
  iconName, ...props
}, ref) => (
  <Styled.Icon
    ref={ref}
    className={`fa fa-${iconName}`}
    {...props}
  />
));

Icon.defaultProps = defaultProps;

export default Icon;
