import { useContext } from 'react';
import { useStore } from 'zustand';

import { Store } from '@liveroom/store';

import { LiveRoomStoreContext } from '@liveroom/context';

const useLiveRoomStore = <T>(selector: (state: Store) => T) => {
  const store = useContext(LiveRoomStoreContext);

  if (!store) {
    throw new Error('Missing LiveRoomStoreProvider');
  }

  return useStore(store, selector!);
};

export {
  useLiveRoomStore,
};
