import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import { Styled } from './NavigationArrow.style';

const directionIcon: any = {
  left: faChevronLeft,
  right: faChevronRight,
};

type NavigationArrowProps = {
  direction: string
  onClick: (event: React.MouseEvent) => void
}

type NavigationArrowsContainerProps = {
  children: React.ReactNode
}

const NavigationArrowsContainer = ({ children }: NavigationArrowsContainerProps) => (
  <Styled.NavigationArrowsContainer>
    {children}
  </Styled.NavigationArrowsContainer>
);

const NavigationArrow = ({ direction, onClick }: NavigationArrowProps) => (
  <Styled.NavigationArrow
    direction={direction}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={directionIcon[direction]}/>
  </Styled.NavigationArrow>
);

export {
  NavigationArrow,
  NavigationArrowsContainer,
};
