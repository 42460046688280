const lineHeights: any = ['13px', '15px', '19px', '23px', '27px', '33px', '37px'];

lineHeights.caption = lineHeights[0];
lineHeights.body = lineHeights[1];
lineHeights.head = lineHeights[2];
lineHeights.title = lineHeights[3];
lineHeights.heading = lineHeights[4];

export default {
  lineHeights,
};
