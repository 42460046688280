import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { includes } from 'lodash-es';

type Event = MouseEvent | TouchEvent

/**
 * useOnClickOutside hook.
 * Triggers callback function, if there was click outside given ref element.
 *
 * @param ref {HTMLElement}
 * @param handler {function}
 * @param skipOnElements
 * @param clickOnSkippedHandler
 * @param isEnabled
 */

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: HTMLElement | null,
  handler: (event: any) => void,
  skipOnElements: Array<HTMLElement> = [],
  isEnabled = true,
  clickOnSkippedHandler?: (event: Event) => void,
) => {
  const eventType = isMobile ? 'touchend' : 'mousedown';

  useEffect(() => {
    const listener = (event: any) => {
      event.stopPropagation();
      if (eventType === 'touchend' && includes(event?.target?.id, 'react-select')) {
        return;
      }

      if (!ref || (ref && 'contains' in ref && ref.contains((event?.target as Node) || null))) {
        return;
      }

      if (skipOnElements.some((elem) => event?.target instanceof Node && elem.contains(event?.target))) {
        clickOnSkippedHandler && clickOnSkippedHandler(event);
        return;
      }

      if (isEnabled && handler) {
        handler(event);
      }
    };

    document.addEventListener(eventType, listener);

    return () => {
      document.removeEventListener(eventType, listener);
    };
  }, [ref, handler, isEnabled, skipOnElements]);
};
export default useOnClickOutside;
