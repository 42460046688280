/* eslint-disable prefer-arrow-callback */
import * as Yup from 'yup';
import { yupToFormErrors } from 'formik';
import moment from 'moment';
import { includes } from 'lodash-es';

import { ageSettings, EXCEPT_TYPE_CAMP } from '@tourop/config/searchForm/birthdate';

import { DATE_REQUEST_FORMAT } from '@ess/constants/api';

import { getHotelXcodeFromValue, getFieldsOfParticipantsWithDate } from '../form';

/**
 * Custom Validation Methods
 */

const isHotelExistsInAgentAttributes = (args, message) => (
  Yup.string().test('isHotelExistsInAgentAttributes', message, (value) => {
    const { agentAttributes } = args;

    const selectedHotelXCode = getHotelXcodeFromValue(value);
    const isHotelExistsInAgentAttributes = selectedHotelXCode && includes(agentAttributes, selectedHotelXCode);

    return !selectedHotelXCode || isHotelExistsInAgentAttributes;
  }));

Yup.addMethod(Yup.string, 'isHotelExistsInAgentAttributes', isHotelExistsInAgentAttributes);

/**
 * Returns validation schema for dynamic birthdate fields.
 */

const getBirthDatesValidationSchema = (rooms, count, objectType, dateFrom, dateTo) => {
  const birthdateFields = getFieldsOfParticipantsWithDate(rooms, 'value');
  const birthdateValidationSchema = {};

  Object.keys(birthdateFields).map((item) => {
    const personType = item.split('.')[3] + (includes(objectType, 'accommodation.childcamp') ? EXCEPT_TYPE_CAMP : '');
    const dateFromMoment = moment(dateFrom, DATE_REQUEST_FORMAT, true);
    const dateToMoment = moment(dateTo, DATE_REQUEST_FORMAT, true);
    const maxDate = dateToMoment.subtract(ageSettings[personType].min, 'year');
    const minDate = dateFromMoment.subtract(ageSettings[personType].max, 'year');

    birthdateValidationSchema[item] = Yup.date()
      .required('provide_birthdate_error_msg')
      .typeError('provide_birthdate_error_msg')
      .when('$dateFrom', (dateFrom, schema) => (
        moment(dateFrom, DATE_REQUEST_FORMAT, true).isValid()
          ? schema.min(minDate, `max_age_${ageSettings.CHILD.max}_years`)
          : schema
      ))
      .when('$dateTo', (dateTo, schema) => (
        moment(dateTo, DATE_REQUEST_FORMAT, true).isValid()
          ? schema.max(maxDate, `min_age_${ageSettings.CHILD.min}_years`)
          : schema
      ));

    return birthdateValidationSchema[item];
  });

  return birthdateValidationSchema;
};

/**
 * Creates validation schema form Search form.
 */

export const searchFormValidationSchema = (values) => {
  const { rooms = [], count = 0 } = values['Base.ParticipantsList'] || {};

  const searchFormValidationSchema = Yup.object().shape({
    ...getBirthDatesValidationSchema(
      rooms,
      count,
      values['Base.ComponentsCombinations.ObjectType'],
      values['Custom.StartDateRange.StartDateFrom'],
      values['Custom.StartDateRange.StartDateTo'],
    ),
  });

  try {
    values = {
      ...values,
      ...getFieldsOfParticipantsWithDate(rooms, 'value'),
    };
    searchFormValidationSchema.validateSync(values, {
      abortEarly: false,
      context: {
        dateFrom: values['Custom.StartDateRange.StartDateFrom'],
        dateTo: values['Custom.StartDateRange.StartDateTo'],
      },
    });
  } catch (errors) {
    return yupToFormErrors(errors);
  }

  return {};
};
