// Todo: Refactor needed.
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';
import {
  get, has, isString, includes,
} from 'lodash-es';

import { IDictionary, SearchTypesEnum } from '@ess/types';

import topOffers from '@tourop/config/search/topOffers';

import { CONTENT_SERVICE_URL, PERSISTED_CONFIG_ITEM, GOOGLE_MAPS_API_TIMEZONE } from '@ess/constants/api';

import {
  getUrlSearchParams,
  getValidatedSearchValues,
  getCurrentLanguage,
} from '@ess/utils';

import { promiseRequest, simpleRequest } from '@ess/v5-data-provider/request';

import './googleMapsInvalidKey';

export interface ISearchBaseParams {
  Operators: string[]
  Accommodation: string[]
  Transport: string[]
}

const containers = ['modal-root', 'drawer-root'];
const singleOperator = Cookies.get('login_start_config')?.toUpperCase();
const { loginOpers, configOper = {} } = get(window, '__REACT_APP_BASE_CONFIG__', {}) as any;

const getSearchParams = () => getUrlSearchParams({ params: ['sf'] });

const googleMapsApiError = [
  'The provided API key is invalid.',
];

/**
 * Returns selected operators from proto hash.
 */
const getSearchBaseParams = (): ISearchBaseParams => {
  const formValues = getValidatedSearchValues(getSearchParams()?.sf);
  const transport = singleOperator && singleOperator in configOper
    ? [configOper[singleOperator].defaultTransport]
    : configOper?.default?.defaultTransport
      ? [configOper.default.defaultTransport]
      : ['transport.flight,accommodation.*'];

  let params: any = {
    Transport: formValues ? [] : transport,
    Accommodation: [],
    Operators: loginOpers?.length ? loginOpers : [],
  };

  if (formValues) {
    params = {
      ...params,
      ...!singleOperator ? { Operators: formValues['Base.Operator'] } : {},
      Transport: formValues['Base.ComponentsCombinations.Transport'] ?? [],
      Accommodation: formValues['Base.ComponentsCombinations.ObjectType'] ?? [],
    };
  }

  return params;
};

/**
 * Appends containers to document body
 */
const createContainers = () => {
  containers.map((item) => {
    const containerElement = document.createElement('div');
    const containerExists = document.querySelectorAll(`.${item}`);
    if (!containerExists.length) {
      containerElement.className = item;
      document.body.appendChild(containerElement);
    }
  });
};

/**
 * Returns persisted config from sessionStorage if exists.
 * @param searchType
 */
const getPersistedConfig = (searchType: string) => {
  const item = sessionStorage.getItem(PERSISTED_CONFIG_ITEM);
  const config = item ? JSON.parse(item) : null;

  if (config && config.type === searchType) {
    return config;
  }
  return null;
};

/**
 * Fetch App config & search base.
 * @param searchType
 * @param searchBaseParams
 */
export const fetchConfig = async (searchType: SearchTypesEnum, searchBaseParams: ISearchBaseParams) => {
  let ret = null;
  const scope = new Sentry.Scope();
  try {
    const [, , page] = window.location.pathname.split('/');
    const persistedConfig = getPersistedConfig(searchType);
    const sections = persistedConfig ? ['SearchBase'] : ['SearchBase', 'SearchForm'];

    let ReactConfig = persistedConfig || null;

    if (!persistedConfig) {
      const postData = encodeURIComponent(JSON.stringify({
        Language: getCurrentLanguage(),
        ReactConfig: {
          Types: ['AppBaseConfig'],
          ...singleOperator ? {
            OperSingleMode: singleOperator,
          } : {},
        },
      }));

      const configRequest = await promiseRequest(
        `${CONTENT_SERVICE_URL}ReactConfig/${postData}`, null, 999, undefined,
      );

      ReactConfig = configRequest.Sections.ReactConfig.AppBaseConfig;

      ReactConfig.isGoogleKeyAuthorized = true;

      if (ReactConfig?.googleApiKey) {
        try {
          const timeZoneRequest = await simpleRequest(
            `${GOOGLE_MAPS_API_TIMEZONE}location=52.22945875512536,21.01998409725624&timestamp=${
              new Date().valueOf()}&key=${ReactConfig.googleApiKey}`, null, undefined,
          );

          if (timeZoneRequest?.status === 'REQUEST_DENIED'
            && googleMapsApiError.includes(timeZoneRequest?.errorMessage)) {
            ReactConfig.isGoogleKeyValid = false;
          } else {
            ReactConfig.isGoogleKeyValid = true;
          }
        } catch (ex) {
          ReactConfig.isGoogleKeyValid = false;
        }
      } else {
        ReactConfig.isGoogleKeyValid = false;
      }
    }

    const currentSearchType = singleOperator ? ReactConfig.searchFormList[0].value : searchType;

    if (currentSearchType !== searchType) {
      window.history.replaceState(null, document.title, `/${currentSearchType}/${page}`);
    }

    const perSectionData: IDictionary<any> = {
      SearchBase: {
        Type: currentSearchType,
        ...searchBaseParams,
        Transport: searchBaseParams?.Transport?.filter((item) => item !== 'transport.*') ?? [],
        ...loginOpers?.length ? {
          Operators: loginOpers?.length ? loginOpers : [],
        } : {},
      },
      SearchForm: {
        Type: currentSearchType,
        ...singleOperator ? {
          Operator: singleOperator,
        } : {},
      },
    };

    scope.setExtra('WCS Request', sections.map((item) => ({
      Language: getCurrentLanguage(),
      [item]: perSectionData[item],
    })));

    const request = Promise.all(sections.map((item) => {
      const postData = encodeURIComponent(JSON.stringify({
        Language: getCurrentLanguage(),
        [item]: perSectionData[item],
      }));

      return promiseRequest(`${CONTENT_SERVICE_URL}${item}/${postData}`, null, 999);
    },
    ));

    const response = await request;

    let data = {} as any;

    response.map((item) => {
      data = {
        ...data,
        Sections: {
          ...data.Sections,
          ...item.Sections,
        },
        Status: {
          ...data.Status,
          ...item.Status,
        },
      };
    });

    sections.map((section) => {
      const status = data?.Status && has(data.Status, section) ? data.Status[section].Status : false;
      if (!isString(data.Status) && status === 'ERROR') {
        scope.setExtra('WCS response', data);
        throw new Error(`WCS ERROR: ${data.Status[section].StatusMsg}`);
      }
    });

    const { SearchForm = undefined, SearchBase } = data.Sections;

    const appConfig = persistedConfig || {
      ...ReactConfig,
      topOffersSortBy: topOffers.defaultSorting,
      isProto: !!getSearchParams()?.sf,
      type: currentSearchType,
      config: SearchForm,
    };

    ret = {
      config: {
        ...appConfig,
      },
      searchBase: {
        ...SearchBase,
      },
      searchForm: {
        ...SearchForm,
      },
    };

    if (!persistedConfig) {
      sessionStorage.setItem(PERSISTED_CONFIG_ITEM, JSON.stringify(appConfig));
    }
  } catch (error:any) {
    if (!includes(error?.message, 'unhandled market code')) {
      Sentry.captureException(error, () => scope);
    }
    throw error;
  }

  return ret;
};

/**
 * Returns App initialization promise.
 */
const appInit = () => {
  const [_, type] = window.location.pathname.split('/');
  const searchBaseParams = getSearchBaseParams();
  const searchType = ['trip', 'results', 'details', 'reservation'].includes(type) ? type : 'trip';

  createContainers();

  return new Promise((resolve, reject) => {
    fetchConfig(searchType as SearchTypesEnum, searchBaseParams)
      .then((response: any) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default appInit;
