import styled from 'styled-components';
import {
  space, flexbox, layout, position,
} from 'styled-system';

const Col = styled.div`
    ${position}
    ${space}
    ${layout}
    ${flexbox}
`;

export const Styled = {
  Col,
};
