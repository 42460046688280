import { Styled } from './Loader.styles';

type OvalLoaderProps = {
  color: string;
  size: string;
}

const OvalLoader = ({ color, size, ...props }: OvalLoaderProps) => <Styled.OvalLoader size={size} color={color} {...props}/>;

export {
  OvalLoader,
};
