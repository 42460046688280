import React from 'react';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

const NotFound = () => (
  <FlexBox
    backgroundColor="#fafafa"
    alignItems="center"
    justifyContent="center"
    width="100%"
    minHeight="100%"
    height="100vh"
  >
    <Text fontSize="96px">
      404
    </Text>
  </FlexBox>
);

export {
  NotFound,
};
