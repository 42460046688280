import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';

import { IV5OnlineOffer } from '@ess/v5-data-provider/interfaces';
import { IResponseListItem, TTransportBus, TTransportFlight } from '@ess/types';

import { OWN_TRANSPORT, TRANSPORT_BUS, TRANSPORT_FLIGHT } from '@ess/constants/transport';

import { getTransportDep, getTransportType } from '@ess/utils';

import Text from '@ess/ui/Text';
import Tooltip from '@ess/ui/Tooltip';
import FlexBox from '@ess/ui/FlexBox';

import { Styled } from '@tourop/components/OfferList/Offer/Offer.styles';

import OfferDetails from '@tourop/components/OfferList/Offer/OfferDetails';

type TransportTooltipContentProps = {
  offerData: IV5OnlineOffer;
  offerHashGetter: any;
}

const TransportTooltipContent = ({ offerData, offerHashGetter }: TransportTooltipContentProps) => {
  const { t } = useTranslation();
  const transportType = getTransportType(offerData.Transport);
  return (
    <>
      {transportType === OWN_TRANSPORT ? (
        <FlexBox p="8px">
          <Text>{t('own_transport')}</Text>
        </FlexBox>
      ) : (
        <FlexBox width={{
          xxs: '320px',
          xs: '360px',
          lg: '450px',
          md: '450px',
          _: '450px',
        }}
        >
          <OfferDetails
            isTooltip
            transportOnly
            offerData={offerData}
            offerHashGetter={offerHashGetter}
            searchType=""
            viewType=""
          />
        </FlexBox>
      )}
    </>
  );
};

type TransportIconProps ={
  onlineOffer?: any,
  content?: any;
  item: IResponseListItem
  offerHashGetter: any
}

const TransportIcon = ({
  onlineOffer = undefined,
  content = undefined,
  item,
  offerHashGetter,
}: TransportIconProps) => {
  const { Transport } = onlineOffer || item;
  const { depCode, icon } = getTransportDep(Transport);
  const transportType = getTransportType(Transport);
  const isBus = transportType === TRANSPORT_BUS;
  const isFlight = transportType === TRANSPORT_FLIGHT;

  const isReturnAirportDifferent = () => {
    if (isFlight && (Transport as TTransportFlight)?.Flight?.Ret) {
      const lastRetPos = (Transport as TTransportFlight).Flight.Ret.length - 1;

      if ((Transport as TTransportFlight).Flight.Ret[lastRetPos].Destination?.Code
        !== (Transport as TTransportFlight).Flight.Out[0].Departure?.Code) {
        return true;
      }
    }
    return false;
  };

  return (
    <Tooltip
      contentPadding={0}
      theme="bubble-wide"
      showOnMobile
      trigger={isMobileOnly ? 'click' : 'mouseenter'}
      placement="bottom"
      content={content || (
        <TransportTooltipContent
          offerData={onlineOffer || item}
          offerHashGetter={offerHashGetter}
        />
      )}
    >
      <Styled.Offer__Transport>
        <FontAwesomeIcon
          icon={icon}
          size="sm"
          color={isFlight && isReturnAirportDifferent() ? '#990000' : 'var(--text-color)'}
        />
        {depCode && (
          <Text
            ml="7px"
            fontWeight="bold"
            color={isFlight && isReturnAirportDifferent() ? 'red' : 'textColor'}
          >
            {depCode}
          </Text>
        )}
        {(isBus) && (
          <Styled.Offer__Transport__Desc>
            {(Transport as TTransportBus).Bus.Out?.Departure?.Name}
          </Styled.Offer__Transport__Desc>
        )}
      </Styled.Offer__Transport>
    </Tooltip>
  );
};

export default TransportIcon;
export { TransportTooltipContent };
