import { useState } from 'react';
import { isEmpty } from 'lodash-es';

import {
  CONTENT_WINDOW_DEFAULT_PAGE,
  CONTENT_WINDOW_OPTIONS,
  CONTENT_WINDOW_NAME,
} from '@ess/constants/contentWindow';
import { BASE_URL } from '@ess/constants/api';

import { objectToURLParams } from '@ess/utils';

type OpenContentWindowArgs = {
  endpoint?: string
  page?: string | null | undefined
  regionListMode?: boolean
  offerHash?: string | null
  name?: string
  additionalParams?: object
  offer?: any
}

export const getMandatoryOfferOnly = (offer:any) => {
  const { Base, Accommodation } = offer;

  const NewAccommodation = {
    Code: Accommodation.Code,
    Location: Accommodation.Location,
    XCode: Accommodation.XCode,
    XCity: Accommodation.XCity,
    XRegion: Accommodation.XRegion,
    XCountry: Accommodation.XCountry,
    Category: Accommodation.Category,
    ExtTripAdvisor: Accommodation.ExtTripAdvisor,
  };
  const NewBase = {
    DestinationLocation: Base.DestinationLocation,
    Operator: Base.Operator,
    EGuide: Base.EGuide,
    OfferId: Base.OfferId,
    XCity: Base.XCity,
    XCountry: Base.XCountry,
    XCode: Base.XCode,
    XRegion: Base.XRegion,

  };

  return {
    Accommodation: NewAccommodation,
    Base: NewBase,
  };
};

/**
 * useContentWindow hook.
 * Manages content window.
 *
 * @returns {{openContentWindow: (function({ page, offerHash, additionalParams, regionListMode }): void)}}
 */

const useContentWindow = (type: string) => {
  const [currentWindow, setCurrentWindow] = useState<Window | null>(null);

  const openContentWindow = ({
    page = CONTENT_WINDOW_DEFAULT_PAGE,
    offerHash = null,
    regionListMode = false,
    additionalParams = {},
    offer = undefined,
  }: OpenContentWindowArgs): void => {
    const contentWindowOptions = objectToURLParams({
      ...CONTENT_WINDOW_OPTIONS,
      width: window.screen.availWidth,
      height: window.screen.availHeight,
    }, ',');

    if (currentWindow !== null && !currentWindow.closed) {
      currentWindow.close();
    }

    const urlParams = objectToURLParams({
      ...!isEmpty(offerHash) ? { so: offerHash } : {},
      ...!isEmpty(additionalParams) ? { ...additionalParams } : {},
      ...!isEmpty(page) ? { page } : {},
      ...!isEmpty(offer) ? { offer: JSON.stringify({ offer }) } : {},
      ...regionListMode ? { regionListMode: 1 } : {},
    });

    const url = `${BASE_URL}${type}/content/?${urlParams}`;
    const contentWindow = window.open(url, CONTENT_WINDOW_NAME, contentWindowOptions);
    const x = (window.screenX || window.screenLeft || 0);
    const y = (window.screenY || window.screenTop || 0);

    contentWindow?.moveTo(x, y);
    contentWindow?.resizeTo(Math.min(1800, window.screen.availWidth), Math.min(1200, window.screen.availHeight));
    contentWindow?.focus();

    setCurrentWindow(contentWindow);
  };

  return { openContentWindow };
};

export default useContentWindow;
