import styled from 'styled-components';
import { space } from 'styled-system';

const Icon = styled.i<{size: string, color: string}>`
    ${space}
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: ${(props) => props.size};
    color: ${(props) => props.theme.colors[props.color]};
`;

export const Styled = {
  Icon,
};
