import { toast } from 'react-toastify';

export const TOAST_INFO = 1;
export const TOAST_ERROR = 2;
export const TOAST_SUCCESS = 3;
export const TOAST_WARNING = 4;
export const TOAST_DARK = 5;

/**
 *  shows the INFO MESSAGE
 */
const showToast = (messageType:number, messageText : string) => {
  if (messageType === TOAST_INFO) {
    toast.info((messageText), {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  if (messageType === TOAST_ERROR) {
    toast.error((messageText), {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  if (messageType === TOAST_SUCCESS) {
    toast.success((messageText), {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  if (messageType === TOAST_WARNING) {
    toast.success((messageText), {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export { showToast };
