import { useContext } from 'react';
import { useStore } from 'zustand';

import { Store } from '../store';

import { AgentSettingsContext } from '../provider';

const useAgentSettingsStore = <T>(selector: (state: Store) => T) => {
  const store = useContext(AgentSettingsContext);

  if (!store) {
    throw new Error('useAgentSettingsStore must be used within AgentSettingsProvider');
  }

  return useStore(store, selector!);
};

export {
  useAgentSettingsStore,
};
