import styled from 'styled-components';
import {
  space, fontSize, color, system,
} from 'styled-system';

const Anchor = styled.a<{ disabled?: boolean }>`
    ${space}
    ${color}
    ${fontSize}
    ${system({
    letterSpacing: true,
    textTransform: true,
    fontWeight: true,
  })}
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    transition: all 0.2s ease-in;
    transition-property: color;

    ${({ disabled, theme, color }) => (!disabled ? `
       &:hover {
        text-decoration: none;
        color: ${disabled ? color : color === 'white' ? theme.colors.lightBlue : theme.colors.orange};
       }
    ` : `
      color:  ${theme.colors.darkGray};
    `)}
`;

const AnchorText = styled.span`
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    text-decoration: underline;
    font-size: inherit;
`;

export const Styled = {
  Anchor,
  AnchorText,
};
