import { combineReducers } from 'redux';

import base from './slices/baseSlice';
import filtersForm from './slices/filtersFormSlice';
import searchForm from './slices/searchFormSlice';
import searchResults from './slices/searchResultsSlice';
import topOffers from './slices/topOffersSlice';

const rootReducer = combineReducers({
  base,
  filtersForm,
  searchForm,
  searchResults,
  topOffers,
});

export default rootReducer;
