import React from 'react';

import Loader from '@ess/ui/Loader';

const LoadingIndicator = (props) => {
  const {
    children = <Loader color="gray" size="15px"/>,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('loadingIndicator', props)}>
      {children}
    </div>
  );
};

export default LoadingIndicator;
