export const radii: any = ['0px', '2px', '4px', '6px', '8px', '100%'];

radii['br-0'] = radii[0];
radii['br-2'] = radii[1];
radii['br-4'] = radii[2];
radii['br-6'] = radii[3];
radii['br-8'] = radii[4];
radii.rounded = radii[5];

radii.default = 'var(--default-border-radius)';
radii.button = 'var(--button-border-radius)';
radii.formElement = 'var(--form-element-border-radius)';
radii.modal = 'var(--modal-border-radius)';

export default {
  radii,
};
