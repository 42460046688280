import { css } from 'styled-components';

const Toastify = css`
    .Toastify__toast {
      border-left: 4px solid transparent;

      &-container {
        width: auto;
        min-width: 320px;
        max-width: 420px;
      }

      &-body {
        align-items: flex-start;

      }

      &--error {
        border-color: var(--toastify-icon-color-error);
      }

      &--info {
        border-color: var(--toastify-icon-color-info);
      }

      &--warning {
        border-color: var(--toastify-icon-color-warning);
      }

      &--success {
        border-color: var(--toastify-icon-color-success);
      }
    }
`;

export default Toastify;
