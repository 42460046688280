import React, {
  forwardRef,
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
  SyntheticEvent, RefObject,
} from 'react';
import ReactSelect, {
  OptionTypeBase, ActionMeta, MenuPlacement, createFilter,
} from 'react-select';
import { useTranslation } from 'react-i18next';
import { isMobileOnly, isMobile } from 'react-device-detect';
import {
  isUndefined,
  compact,
  reduce,
  keys,
  find,
  without,
} from 'lodash-es';

import useStorage from '@ess/hooks/useStorage';
import StateManager from 'react-select';
import FlexBox from '@ess/ui/FlexBox';

import DropdownIndicator from '../SimpleSelect/ReplacedComponents/DropdownIndicator';
import ClearIndicator from '../SimpleSelect/ReplacedComponents/ClearIndicator';
import LoadingIndicator from '../SimpleSelect/ReplacedComponents/LoadingIndicator';
import Control from '../SimpleSelect/ReplacedComponents/Control';
import Option from '../AdvancedSelect/ReplacedComponents/Option';
import Menu from './ReplacedComponents/Menu';
import MultiValue from './ReplacedComponents/MultiValue';
import Group from './ReplacedComponents/Group';
import MenuGrid from './ReplacedComponents/MenuGrid';
import AdvancedSelectCustomStyles from './AdvancedSelect.styles';

const getUngroupedOptions = (groupedOptions: OptionTypeBase[], hasSimpleGroups: boolean, hasBoundGroups: boolean, hasFavorites: boolean) => {
  const groupIndex = hasBoundGroups ? 0 : -1;
  let ungroupedOptions: OptionTypeBase[] = [];

  if (!hasSimpleGroups && !hasBoundGroups) {
    return groupedOptions;
  }

  groupedOptions.map(({ options }, index) => ungroupedOptions = [...ungroupedOptions, ...(groupIndex === index ? [] : options)]);

  return ungroupedOptions;
};

export type TOptions = Array<OptionTypeBase>;

export type AdvancedSelectProps = {
  minMenuWidth?:any
  options: OptionTypeBase[]
  name?: string
  value?: any
  placeholder?: string
  multiple?: boolean
  components?: any,
  maxMenuHeight?: number
  onChange?: (selected: OptionTypeBase, actionMeta?: ActionMeta<OptionTypeBase>) => void
  onMenuOpen?: () => void
  isSearchable?: boolean
  isClearable?: boolean
  menuPlacement?: MenuPlacement
  isLoading?: boolean
  isDisabled?: boolean
  openMenuOnFocus?: boolean
  menuShouldBlockScroll?: boolean
  menuShouldScrollIntoView?: boolean
  button?: boolean | ReactElement
  menuPortalTarget?: HTMLElement | null
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void
  onBlur?: (event: React.FocusEvent) => void
  hasBoundGroups?: boolean
  hasSimpleGroups?: boolean
  groupsDictionary?: Record<string, { list: Array<string>, text: string }>
  showGroupItemCount?: boolean
  hasFavorites?: boolean
  label?: string
  height?: string
  columns?: number
  gridMenu?: boolean
  filterByValues?: boolean,
  targetElement?: RefObject<HTMLElement>
}

const defaultProps = {
  name: '',
  placeholder: '',
  value: null,
  multiple: false,
  button: false,
  maxMenuHeight: undefined,
  onChange: undefined,
  onMenuOpen: undefined,
  onFocus: undefined,
  onBlur: undefined,
  menuPlacement: 'auto' as MenuPlacement,
  isSearchable: undefined,
  isClearable: true,
  isLoading: false,
  isDisabled: false,
  openMenuOnFocus: false,
  menuShouldBlockScroll: false,
  menuShouldScrollIntoView: false,
  menuPortalTarget: undefined,
  components: undefined,
  hasBoundGroups: false,
  height: undefined,
  hasSimpleGroups: false,
  groupsDictionary: undefined,
  showGroupItemCount: false,
  hasFavorites: true,
  label: '',
  columns: 1,
  gridMenu: false,
  filterByValues: false,
  targetElement: undefined,
};

const AdvancedSelect = forwardRef<HTMLElement, AdvancedSelectProps>(({
  name,
  value,
  label,
  options,
  placeholder,
  onChange,
  onBlur,
  height,
  onFocus,
  isSearchable,
  isClearable,
  isLoading,
  isDisabled,
  menuPlacement,
  multiple,
  maxMenuHeight,
  menuShouldBlockScroll,
  menuShouldScrollIntoView,
  menuPortalTarget,
  components,
  onMenuOpen,
  hasBoundGroups = false,
  hasSimpleGroups = false,
  groupsDictionary,
  showGroupItemCount,
  hasFavorites = false,
  columns,
  gridMenu,
  filterByValues,
  targetElement,
  ...props
}, ref) => {
  const cRef = useRef<StateManager>(null);
  const { t } = useTranslation();
  const [isSearchEnabled, setIsSearchEnabled] = useState(true);
  const ungroupedOptions = getUngroupedOptions(options, hasSimpleGroups, hasBoundGroups, hasFavorites);
  const optionsCount = ungroupedOptions.length;
  const [checkedOptions, setCheckedOptions] = useState<OptionTypeBase[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<OptionTypeBase[]>([]);
  const [favorites, setFavorites] = useStorage<{ [index: string]: TOptions }>('localStorage', 'FormSelectsFavorites', {});
  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
  const [isGridMenuOpen, setIsGridMenuOpen] = useState<boolean>(false);
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  // removes all no longer existing values and fixes `disabled` state in case its changed
  useEffect(() => {
    if (name && hasFavorites && favorites[name]) {
      const fixedFavorites = without(favorites[name].map((item: OptionTypeBase) => find(ungroupedOptions, { value: item.value })), undefined, null);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setFavorites((state: { [index: string]: TOptions }) => ({
        ...state,
        [name as string]: fixedFavorites,
      }));
    }
  }, []);

  useEffect(() => {
    setSearchActive(inputValue.length > 0);
  }, [inputValue]);

  const getOptionsWithFavorites = () => {
    let newOpts = [...options];
    let newFavorites: TOptions = [];
    if (name && favorites[name]) {
      newFavorites = favorites[name];
      const favValues = newFavorites.map((item: OptionTypeBase) => item.value);

      if (hasSimpleGroups || hasBoundGroups) {
        newOpts = [...options].map((item) => ({ ...item, ...{ options: item.options.filter((item: OptionTypeBase) => !favValues.includes(item.value)) } }));
      } else {
        newOpts = [...options].filter((item: OptionTypeBase) => !favValues.includes(item.value));
      }
    }

    const newOptsWithFavs = [...(hasBoundGroups || hasSimpleGroups ? newOpts : [{
      label: t('select_heading_options'),
      options: newOpts,
    }])];

    return [
      { label: t('select_heading_favorites'), options: newFavorites },
      ...newOptsWithFavs];
  };

  useEffect(() => {
    setSelectOptions(searchActive ? ungroupedOptions : (hasFavorites && name ? getOptionsWithFavorites() : options));
  }, [options, hasFavorites, searchActive]);

  const [selectOptions, setSelectOptions] = useState<OptionTypeBase[]>(
    searchActive ? ungroupedOptions : (hasFavorites && name ? getOptionsWithFavorites() : options));

  const onBlurHandler = (event: React.FocusEvent) => {
    setIsOpen(false);
    setInputValue('');

    if (onBlur) {
      onBlur(event);
    }
  };

  const onFocusHandler = (event: React.FocusEvent<HTMLElement>) => {
    setCheckedOptions(
      ungroupedOptions.filter((option: OptionTypeBase) => value.includes(option.value)),
    ); // revert non-confirmed selection

    if (onFocus) {
      onFocus(event);
    }
  };

  const changeHandler = (selected: OptionTypeBase, actionMeta: ActionMeta<OptionTypeBase>) => {
    let group: Array<string> | undefined;
    if (!(searchActive) && (actionMeta.action === 'select-option' || actionMeta.action === 'deselect-option') && hasBoundGroups && groupsDictionary) {
      group = groupsDictionary?.[actionMeta.option?.value]?.list;
    }

    if (actionMeta.action === 'select-option' && actionMeta.option !== undefined) {
      const toAdd = group
        ? ungroupedOptions.filter((option) => group?.includes(option.value))
        : [actionMeta.option];
      setCheckedOptions((state) => [...(state || []), ...toAdd]);
    } else if (actionMeta.action === 'deselect-option') {
      // when deselecting by a group, only options that not exists in any other group must be deselected
      const reducer = (acc: Array<string>, item: { list: Array<string>, text: string }, index: string) => {
        if (index !== actionMeta.option?.value && selectedGroups.some((item: OptionTypeBase) => item.value === index)) {
          acc.push(...item.list);
        }
        return acc;
      };
      const selectedGroupsOptions = reduce(groupsDictionary, reducer, []);
      setCheckedOptions((state) => state.filter((elem) => (group
        ? !group.includes(elem.value) || selectedGroupsOptions.includes(elem.value)
        : elem.value !== actionMeta.option?.value)));
    } else if (actionMeta.action === 'clear') {
      onClear();
    }
  };

  useEffect(() => {
    // check each group if all its elements are selected
    if (!searchActive && hasBoundGroups && groupsDictionary) {
      if (optionsCount === checkedOptions.length) {
        // quick solution for select all
        setSelectedGroups(keys(groupsDictionary).map(
          (groupValue) => ({ label: groupsDictionary[groupValue].text, value: groupValue })),
        );
      } else {
        const fullySelectedGroups = compact(keys(groupsDictionary).map((groupKey) => {
          const group = groupsDictionary[groupKey].list;
          return group.every((item) => checkedOptions.map((item) => item.value).includes(item)) ? groupKey : false;
        }));

        setSelectedGroups(fullySelectedGroups.map(
          (groupValue) => ({ label: groupsDictionary[groupValue].text, value: groupValue })),
        );
      }
    }
  }, [checkedOptions, searchActive]);

  const setFocusedOption = () => {
    const currentRef = (ref ?? cRef) as MutableRefObject<StateManager>;
    if (currentRef?.current) {
      if (currentRef.current.select) {
        currentRef.current.select.scrollToFocusedOptionOnUpdate = true;
      }

      const newState = options.filter(
        (option: OptionTypeBase) => option.value === String(value),
      )[0] as { label: string, value: string };

      currentRef?.current?.select?.setState({
        focusedOption: newState,
      });
    }
  };

  const onMenuOpenHandler = () => {
    if (onMenuOpen) {
      onMenuOpen();
    }

    setFocusedOption();
  };

  useEffect(() => {
    if (value !== null) {
      const values = ungroupedOptions.filter((option: OptionTypeBase) => value.includes(option.value));
      setCheckedOptions(values);
    }
  }, [value]);

  useEffect(() => {
    const isSearchEnabled = !isUndefined(isSearchable) ? isSearchable : optionsCount > 10;

    setIsSearchEnabled(isSearchEnabled);
  }, [optionsCount, isSearchable]);

  useEffect(() => {
    setFocusedOption();
  }, [options]);

  const onClear = (event: SyntheticEvent | undefined = undefined) => {
    setCheckedOptions([]);
    if (onChange) {
      onChange([], undefined);
    }

    setInputValue('');

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onConfirm = (event: SyntheticEvent | undefined = undefined, values: Array<string>) => {
    const selected: Array<OptionTypeBase> = values
      ? ungroupedOptions.filter((option) => (values).includes(option.value))
      : (checkedOptions || []);

    if (onChange) {
      onChange(selected, undefined);
    }

    const currentRef = (ref ?? cRef) as MutableRefObject<StateManager>;
    if (currentRef?.current?.select) {
      if (isMobile) {
        currentRef.current.select.focus();
      }
      currentRef.current.select.blur();
    }

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onClose = () => {
    setCheckedOptions(ungroupedOptions.filter((option: OptionTypeBase) => value.includes(option.value))); // revert non-confirmed selection
  };

  const toggleFavorites = (triggered: OptionTypeBase, event: SyntheticEvent | undefined) => {
    const favorites = [...selectOptions[0].options];
    const inFavorites = favorites.some((item: OptionTypeBase) => item.value === triggered.value);

    const newFavorites = inFavorites ? favorites.filter((favItem) => favItem.value !== triggered.value) : [...favorites, triggered];
    const favValues = newFavorites.map((item) => item.value);
    let newOpts;
    if (hasSimpleGroups || hasBoundGroups) {
      newOpts = [...options].map((item) => ({ ...item, ...{ options: item.options.filter((item: OptionTypeBase) => !favValues.includes(item.value)) } }));
    } else {
      newOpts = [...options].filter((item: OptionTypeBase) => !favValues.includes(item.value));
    }

    const newOptsWithFavs = [...(hasBoundGroups || hasSimpleGroups ? newOpts : [{
      label: t('select_heading_options'),
      options: newOpts,
    }])];

    setSelectOptions([...[{ label: t('select_heading_favorites'), options: newFavorites }], ...newOptsWithFavs]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFavorites((state: { [index: string]: TOptions }) => ({
      ...state,
      [name as string]: newFavorites,
    }));

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const filter = createFilter({
    stringify: (option: OptionTypeBase) => (filterByValues
      ? `${option.label} ${option.value}`
      : `${option.label}`),
  });

  return (
    <FlexBox>
      <ReactSelect
        ref={(ref ?? cRef) as RefObject<StateManager>}
        passRef={(ref ?? cRef) as RefObject<StateManager>}
        name={name}
        label={label}
        styles={AdvancedSelectCustomStyles}
        value={[...checkedOptions, ...selectedGroups]}
        options={selectOptions}
        onChange={(selected, actionMeta) => changeHandler(selected, actionMeta)}
        onInputChange={(newValue, actionMeta) => {
          if (actionMeta.action !== 'set-value') {
            setInputValue(newValue);
          }
        }}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isMobile ? false : isSearchEnabled}
        isLoading={isLoading}
        isDisabled={isDisabled}
        blurInputOnSelect={false}
        isOptionDisabled={(option) => option.disabled}
        loadingMessage={() => t('loading_options')}
        noOptionsMessage={() => t('no_results')}
        onMenuOpen={onMenuOpenHandler}
        menuPlacement={menuPlacement}
        menuShouldBlockScroll={menuShouldBlockScroll}
        menuShouldScrollIntoView={menuShouldScrollIntoView}
        isMulti // TODO: remove oen of those
        multiple
        onMenuClose={onClose}
        searchActive={searchActive}
        inputValue={inputValue}
        setInputValue={setInputValue}
        {...{ ...(menuPortalTarget !== null) ? { menuPortalTarget: menuPortalTarget || document.body } : {} }}
        {...{ ...(maxMenuHeight) ? { maxMenuHeight } : isMobileOnly ? { maxMenuHeight: 445 } : { maxMenuHeight: 345 } }}
        minMenuHeight={100}
        setSelectOptions={setSelectOptions}
        autoFocus={false}
        closeMenuOnSelect={false}
        columns={columns}
        hasGroups={!searchActive && hasBoundGroups || hasSimpleGroups || hasFavorites}
        {...{ menuIsOpen: gridMenu ? false : isOpen || undefined }}
        setIsOpen={setIsOpen}
        gridMenu={gridMenu}
        isGridMenuOpen={isGridMenuOpen}
        setIsGridMenuOpen={setIsGridMenuOpen}
        onClear={onClear}
        onConfirm={onConfirm}
        toggleFavorites={toggleFavorites}
        backspaceRemovesValue={false}
        showGroupItemCount={showGroupItemCount}
        hasFavorites={hasFavorites}
        favorites={hasFavorites ? favorites : []}
        ungroupedOptions={ungroupedOptions}
        filteroption={filter}
        components={components || {
          Option,
          MenuList: gridMenu ? () => null : Menu,
          MultiValue,
          Group,
          Control,
          LoadingIndicator,
          ClearIndicator,
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        {...(hasBoundGroups || hasSimpleGroups ? { groupsDictionary } : {})}
        {...props}
      />
      {gridMenu && targetElement && (
      <MenuGrid
        name={name || ''}
        label={label || ''}
        options={selectOptions}
        ungroupedOptions={ungroupedOptions}
        targetElement={targetElement}
        value={value}
        onConfirm={onConfirm}
        isOpen={isGridMenuOpen}
        toggleFavorites={toggleFavorites}
        onClear={onClear}
        passRef={(ref ?? cRef) as RefObject<StateManager>}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filterOption={filter}
        favorites={favorites}
        setIsGridMenuOpen={setIsGridMenuOpen}
        hasFavorites={hasFavorites}
      />
      )}
    </FlexBox>
  );
});

AdvancedSelect.defaultProps = defaultProps;

export default AdvancedSelect;
export {
  Option,
  ClearIndicator,
  Group,
  Menu,
  MultiValue,
};
