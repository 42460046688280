/* eslint-disable no-param-reassign, dot-notation */

import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

import { ACCESS_TOKEN_KEY } from '../constants';

import {
  createTokenExpiredInterceptor,
} from './interceptors';

const AXIOS_CONFIG: AxiosRequestConfig = {
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

const axiosInstance = axios.create(AXIOS_CONFIG);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get(ACCESS_TOKEN_KEY);

    if (token) {
      config.headers.set('X-TOKEN', token, false);
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

createTokenExpiredInterceptor(axiosInstance);

export {
  axiosInstance,
};
