import React, { useContext } from 'react';

import { BasketContext } from '../BasketProvider';

const useReactBasket = () => {
  const basketContext = useContext(BasketContext);

  if (!basketContext) {
    throw new Error('Use BasketProvider to consume this context.');
  }

  return basketContext;
};

export {
  useReactBasket,
};
