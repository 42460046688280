import { StylesConfig } from 'react-select';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlexBox from '../../../FlexBox';

const AdvancedSelectCustomStyles = {
  menu: (provided, state) => ({
    ...provided,
    position: 'absolute',
    zIndex: 99999,
    ...(isMobileOnly || state.selectProps.gridMenu ? { boxShadow: 'unset' } : {}),
    minWidth: state.selectProps.multiple ? '140px' : '100%',
    overflow: 'hidden',
    border: 'none',
    width: 'auto',
    maxWidth: '800px',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 99999,
  }),
  menuList: (provided, state) => ({
    ...provided,
    textAlign: 'left',
    borderRadius: '4px',
    border: 'none',
    padding: '0px',
    ...{
      ...state.selectProps.hasGroups ? {} : {
        display: 'grid',
        gridTemplateColumns: `${100 / state.selectProps.columns}% `.repeat(state.selectProps.columns),
      },
    },
    maxHeight: '400px',
  }),
  groupHeading: (provided) => ({
    ...provided,
    padding: '0 6px 8px',
    fontSize: '10px',
    letterSpacing: '0.5px',
  }),
  input: (provided) => ({
    ...provided,
    height: 'auto',
    fontSize: '13px',
  }),
  group: (provided, state) => ({
    ...provided,
    paddingBottom: state.selectProps.inputValue ? '0' : '8px',
    paddingTop: state.selectProps.inputValue ? '0' : '8px',
    boxSizing: 'border-box',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.selectProps.isDisabled ? '#868686' : 'var(--text-color)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: '13px',
    height: '100%',
    padding: '2px 4px',
    gap: '2px',
  }),
  multiValue: (provided) => ({
    ...provided,
    margin: '0',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    padding: '3px 6px 3px 6px',
  }),
  option: (provided, state) => ({
    ...provided,
    position: 'relative',
    display: 'flex',
    fontSize: '13px',
    padding: '8px 6px',
    color: state.isFocused ? '#fff' : state.isDisabled ? '#868686' : 'var(--text-color)',
    backgroundColor: state.isFocused ? 'var(--text-color)' : state.isSelected ? '#f1f3f4' : '#fff',
    fontWeight: state.isSelected ? 'bold' : 'normal',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    ':active': {
      ...provided[':active'],
      backgroundColor: state.isFocused ? 'var(--text-color)' : state.isSelected ? '#f1f3f4' : '#fff',
    },
    borderBottom: state.data.isMultiple ? '1px solid #d3d6da' : null,
    overflow: 'hidden',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    position: 'relative',
    padding: 0,
    width: '28px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#c00',
    fontSize: '12px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    width: '28px',
    height: '100%',
    fontSize: '15px',
    color: state.selectProps.isDisabled ? '#868686' : '#689b0e',
    display: (
      state.selectProps.isLoading
      || state.selectProps.isAutosuggest
      || (state.hasValue && state.selectProps.isClearable) ? 'none' : 'flex'
    ),
    top: state.selectProps.menuIsOpen ? '-0.5px' : '0.5px',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    transition: 'all .3s ease',
    willChange: 'transform, top',
    transitionProperty: 'transform, top',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.selectProps.isDisabled ? '#868686' : '#838383',
    maxWidth: 'calc(100% - 8px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  container: (provided) => ({
    ...provided,
    width: '100%',
    pointerEvents: 'all',
  }),
  control: (provided, state) => ({
    ...provided,
    paddingRight: '0px',
    minHeight: '34px',
    maxHeight: '34px',
    height: '34px',
    backgroundColor: state.selectProps.isDisabled ? '#d3d6da' : '#fff',
    borderColor: state.isFocused ? 'transparent !important' : '#d5d5d5',
    borderTopRightRadius: state.selectProps.button ? 0 : '4px',
    borderBottomRightRadius: state.selectProps.button ? 0 : '4px',
    borderRight: state.selectProps.button ? 0 : '1px solid #d5d5d5',
    cursor: state.selectProps.isDisabled ? 'not-allowed' : 'pointer',
    boxShadow: state.isFocused ? '0 0 0 2.5px #2684ff !important' : null,
    transition: 'all .2s ease',
    transitionProperty: 'box-shadow, border-color',
  }),
} as StylesConfig<any, any>;

export default AdvancedSelectCustomStyles;

export const FontAwesomeIconOnHover = styled(FontAwesomeIcon)`
  cursor: pointer;
  display: none;
`;

export const FlexboxWithHover = styled(FlexBox)`
  :hover * > ${FontAwesomeIconOnHover} {
    display: block;
  }
`;
