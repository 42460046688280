import { useNavigate } from 'react-router-dom';

import { objectToURLParams, getUrlSearchParams } from '@ess/utils';

interface SearchParams {
  include?: string[],
  params?: string,
}

const useNavigateWithParams = () => {
  const navigate = useNavigate();

  return (pathname: string, search?: SearchParams) => {
    const pickedSearchParams = getUrlSearchParams({
      queryString: window.location.search,
      params: search?.include?.length ? search.include : [],
    });

    const searchParams = search?.params ?? objectToURLParams(pickedSearchParams);

    return navigate({
      pathname,
      ...(search) ? {
        search: searchParams,
      } : {},
    });
  };
};

export {
  useNavigateWithParams,
};
