import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useReducer,
} from 'react';
import { isEmpty } from 'lodash-es';

import { ISearchBase, SearchTypesEnum } from '@ess/types';

import { fetchConfig, ISearchBaseParams } from '@tourop/app/appInit';

import { getUngroupedOperators, getFieldsValuesFromConfig } from '@ess/utils';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import reducer from './reducer';

type SearchBaseProviderProps = {
  children: React.ReactNode,
  initialState: ISearchBase
}

type FetchSearchBaseArgs = {
  formValues?: any
  searchType?: SearchTypesEnum
  contextUpdate?: boolean
}

const SB_FIELDS = {
  operator: 'Base.Operator',
  transport: 'Base.ComponentsCombinations.Transport',
  accommodation: 'Base.ComponentsCombinations.ObjectType',
};

export interface ISearchBaseContext {
  state: ISearchBase;
  dispatch: React.Dispatch<any>;
  getOperatorDefaultValue:() => string[];
  fetchSearchBase: (formValues?: any, searchType?: SearchTypesEnum, contextUpdate?: boolean) => ISearchBase | null,
}

const SearchBaseContext = createContext<ISearchBaseContext>({
  state: {},
  dispatch: () => null,
  getOperatorDefaultValue: () => [],
  fetchSearchBase: () => null,
});

const sortCampAttributes = (items: any) => items.sort((a: any, b: any) => a.desc.localeCompare(b.desc));

const SearchBaseProvider = ({ children, initialState }: SearchBaseProviderProps) => {
  const [state, dispatch] = useReducer<React.Reducer<ISearchBase, object>>(reducer, initialState);
  const { state: SFContext } = useContext(AppConfigContext);
  const { type } = SFContext ?? {};

  /**
   * Returns all operators list.
   */
  const getOperatorDefaultValue = useCallback(() => {
    const operatorField = state?.['Base.Operator'];
    const searchBaseList = operatorField?.list ? operatorField.list : {};
    const operatorsList = searchBaseList?.values ? Object.keys(searchBaseList.values) : [];
    const values = operatorsList.map((key) => key);

    return getUngroupedOperators(values);
  }, [state?.['Base.Operator']]);

  /**
   * Fetch Search Base
   * @param formValues
   * @param searchType
   * @param contextUpdate
   */
  const fetchSearchBase = useCallback(async ({
    formValues,
    searchType = undefined,
    contextUpdate = true,
  }: FetchSearchBaseArgs = {}) => {
    const values = formValues || getFieldsValuesFromConfig({
      SBContext: initialState, SFContext, property: 'initialValue',
    });

    const params = {
      Operators: values[SB_FIELDS.operator].length ? values[SB_FIELDS.operator] : [],
      Transport: values[SB_FIELDS.transport].length ? values[SB_FIELDS.transport] : [],
      Accommodation: values[SB_FIELDS.accommodation].length ? values[SB_FIELDS.accommodation] : [],
    } as ISearchBaseParams;

    return new Promise((resolve, reject) => {
      fetchConfig(searchType || type, params).then((response: any) => {
        if (contextUpdate) {
          dispatch({ type: 'UPDATE_SEARCH_BASE', payload: response?.searchBase ?? {} });
        }
        resolve(response?.searchBase ?? {});
      }).catch((err) => {
        reject(err);
      });
    });
  }, [type]);

  const value = useMemo(() => ({
    state: {
      ...state,
      'Accommodation.Camp.Theme': { groupedlist: sortCampAttributes(state['Accommodation.Camp.Theme']?.groupedlist ?? []) },
    },
    dispatch,
    getOperatorDefaultValue,
    fetchSearchBase,
  }), [state]);

  useEffect(() => {
    if (isEmpty(initialState) && !isEmpty(SFContext)) {
      fetchSearchBase();
    }
  }, [type]);

  return (
    <SearchBaseContext.Provider value={value}>
      {children}
    </SearchBaseContext.Provider>
  );
};

export default SearchBaseProvider;
export {
  SearchBaseContext,
};
