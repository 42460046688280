import { useCallback } from 'react';

import { IOffer } from '@ess/types';

import {
  getOfferUrl,
  getOfferHashGetter,
  getUrlSearchParams,
  objectToURLParams,
  encodeOfferId,
  getValidParticipants,
} from '@ess/utils';

import { useAgentSettings } from '@ess/components/AgentSettings';

import useSearchType from '@ess/hooks/useSearchType';

export enum OfferUrl {
  Details = 'details',
  Booking = 'booking',
  Mask = 'mask',
}

interface Redirect {
  page: OfferUrl;
  offerData: IOffer;
  target?: '_blank' | '_self' | 'mask';
  searchType?: string;
  formHashes?: string[] | boolean;
  features?: string;
  additionalQueryParams?: Record<string, any>;
}

/**
 * Returns url hashes (sf / sfr).
 */
const getFormsProto = (formHashes: string[]) => {
  const urlSearchParams = getUrlSearchParams({ params: formHashes });

  return {
    sf: urlSearchParams.sf,
    sfr: urlSearchParams.sfr,
  };
};

/**
 * Returns offer proto hash.
 * @param offerData
 */
const getOfferProtoHash = (offerData: IOffer) => {
  const { adultsCount, childDates, infantDates } = encodeOfferId(offerData?.Base?.OfferId) ?? {};
  const validParticipants = getValidParticipants({
    adultsCount,
    childDates,
    infantDates,
  });

  return decodeURIComponent(getOfferHashGetter(offerData, validParticipants)());
};

const useOfferPage = () => {
  const openOfferInNewWindow = useAgentSettings((state) => state.values.searchResults.openOfferInNewWindow);
  const { type } = useSearchType();

  const goToOfferPage = useCallback(({
    page,
    offerData,
    target = undefined,
    searchType = type,
    formHashes = ['sf', 'sfr'],
    features = '',
    additionalQueryParams = {},
  }: Redirect) => {
    const redirectTarget = target || openOfferInNewWindow ? '_blank' : '_self';
    const offerProto = getOfferProtoHash(offerData);

    const urlParams = objectToURLParams({
      so: offerProto,
      ...Array.isArray(formHashes) && formHashes?.length ? {
        ...getFormsProto(formHashes),
      } : {},
      ...additionalQueryParams,
    });

    const url = getOfferUrl(page, searchType, urlParams);

    window.open(url, redirectTarget, features);
  }, [type, openOfferInNewWindow]);

  return {
    goToOfferPage,
    isNewWindowMode: openOfferInNewWindow,
  };
};

export {
  useOfferPage,
};
