import { IDictionary } from '@ess/types';

import {
  GROUPED_VIEW
} from '@ess/constants/search';


interface IFilters {
  fields: IDictionary<any>
}

type SearchResultsSliceState = {
  view: string
  extendedViewDetails: false
  initialOrder: string
  regionsType: string | null
  showMap: boolean
  multiRoomMode: boolean
  results: {
    hoveredMarker: {
      region?: string
      hotel?: string
      country?: string
    },
  },
  filters: IFilters
  error: boolean
}

const initialState = {
  view: GROUPED_VIEW,
  extendedViewDetails: false,
  initialOrder: 'Base.Price.FirstPerson',
  regionsType: '',
  showMap: false,
  multiRoomMode: false,
  results: {
    hoveredMarker: {},
  },
  filters: {
    fields: {},
  },
} as SearchResultsSliceState;

export default initialState;
