import moment from 'moment';

import { IDictionary } from '@ess/types';

import { DATE_REQUEST_FORMAT } from '@ess/constants/api';
import { CHILD_CODE, INFANT_CODE } from '@ess/constants/participants';

export const PARTICIPANTS_WITH_DATES = [CHILD_CODE, INFANT_CODE];

export const CHILD_BY_INDEX_DATE = [
  moment().subtract(6, 'years').format(DATE_REQUEST_FORMAT),
  moment().subtract(10, 'years').format(DATE_REQUEST_FORMAT),
  moment().subtract(12, 'years').format(DATE_REQUEST_FORMAT),
];

export const DEFAULT_BIRTH_DATES: IDictionary<any> = {
  CHILD: CHILD_BY_INDEX_DATE[2],
  INFANTS: moment().subtract(1, 'years').format(DATE_REQUEST_FORMAT),
};
