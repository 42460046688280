import React, { createContext, useEffect, useRef } from 'react';

import { createAuthStore, AuthStore, Store } from './store';

const AuthContext = createContext<AuthStore | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
  config?: Partial<Store>
}

const AuthProvider = ({ children, config = {} }: AuthProviderProps) => {
  const storeRef = useRef<AuthStore>();

  if (!storeRef.current) {
    storeRef.current = createAuthStore(config);
  }

  useEffect(() => {
    // @ts-ignore
    window.auth = {
      showLoginOverlay: (show: boolean) => {
        storeRef?.current?.setState((state) => ({
          ...state,
          showLoginOverlay: show,
        }));
      },
    };
  }, []);

  return (
    <AuthContext.Provider value={storeRef.current}>
      {children}
    </AuthContext.Provider>
  );
};

export {
  AuthProvider,
  AuthContext,
};
