import React, { SyntheticEvent, ReactElement } from 'react';
import {
  components, CommonProps, OptionTypeBase, OptionProps,
} from 'react-select';
import { CSSObject } from '@emotion/serialize';
import { CSSProperties } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faSolidStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '../../../../Box';
import FlexBox from '../../../../FlexBox';
import Checkbox from '../../../../Form/Checkbox';

const Option = (props: OptionProps<OptionTypeBase, true> & CommonProps<OptionTypeBase, true>,
) => {
  const {
    getStyles, isSelected, isFocused, children,
  } = props;
  const styles: CSSObject = getStyles('option', props);
  const groupCounterStyles: CSSObject = getStyles('option', props);
  styles.padding = '0';

  groupCounterStyles.position = 'absolute';
  groupCounterStyles.right = '0';
  groupCounterStyles.top = '0';
  groupCounterStyles.padding = '1px 6px 1px 6px';
  groupCounterStyles.marginRight = '3px';
  groupCounterStyles.border = 'radius';
  groupCounterStyles.borderRadius = '4px';
  groupCounterStyles.width = '22px';
  groupCounterStyles.textAlign = 'center';
  groupCounterStyles.backgroundColor = '#102c58';
  groupCounterStyles.color = 'white';

  if (isFocused) {
    groupCounterStyles.color = '#102c58';
    groupCounterStyles.backgroundColor = 'white';
  }

  const group = props.selectProps.groupsDictionary?.[props.data.value]?.list;
  const showGroupItemCount = props.selectProps.showGroupItemCount;

  const onClick = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onFavoritesClick = (e: SyntheticEvent) => {
    props.selectProps.toggleFavorites(props.data, e);
    e.preventDefault();
    e.stopPropagation();
  };

  const iconStyles: CSSProperties = {
    width: 'unset',
    padding: '11px 3px 8px 6px',
    flexGrow: 0,
    flexShrink: 0,
  };

  const inFavorites = Boolean(
    (props.selectProps.favorites[props.selectProps.name ? props.selectProps.name : ''] || [])
      .filter((item: OptionTypeBase) => item.value === props.data.value).length,
  );

  return (
    <FlexBox onClick={onClick}>
      {props.selectProps.hasFavorites && (isMobile || inFavorites)
        && (
        <FlexBox style={{ ...styles, ...iconStyles } as CSSProperties} as="span" onClick={onFavoritesClick}>
          <FontAwesomeIcon icon={inFavorites ? faSolidStar : faStar}/>
        </FlexBox>
        )}

      <components.Option {...props}>
        <Checkbox
          checked={isSelected}
          label={group && showGroupItemCount
            ? (
              <>
                {children}
                {' '}
                <Box style={groupCounterStyles as CSSProperties} as="span">{group.length}</Box>
              </>
            )
            : children as ReactElement | string}
          style={styles as CSSProperties}
          transition={false}
          iconStyles={{ position: 'relative', color: styles.color as string }}
        />
      </components.Option>
      {props.selectProps.hasFavorites && !props.selectProps.searchActive
        && (
        <FlexBox style={{ ...styles, ...iconStyles } as CSSProperties} as="span" onClick={onFavoritesClick}>
          {!isMobile && isFocused && !inFavorites
            && (<FontAwesomeIcon icon={inFavorites ? faSolidStar : faStar}/>)}
        </FlexBox>
        )}
    </FlexBox>
  );
};

export default Option;
