import { SettingsSection, SettingsType } from '../types';

export const searchResults: SettingsSection = {
  viewType: {
    label: 'lbl_search_view',
    type: SettingsType.Select,
    defaultValue: 'GROUPED_VIEW',
    options: [
      {
        label: 'lbl_extended_view',
        value: 'GROUPED_EXTENDED_VIEW',
      },
      {
        label: 'lbl_group',
        value: 'GROUPED_VIEW',
      },
      {
        label: 'lbl_ungroup',
        value: 'UNGROUPED_VIEW',
      },
    ],
  },
  sortType: {
    label: 'lbl_sort_settings',
    type: SettingsType.Select,
    defaultValue: 'RESULTS_SORT_IN_COLUMN',
    options: [
      {
        label: 'lbl_sort_in_column',
        value: 'RESULTS_SORT_IN_COLUMN',
      },
      {
        label: 'lbl_sort_in_list',
        value: 'RESULTS_SORT_IN_LIST',
      },
    ],
  },
  mapSetting: {
    label: 'lbl_gmap_view_settings',
    type: SettingsType.Select,
    defaultValue: 'roadmap',
    options: [
      {
        label: 'lbl_mapType_roadmap',
        value: 'roadmap',
      },
      {
        label: 'lbl_mapType_terrain',
        value: 'terrain',
      },
      {
        label: 'lbl_mapType_satellite',
        value: 'satellite',
      },
      {
        label: 'lbl_mapType_hybrid',
        value: 'hybrid',
      },
    ],
  },
  mapShowed: {
    label: 'lbl_map_default_showed',
    type: SettingsType.Switch,
    defaultValue: false,
  },
  dropdownMouseover: {
    label: 'lbl_dropdownmouseover',
    type: SettingsType.Switch,
    defaultValue: true,
  },
  filtersExpanded: {
    label: 'lbl_filters_default_expanded',
    type: SettingsType.Switch,
    defaultValue: true,
  },
  openOfferInNewWindow: {
    label: 'lbl_offer_details_target',
    type: SettingsType.Switch,
    defaultValue: false,
  },
  sortChildren: {
    label: 'lbl_sort_children',
    type: SettingsType.Switch,
    defaultValue: true,
  },
  prioritizedOperators: {
    label: 'lbl_prioritized_operators',
    type: SettingsType.Switch,
    defaultValue: false,
  },
};
