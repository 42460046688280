import {
  includes, isEmpty, has, floor, isArray,
} from 'lodash-es';

import { IPrice } from '@ess/types';

import { TOTAL_PRICE } from '@ess/constants/search';
import { OWN_TRANSPORT, TRANSPORT_ICONS, TRANSPORT_TYPES } from '@ess/constants/transport';

/**
 * Returns transport type.
 */

export const getTransportType = (transport: any) => (
  !isEmpty(transport) ? TRANSPORT_TYPES[Object.keys(transport)[0]] : OWN_TRANSPORT
);

/**
 * Returns departure code with transport icon.
 */

export const getTransportDep = (transport: any) => {
  const transportType = getTransportType(transport);
  const { Out = null } = !isEmpty(transport) ? transport[Object.keys(transport)[0]] : {};

  const depCode = Out !== null
    ? isArray(Out)
      ? Out[0].Departure.Code
      : ''
    : '';

  return {
    depCode,
    icon: TRANSPORT_ICONS[transportType],
  };
};

/**
 * Returns parsed Transport.
 */

export const getTransportDesc = (transport: any) => {
  if (!transport) {
    return {
      Out: [],
      Ret: [],
    };
  }

  const transportType = Object.keys(transport)[0];
  const transportData = !isEmpty(transport) ? transport[transportType] : {};

  let { Out = null, Ret = null } = transportData;

  if (!isEmpty(Out) && !isArray(Out)) {
    Out = [Out];
  }

  if (!isEmpty(Ret) && !isArray(Ret)) {
    Ret = [Ret];
  }

  return {
    Out: !isEmpty(Out) ? Out.map((segment: any) => ({
      Departure: !includes([null, '-', ''], segment.Departure) ? segment.Departure : '',
      Destination: !includes([null, '-'], segment.Destination) ? segment.Destination : '',
      Luggage: segment.Luggage,
      FlightNo: segment.FlightNo,
      AvailableSeatsCount: segment.AvailableSeatsCount,
    })) : [],
    Ret: !isEmpty(Ret) ? Ret.map((segment: any) => ({
      Departure: !includes([null, '-', ''], segment.Departure) ? segment.Departure : '',
      Destination: !includes([null, '-'], segment.Destination) ? segment.Destination : '',
      Luggage: segment.Luggage,
      FlightNo: segment.FlightNo,
      AvailableSeatsCount: segment.AvailableSeatsCount,
    })) : [],
  };
};

/**
 * Returns offer airlines from Transport object.
 */

export const getAirlines = (transport: any) => {
  const transportData = !isEmpty(transport) ? transport[Object.keys(transport)[0]] : {};
  const { Out, Ret } = transportData;

  const getValidAirline = (data: any) => {
    const hasAirline = !isEmpty(data) && has(data[0], 'Airline');
    const isValidAirline = hasAirline && !includes([null, '-', '..'], data[0].Airline.Name);

    return isValidAirline ? data[0].Airline.Name : '';
  };

  const outAirline = getValidAirline(Out);
  const retAirline = getValidAirline(Ret);
  const isSameAirline = outAirline && retAirline && outAirline === retAirline;

  return {
    isSameAirline,
    outAirline,
    retAirline,
  };
};

/**
 * Checks if Total Price has TFG/TFP included.
 */

export const getTFG = (price: IPrice) => {
  if (!price || !(TOTAL_PRICE in price)) {
    return false;
  }

  const { details } = price[TOTAL_PRICE] as IPrice ?? {};
  const hasPriceTFGInfo = has(details, 'TFGIncluded');

  return hasPriceTFGInfo ? details?.TFGIncluded : false;
};

/**
 * Returns parsed slope / beach distance.
 */

export const getDistance = (distance?: number) => {
  if (!distance) {
    return {
      show: false,
    };
  }

  const show = distance > 0 && distance < 99999;
  const distanceInKilometers = distance > 1000;
  const unit = distanceInKilometers ? 'km' : 'm';
  const value = distanceInKilometers ? floor((distance / 1000), 2) : distance;

  return {
    show,
    unit,
    value,
  };
};
