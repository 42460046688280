/* eslint-disable no-param-reassign */

import {
  AxiosInstance, AxiosResponse,
} from 'axios';

export interface AxiosTokenExpiredOptions {
  onLogout: () => void;
  statusCodes?: number[]
}

const defaultOptions = {
  statusCodes: [401],
  onLogout: () => {},
};

const createTokenExpiredInterceptor = (
  instance: AxiosInstance,
  options?: AxiosTokenExpiredOptions,
) => instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: any) => {
    options = {
      ...defaultOptions,
      ...options,
    };

    if (options?.statusCodes?.includes(error?.response?.status)) {
      options.onLogout();
    }

    return Promise.reject(error);
  },
);

export {
  createTokenExpiredInterceptor,
};
