import React, { memo } from 'react';
import moment from 'moment';
import { IDictionary, IFlightPart } from '@ess/types';

import { TRANSPORT_BUS, TRANSPORT_FLIGHT, TRANSPORT_TRAIN } from '@ess/constants/transport';
import { TRANSPORT_DATE_DISPLAY_FORMAT } from '@ess/constants/api';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlane, faBus, faTrain,
} from '@fortawesome/pro-light-svg-icons';
import {
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Tooltip from '@ess/ui/Tooltip';

import { OnlineWarnOld } from '@ess/ui/OnlineWarnTooltip';
import { isMobileOnly } from 'react-device-detect';

type TransportItemProps = {
  transportType: string
  operatorConfig: any
  segments: IFlightPart[]
  type: string
  airline: string
}

const transportIcon: IDictionary<any> = {
  [TRANSPORT_FLIGHT]: faPlane,
  [TRANSPORT_BUS]: faBus,
  [TRANSPORT_TRAIN]: faTrain,
};

const TransportItemNew = ({
  transportType,
  segments,
  operatorConfig,
  type,
  airline,
}: TransportItemProps) => {
  const { t } = useTranslation();
  const isFlight = transportType === TRANSPORT_FLIGHT;
  const isBus = transportType === TRANSPORT_BUS;
  const showPlaces = (isFlight && operatorConfig?.showFlightPlaces) || (isBus && operatorConfig?.showBusPlaces);

  const departureLabel = [TRANSPORT_BUS, TRANSPORT_TRAIN].includes(transportType) ? t('departure_bus') : t('departure_flight');
  const label = type === 'departure' ? departureLabel : t('return');
  const icon = transportType in transportIcon ? transportIcon[transportType] : null;

  const renderDateChanged = (
    isDateChanged: boolean,
  ) => isDateChanged && (
    <>
      <span style={{ color: 'red', marginLeft: '5px' }}>
        +1
      </span>
      <Tooltip content={`${t('lbl_date_change_info')}`}>
        <span style={{ marginLeft: '2px' }}>
          <FontAwesomeIcon color="orange" icon={faExclamationTriangle}/>
        </span>
      </Tooltip>
    </>
  );
  return (
    <FlexBox flexDirection="column" width="100%">
      {segments.map((segment, k) => {
        const {
          Departure, Destination, Luggage,
        } = segment;
        const DepartureDesc: any = Departure;
        const date = Departure?.Date;
        const depDate = moment(Departure?.Date);
        const destDate = moment(Destination?.Date);
        const isDateChange = (depDate.isValid() && destDate.isValid() && destDate > depDate && depDate !== destDate);

        return (
          <FlexBox key={k} flexDirection="column" mb="small">
            <FlexBox alignItems="center" width="100%" justifyContent="space-between">
              <FlexBox>
                <Text>{label}</Text>
                <Text fontSize="14px" fontWeight="bold" px="tiny"> · </Text>
                <Text>{moment(date).format(TRANSPORT_DATE_DISPLAY_FORMAT)}</Text>
              </FlexBox>
              {airline && (<Text>{`${airline} ${segment.FlightNo ? `(${segment.FlightNo})` : ''}`}</Text>)}
            </FlexBox>

            <FlexBox alignItems="center" width="100%" height={30}>
              <Text fontSize="14px" fontWeight="bold">{Departure?.Time}</Text>
              <FlexBox alignItems="center" width="100%" px="small">
                <FlexBox width="100%" style={{ borderBottom: '1px dashed #c2c2c2' }}/>
                <FlexBox width={50} px="small" justifyContent="center" style={{ fontSize: '16px' }}>
                  <FontAwesomeIcon icon={icon}/>
                </FlexBox>
                <FlexBox
                  width={isDateChange ? 'calc(100% - 60px)' : '100%'}
                  style={{ borderBottom: '1px dashed #c2c2c2' }}
                />
              </FlexBox>
              <Text fontSize="14px" fontWeight="bold" whiteSpace="nowrap">{`${Destination?.Time} `}</Text>
              {!isMobileOnly && renderDateChanged(isDateChange)}
            </FlexBox>
            {isDateChange && isMobileOnly && (
              <FlexBox
                ml="auto"
                mt="-5px"
              >
                {t('lbl_date_change_info')}
              </FlexBox>
            )}
            <FlexBox alignItems="center" width="100%" justifyContent="space-between">
              <FlexBox flexDirection="column">
                <FlexBox mb="tiny">
                  <Text fontSize="14px" fontWeight="bold">{Departure?.Code}</Text>
                  {Departure?.Name && (<Text fontSize="14px" fontWeight="bold" px="tiny"> · </Text>)}
                  <Text fontSize="14px">{Departure?.Name}</Text>
                  <Text fontSize="13px" ml="3px">{`${(DepartureDesc?.Desc ? `(${DepartureDesc.Desc})` : '')}`}</Text>
                </FlexBox>
              </FlexBox>

              <FlexBox flexDirection="column">
                <FlexBox mb="tiny">
                  <Text fontSize="14px" fontWeight="bold">{Destination?.Code}</Text>
                  {Destination?.Name && (<Text fontSize="14px" fontWeight="bold" px="tiny"> · </Text>)}
                  <Text fontSize="14px">{Destination?.Name}</Text>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            {(Luggage) && (
              <FlexBox alignItems="center" width="100%" justifyContent="space-between">
                <FlexBox mb="tiny" maxWidth="60%">
                  {`${t('lbl_luggage')} ${t('lbl_registered_luggage')}: ${Luggage?.Registered}`}
                </FlexBox>
                <FlexBox mb="tiny">
                  {`${t('lbl_luggage')} ${t('lbl_hand_luggage')}: ${Luggage?.Hand}`}
                </FlexBox>
              </FlexBox>
            )}
            {(showPlaces && segment.AvailableSeatsCount !== undefined) && (
              <FlexBox alignItems="center" width="100%" justifyContent="space-between">
                <FlexBox mb="tiny">
                  <FlexBox>
                    <Text fontSize="inherit" mr="tiny">{`${t('check_avail_seats')}:`}</Text>
                  </FlexBox>
                  <FlexBox>
                    <Text fontSize="inherit" mr="tiny" fontWeight="bold">
                      <OnlineWarnOld obj={segment} property="AvailableSeatsCount"/>
                    </Text>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};

export default memo(TransportItemNew);
