import React, { StrictMode, Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from '@ess/global-styles/GlobalStyles';

import { theme } from '@tourop/config/theme';

import './i18n';
import './sentry';

import { LiveRoomStoreProvider } from '@liveroom/context';

import { LoadScreen } from '@liveroom/pages/LoadScreen';

import App from './App';

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept();
}

ReactDOM.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<></>}>
      <ThemeProvider theme={theme}>
        <LiveRoomStoreProvider>
          <Suspense fallback={<LoadScreen/>}>
            <BrowserRouter>
              <App/>
            </BrowserRouter>
          </Suspense>
        </LiveRoomStoreProvider>
        <GlobalStyle/>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
);
