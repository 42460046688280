import React from 'react';

import { CARD_STYLES } from '@liveroom/constants';

import { Card } from '@ess/ui/Card';
import Skeleton from '@ess/ui/Skeleton';
import FlexBox from '@ess/ui/FlexBox';

import { Styled } from './OfferList.styles';

const OfferListSkeleton = () => (
  <Styled.OffersList__Grid>
    {Array.from({ length: 15 }).map((_, index) => (
      <Card key={`skeleton-${index}`} style={CARD_STYLES}>
        <Skeleton bg="#e6e6e6" height="190px" width="100%" borderRadius="0px" flexShrink={0}/>
        <FlexBox
          p="small"
          height="100%"
          flexDirection="column"
        >
          <FlexBox width="100%" height="100%" flexDirection="column" flexGrow={1}>
            <FlexBox mb="small">
              <Skeleton bg="#e6e6e6" height="14px" width="60%" borderRadius="4px"/>
            </FlexBox>

            <FlexBox mb="large">
              <Skeleton bg="#e6e6e6" height="20px" width="90%" borderRadius="4px"/>
            </FlexBox>

            {Array.from({ length: 4 }).map((_, index) => (
              <FlexBox key={`skeleton-item-${index}`} mb="small">
                <Skeleton bg="#e6e6e6" height="16px" width="80%" borderRadius="4px"/>
              </FlexBox>
            ))}
          </FlexBox>
          <FlexBox>
            <Skeleton bg="#e6e6e6" height="34px" width="100%" borderRadius="8px"/>
          </FlexBox>
        </FlexBox>
      </Card>
    ))}
  </Styled.OffersList__Grid>
);

export default OfferListSkeleton;
