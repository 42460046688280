import React, {
  forwardRef, useEffect, useState, memo, useRef, useImperativeHandle, HTMLInputTypeAttribute,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { FieldError } from '@ess/types';

import Text from '@ess/ui/Text';

import { Styled } from './TextInput.styles';

type AutoComplete = 'off' | 'on';

export type TextInputProps = {
  name?: string
  icon?: React.ReactElement | null
  startIcon?: string | React.ReactNode
  endIcon?: string | React.ReactNode
  value?: any
  type?: HTMLInputTypeAttribute
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClear?: (event: React.MouseEvent<HTMLElement>) => void
  onClick?: (event: React.PointerEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent) => void
  onFocus?: (event: React.FocusEvent) => void
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void
  placeholder?: string
  error?: FieldError
  autoFocus?: boolean
  autoSelect?: boolean
  readOnly?: boolean
  isClearable?: boolean
  showErrorMessage?: boolean
  isDisabled?: boolean
  height?: number
  maxLength?: number
  autoComplete?: AutoComplete
  style?: React.CSSProperties
  unControlled?: boolean
  disableInterActivity?: boolean
};

const defaultProps = {
  disableInterActivity: false,
  name: '',
  value: '',
  icon: null,
  height: undefined,
  startIcon: null,
  type: 'text',
  endIcon: null,
  onChange: undefined,
  onBlur: undefined,
  onClear: undefined,
  onClick: undefined,
  onFocus: undefined,
  onPaste: undefined,
  placeholder: '',
  error: '',
  autoFocus: false,
  autoSelect: false,
  isDisabled: false,
  isClearable: false,
  readOnly: false,
  maxLength: undefined,
  showErrorMessage: true,
  autoComplete: 'off' as AutoComplete,
  style: undefined,
  unControlled: false,
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({
  disableInterActivity,
  name,
  value,
  height,
  onChange,
  onClear,
  onClick,
  onBlur,
  onFocus,
  onPaste,
  style,
  placeholder,
  readOnly,
  error,
  showErrorMessage,
  isDisabled,
  type,
  isClearable,
  icon,
  startIcon,
  endIcon,
  maxLength,
  autoFocus,
  autoSelect,
  autoComplete,
  unControlled,
}, ref) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<any>('');
  const inputElement = useRef<any>(null);
  const hasError: any = error && error.length;

  useImperativeHandle(ref, () => inputElement.current);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);

    if (onChange) {
      onChange(event);
    }
  };

  const onPasteHandler = (event: React.ClipboardEvent<HTMLInputElement>) => {
    if (onPaste) {
      onPaste(event);
    }
  };

  const clearHandler = (event: React.MouseEvent<HTMLElement>): void => {
    setInputValue('');

    if (onClear) {
      onClear(event);
    }
  };

  const onIconClick = () => {
    if ((ref as any)?.current) {
      (ref as any)?.current?.focus();
    }
  };

  const clickHandler = (event: React.PointerEvent<HTMLInputElement>): void => {
    if (onClick) {
      onClick(event);
    }
  };

  useEffect(() => {
    setInputValue(value ?? '');
  }, [value]);

  useEffect(() => {
    if (autoFocus && inputElement?.current) {
      setTimeout(() => {
        inputElement.current.focus();
      }, 200);
    }
    if (autoSelect && inputElement?.current) {
      setTimeout(() => {
        inputElement.current.select();
      }, 200);
    }
  }, [inputElement?.current]);

  return (
    <Styled.TextInput__Wrapper
      disabled={isDisabled}
      clearIconVisible={isClearable && inputValue}
      endIcon={!!endIcon}
      startIcon={!!startIcon}
    >
      <Styled.TextInput__Inner>
        {startIcon && (
        <Styled.TextInput__Icon
          isStartIcon
          isError={hasError}
          onClick={onIconClick}
        >
          {startIcon}
        </Styled.TextInput__Icon>
        )}
        <Styled.TextInput
          disableInterActivity={disableInterActivity}
          ref={inputElement}
          type={type}
          height={height}
          hasError={hasError}
          onChange={changeHandler}
          onClick={clickHandler}
          onFocus={onFocus}
          onBlur={onBlur}
          onPaste={onPasteHandler}
          value={unControlled ? value : inputValue}
          readOnly={readOnly || isDisabled}
          autoComplete={autoComplete}
          style={style}
          {...{ ...name ? { name, id: name } : {} }}
          {...{ ...placeholder ? { placeholder } : {} }}
          {...{ ...maxLength ? { maxLength } : {} }}
        />
        {((icon !== null || endIcon !== null) || (isClearable && inputValue)) && (
        <>
          {(isClearable && !isDisabled && inputValue) ? (
            <Styled.TextInput__Icon
              onClick={clearHandler}
              isStartIcon={false}
              isClearIcon
            >
              <FontAwesomeIcon
                color="#c00"
                icon={faTimes}
                size="1x"
              />
            </Styled.TextInput__Icon>
          ) : (
            <Styled.TextInput__Icon
              isStartIcon={false}
              onClick={onIconClick}
            >
              {icon || endIcon}
            </Styled.TextInput__Icon>
          )}
        </>
        )}
      </Styled.TextInput__Inner>
      {hasError && showErrorMessage && (<Text fontSize="12px" my="2px" color="errorRed">{t(error as string)}</Text>)}
    </Styled.TextInput__Wrapper>

  );
});

TextInput.defaultProps = defaultProps;

export default memo(TextInput);
