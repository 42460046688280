import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { showToast, TOAST_ERROR, TOAST_SUCCESS } from '@ess/utils';

import { Button } from '@ess/ui/Button';
import Modal from '@ess/ui/Modal';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import TextInput from '@ess/ui/Form/TextInput';

type LiveRoomProps = {
  url: string
  onClose: () => void
  hashId: string
  language: string
}

const LiveRoom = ({
  url, onClose, hashId, language,
}: LiveRoomProps) => {
  const { t } = useTranslation();
  const [number, setNumber] = useState<any>(null);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumber(event.target.value);
  };

  const connect = () => {
    (async () => {
      try {
        if (number) {
          const response = await axios.get(`${url}${language.toLowerCase()}/${hashId}/set/${number}`);

          if (response?.status === 200) {
            showToast(TOAST_SUCCESS, t('lbl_liveroom_connection_success'));
          } else {
            showToast(TOAST_ERROR, t('lbl_liveroom_connection_fail'));
          }
        }
      } catch (error) {
        showToast(TOAST_ERROR, t('lbl_liveroom_connection_fail'));
      }
    })();
  };
  return (
    <Modal
      isOpen
      width={500}
      title={t('lbl_liveroom')}
      onClose={onClose}
      controls={(
        <FlexBox p="small" width="100%" alignItems="center" justifyContent="flex-end">
          <Button
            mr="small"
            variant="secondary"
            label={t('lbl_close')}
            size="small"
            onClick={onClose}
          />
          <Button
            disabled={!number}
            label={t('lbl_connect')}
            size="small"
            onClick={connect}
          />
        </FlexBox>
      )}
    >
      <FlexBox p="small" flexDirection="column">
        <FlexBox width="100%" mb="small" flexDirection="column">
          <Text mb="tiny">{`${t('lbl_connection_number')}:`}</Text>
          <TextInput value={number} onChange={onChange} placeholder={t('lbl_provide_connection_number')} autoFocus/>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export {
  LiveRoom,
};
