import React, { createContext, useRef } from 'react';

import { createAgentSettingsStore, AgentSettingsStore } from '../store';

const AgentSettingsContext = createContext<AgentSettingsStore | null>(null);

type OfferDetailsContextProps = {
  children: React.ReactNode;
  sections?: Record<string, boolean>;
}

const AgentSettingsProvider = ({ children, sections = undefined }: OfferDetailsContextProps) => {
  const storeRef = useRef<AgentSettingsStore>();

  if (!storeRef.current) {
    storeRef.current = createAgentSettingsStore(sections);
  }

  return (
    <AgentSettingsContext.Provider value={storeRef.current}>
      {children}
    </AgentSettingsContext.Provider>
  );
};

export {
  AgentSettingsProvider,
  AgentSettingsContext,
};
