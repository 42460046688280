import styled from 'styled-components';
import { variant, space } from 'styled-system';

/**
 * Chip Variants / Sizes
 */

const ChipSize = variant({
  prop: 'size',
  key: 'chip.sizes',
});

const ChipVariant = variant({
  prop: 'variant',
  key: 'chip.variants',
});

/**
 * Chip styles
 */

const Chip = styled.div`
    display: inline-flex;
    align-items:center;
    justify-content: center;
    flex-shrink: 0;
    white-space: nowrap;
    min-width: 28px;
    padding: 0px 8px;
    border-radius: 12px;
    cursor: default;
    letter-spacing: 0.25px;

    ${space}
    ${ChipSize}
    ${ChipVariant}
`;

const ChipGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -2.5px;

  & > div {
    margin: 2.5px;
  }
`;

export const Styled = {
  ChipGroup,
  Chip,
};
