export default {
  grid: {
    columns: 12,
    gapX: 5,
    gapY: 6.5,
  },
  defaultModal: {
    modalMaxWidth: 450,
    rows: {
      xxs: 2,
      xs: 2,
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
  },
  modal: {
    'Base.Operator': {
      modalMaxWidth: 820,
      rows: {
        xxs: 2,
        xs: 2,
        sm: 3,
        md: 4,
        lg: 5,
        xl: 5,
      },
    },
    'Base.Transfer': {
      modalMaxWidth: 450,
      rows: {
        xxs: 3,
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3,
      },
    },
    'Base.DestinationLocation.Region': {
      modalMaxWidth: 820,
      rows: {
        xxs: 2,
        xs: 2,
        sm: 3,
        md: 4,
        lg: 5,
        xl: 5,
      },
    },
    'Base.DepartureLocation': {
      modalMaxWidth: 450,
      rows: {
        xxs: 2,
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3,
      },
    },
    'Base.NightsBeforeReturn': {
      modalMaxWidth: 450,
      rows: {
        xxs: 3,
        xs: 4,
        sm: 4,
        md: 4,
        lg: 5,
        xl: 5,
      },
    },
    'Accommodation.XService': {
      modalMaxWidth: 450,
      rows: {
        xxs: 2,
        xs: 2,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 2,
      },
    },
    'Base.StartDateDayOfWeek': {
      modalMaxWidth: 450,
      rows: {
        xxs: 2,
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3,
      },
    },
    'Custom.AgentAttributes': {
      modalMaxWidth: 450,
      rows: {
        xxs: 2,
        xs: 2,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 2,
      },
    },
  },
} as any;
