import { SettingsSection, SettingsType, DetailsSectionState } from '../types';

const defaultSections: Record<string, boolean> = {
  Description: true,
  Pictures: true,
  Omnibus: true,
  PriceChart: true,
  MultiRoomFinder: true,
  AlternativeOffers: true,
  AlternativeDepartures: true,
  AlternativeHotels: true,
  OtherOperatorsHotels: true,
  TripAdvisor: true,
  RegionalInfo: true,
  VisaInfo: true,
  AgentAttributes: true,
  AirportsNearby: true,
  BeachesNearby: true,
  MapInfo: true,
};

const labelMap: Record<string, string> = {
  expanded: 'details_expanded_view',
  reduced: 'details_shrink_view',
  hidden: 'details_not_show',
};

export const createDetailsPageSettings = (sections?: Record<string, boolean>) => {
  let settings = {};

  Object.keys(defaultSections).map((section) => {
    const showSettings = sections || defaultSections;

    settings = {
      ...settings,
      [section]: {
        label: `offer_${section.toLowerCase()}`,
        type: SettingsType.Select,
        defaultValue: 'reduced',
        options: Object.keys(DetailsSectionState).map((state) => ({
          label: labelMap[state.toLowerCase()],
          value: state.toLowerCase(),
        })),
        visible: showSettings[section],
      },
    };
  });

  return settings;
};

export const detailsPage: SettingsSection = createDetailsPageSettings();
