import { radii } from '../borderRadius';
import { colors } from '../colors';

const hover = {
  color: colors.white,
  backgroundColor: colors.orange,
  borderColor: colors.orange,
};

const disabled = {
  color: colors.lightGray,
  backgroundColor: colors.gray,
  borderColor: colors.gray,
};

const variants = {
  primary: {
    color: colors.white,
    backgroundColor: colors.buttonBackground,
    borderColor: colors.buttonBackground,
  },
  secondary: {
    color: colors.buttonBackground,
    backgroundColor: colors.white,
    borderColor: colors.buttonBackground,
  },
  navy: {
    color: colors.white,
    backgroundColor: colors.navy,
    borderColor: colors.navy,
  },
  gray: {
    color: colors.darkGray,
    backgroundColor: colors.lighterGray,
    borderColor: colors.gray,
  },
  darkGray: {
    color: colors.darkGray,
    backgroundColor: '#ededed',
    borderColor: colors.gray,
  },
  light: {
    color: colors.darkGray,
    backgroundColor: colors.lighterGray,
    borderColor: 'transparent',
  },
  transparent: {
    color: colors.navy,
    backgroundColor: colors.white,
    borderColor: colors.navy,
  },
};

const sizes = {
  button: {
    tiny: {
      fontSize: '10px',
      height: '22px',
    },
    small: {
      fontSize: '11px',
      height: '26px',
    },
    medium: {
      fontSize: '12px',
      height: '30px',
    },
    large: {
      fontSize: '13px',
      height: '34px',
    },
  },
  iconButton: {
    tiny: {
      fontSize: '8px',
      height: '20px',
      width: '20px',
    },
    small: {
      fontSize: '11px',
      height: '26px',
      width: '26px',
    },
    medium: {
      fontSize: '12px',
      height: '30px',
      width: '30px',
    },
    large: {
      fontSize: '13px',
      height: '34px',
      width: '34px',
    },
  },
};

const radius = {
  iconButton: {
    rounded: {
      borderRadius: radii.rounded,
    },
    square: {
      borderRadius: radii['br-6'],
    },
  },
};

const button = {
  variants,
  disabled,
  radius,
  hover,
  sizes,
};

export default {
  button,
};
