import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initiator: null,
  visibleOnSearchPage: false,
};

export const topOffersSlice = createSlice({
  name: 'topOffers',
  initialState,
  reducers: {
    resetTopOffers: (state) => ({
      ...state,
      ...initialState,
    }),
    setInitiator: (state, action) => ({
      ...state,
      initiator: action.payload,
    }),
    setVisibleOnSearchPage: (state, action) => ({
      ...state,
      visibleOnSearchPage: action.payload,
    }),
  },
});

export const {
  resetTopOffers,
  setInitiator,
  setVisibleOnSearchPage,
} = topOffersSlice.actions;
export default topOffersSlice.reducer;
