import React from 'react';
import { useTranslation } from 'react-i18next';

import Img from '@ess/ui/Image';
import Text from '@ess/ui/Text';
import Anchor from '@ess/ui/Anchor';
import FlexBox from '@ess/ui/FlexBox';

type FullRatingProps = {
  data: any
  onClick?: () => void
}

const FullRating = ({ data, onClick = undefined }: FullRatingProps) => {
  const { t } = useTranslation();
  const {
    image, rating, count, description,
  } = data;
  const onClickHandler = () => {
    window.open(description, '_blank');
  };

  return (
    <FlexBox alignItems="center" flexWrap="wrap">
      <Img
        src={image}
        alt={rating as unknown as string}
        height="25px"
        width="130px"
        objectFit="contain"
        onClick={onClickHandler}
        style={{
          cursor: 'pointer',
          marginLeft: '-8px',
          marginRight: '-5px',
        }}
      />
      <>
        <Text ml="tiny" mr="tiny" lineHeight="16px" fontSize="inherit" fontWeight="bold">{count}</Text>
        <Text mr="5px" lineHeight="16px" fontSize="inherit">{`${t('reviews_count')} `}</Text>
      </>
      {onClick && (
        <Anchor ml="tiny" fontSize="inherit" onClick={onClickHandler}>{t('informations')}</Anchor>
      )}
    </FlexBox>
  );
};

export default FullRating;
