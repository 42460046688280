import { has } from 'lodash-es';
import { useDebouncedCallback } from 'use-debounce';

interface IEventData {
  event: string
  eventCategory: string
  eventAction: string
  eventLabel?: string
  eventValue?: string | number
}

declare let window: {
  dataLayer: any
  ga: any
};

/* Min event data set. */
const REQUIRED_EVENT_PROPERTIES = ['event', 'eventCategory', 'eventAction'];

/* Indicates if google analytics exists. */
const isGAInitialized = (): boolean => {
  const hasDataLayer = typeof window.dataLayer !== 'undefined';
  const hasGa = typeof window.ga !== 'undefined' && typeof window.ga === 'function';

  return hasDataLayer && hasGa;
};

/**
 * useGoogleAnalytics hook.
 * Return google analytics functions.
 *
 * @returns breakPoint
 */
const useGoogleAnalytics = () => {
  const trackEvent = (eventData: IEventData) => {
    const hasEventProperFields = REQUIRED_EVENT_PROPERTIES.every((el) => has(eventData, el));
    const data: IEventData = { ...eventData };

    if (!isGAInitialized()) {
      return;
    }

    if (!hasEventProperFields) {
      console.error(`GAEvent: Missing required event properties (${REQUIRED_EVENT_PROPERTIES.join(', ')}).`);
      return;
    }

    window.dataLayer.push(data);
  };

  const sendPageView = useDebouncedCallback((location: string) => {
    if (!isGAInitialized()) {
      return;
    }

    window.ga('set', 'page', location);
    window.ga('send', 'pageview');
  }, 100);

  return {
    trackEvent,
    sendPageView,
  };
};

export default useGoogleAnalytics;
