import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';

import { Styled } from './OmnibusForOfferDetails.styles';

type OmnibusForOfferDetailsProps = {
  omnibus: any
}

const OmnibusForOfferDetails = ({ omnibus }: OmnibusForOfferDetailsProps) => {
  const { t } = useTranslation();

  const previousPriceAmount = Math.round(omnibus.Previous.amount);
  const previousPriceCurrency = omnibus.Previous.currency;
  const lowestPriceAmount = Math.round(omnibus.Lowest.amount);
  const lowestPriceCurrency = omnibus.Lowest.currency;

  return !isEmpty(omnibus) ? (
    <Styled.OmnibusSectionHolder>
      <Styled.OmnibusLabel>
        {`${t('lbl_omnibus_section_label')}:`}
      </Styled.OmnibusLabel>
      <Styled.PriceLabel>
        {`${t('lbl_omnibus_previous_price')}: ${previousPriceAmount} ${previousPriceCurrency}`}
      </Styled.PriceLabel>
      <Styled.PriceLabel>
        {`${t('lbl_omnibus_lowest_price')}: ${lowestPriceAmount} ${lowestPriceCurrency}`}
      </Styled.PriceLabel>
    </Styled.OmnibusSectionHolder>
  ) : null;
};
export default OmnibusForOfferDetails;
