import React, { memo } from 'react';
import {
  LayoutProps, PositionProps, FlexboxProps, SpaceProps,
} from 'styled-system';

import { Styled } from './Row.styles';

type RowProps = {
  children: React.ReactNode[] | React.ReactNode
  gapX?: number
  gapY?: number
  columns?: number
  style?: React.CSSProperties
} & LayoutProps & PositionProps & FlexboxProps & SpaceProps;

const Row = ({
  children,
  gapX = 0,
  gapY = 0,
  style = {},
  ...props
}: RowProps) => {
  const rowGapX = gapX && gapX > 0 ? gapX * -1 : gapX;
  const rowGapY = gapY && gapY > 0 ? gapY * -1 : gapY;

  return (
    <Styled.Row
      style={style}
      {...(rowGapX && { mx: `${rowGapX}px` })}
      {...(rowGapY && { my: `${rowGapY}px` })}
      {...props}
    >
      {React.Children.toArray(children).map((child, index) => (
        React.cloneElement(child as any, {
          key: `col-${index}`,
          ...gapX ? { px: `${gapX}px` } : {},
          ...gapY ? { py: `${gapY}px` } : {},
        })
      ))}
    </Styled.Row>
  );
};

export default memo(Row);
