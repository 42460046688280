import React, {
  forwardRef, useEffect, useState, CSSProperties, useMemo,
} from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { includes } from 'lodash-es';

import { IDictionary } from '@ess/types';

import useBreakpoint from '@ess/hooks/useBreakpoint';

import DrawerOverlay from './DrawerOverlay';

import { Styled } from './Drawer.styles';

export type DrawerPositionEnum = 'left' | 'right' | 'top' | 'bottom';

export type DrawerProps = {
  isPortal?: boolean;
  id?: any;
  position?: DrawerPositionEnum;
  controls?: React.ReactElement | undefined;
  children: React.ReactNode;
  onClose?: () => void;
  title?: React.ReactElement | string;
  appendTo?: Element | null;
  clickOutsideIgnoreElements?: any[];
  maxWidth?: number;
  isOpen?: boolean;
  isBodyScrollLocked?: boolean;
  top?: number;
  showOverlay?: boolean;
  animationEnabled?: boolean;
  fixHeight?: boolean;
  headerStyles?: CSSProperties;
  contentStyles?: CSSProperties;
  closeOnClickOutside?: boolean;
  innerRef?: any;
  containerStyles?: CSSProperties;
  zIndex?: number;
};

const positionsMap: IDictionary<any> = {
  right: {
    initial: {
      x: '100%',
    },
    animate: {
      x: 0,
    },
    exit: {
      x: '100%',
    },
  },
  left: {
    initial: {
      x: '-100%',
    },
    animate: {
      x: 0,
    },
    exit: {
      x: '-100%',
    },
  },
  top: {
    initial: {
      y: '-100%',
    },
    animate: {
      y: 0,
    },
    exit: {
      y: '-100%',
    },
  },
  bottom: {
    initial: {
      y: '100%',
    },
    animate: {
      y: 0,
    },
    exit: {
      y: '100%',
    },
  },
};

const Drawer = forwardRef<HTMLDivElement, DrawerProps>(({
  isPortal = true,
  id = undefined,
  children,
  title = undefined,
  onClose = undefined,
  top = 0,
  showOverlay = true,
  isOpen = false,
  isBodyScrollLocked = false,
  position = 'left' as DrawerPositionEnum,
  appendTo = null,
  controls = undefined,
  clickOutsideIgnoreElements = [],
  closeOnClickOutside = true,
  fixHeight = false,
  contentStyles = {},
  headerStyles = {},
  containerStyles = {},
  animationEnabled = false,
  maxWidth = undefined,
  zIndex = 99999,
  innerRef = null,
  ...props
}, ref) => {
  const [rootElement, setRootElement] = useState<Element | null>(null);
  const [drawerElement, setDrawerElement] = useState<HTMLDivElement | null>(null);
  const [fixedHeight, setFixedHeight] = useState<string>('auto');
  const breakpoint = useBreakpoint();

  const styles = useMemo(() => ({
    [position as string]: '0',
    ...maxWidth ? { maxWidth: `${maxWidth}px` } : {},
    ...includes(['bottom', 'top'], position) ? {
      width: '100%',
      height: fixedHeight,
      maxHeight: '100%',
      left: 0,
      right: 0,
      margin: '0 auto',
    } : {
      width: 'auto',
      height: includes(['xxs', 'xs'], breakpoint) || !top ? '100%' : `calc(100% - ${top}px)`,
      top: includes(['xxs', 'xs'], breakpoint) || !top ? '0px' : `${top}px`,
    },
  }), [position, top]);

  const closeHandler = (event: MouseEvent | TouchEvent) => {
    event.stopPropagation();

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setRootElement(appendTo ?? document.querySelector('.drawer-root') as Element);
  }, [appendTo]);

  useEffect(() => {
    if (fixHeight && fixedHeight === 'auto' && drawerElement) {
      setFixedHeight(`${drawerElement.clientHeight}px`);
    }
  }, [drawerElement, fixHeight]);

  const content = (
    <AnimatePresence>
      {isOpen && (
        <>
          <Styled.Drawer
            isPortal={isPortal}
            id={id}
            key="drawer"
            ref={ref || setDrawerElement}
            {...{
              ...animationEnabled ? {
                animate: positionsMap[position as string].animate,
                initial: positionsMap[position as string].initial,
                exit: positionsMap[position as string].exit,
                transition: { type: 'spring', bounce: 0, duration: 0.3 },
              } : {},
            }}
            zIndex={zIndex}
            style={{
              ...styles,
              ...containerStyles,
            }}
            {...props}
          >
            {title && (
              <Styled.Drawer__Header>
                <Styled.Drawer__Header__Inner style={headerStyles}>
                  <Styled.Drawer__Header__Title>
                    {title}
                  </Styled.Drawer__Header__Title>
                  <Styled.Drawer__Header__CloseBtn onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} color="inherit" size="lg"/>
                  </Styled.Drawer__Header__CloseBtn>
                </Styled.Drawer__Header__Inner>
              </Styled.Drawer__Header>
            )}

            <Styled.Drawer__Content style={contentStyles} ref={innerRef}>
              {children}
            </Styled.Drawer__Content>

            {controls && (
              <Styled.Drawer__Controls>
                {controls}
              </Styled.Drawer__Controls>
            )}
          </Styled.Drawer>
          {isPortal && <DrawerOverlay zIndex={zIndex as number} onClick={onClose} show={showOverlay}/>}
        </>
      )}
    </AnimatePresence>
  );

  return isPortal ? (
    <>
      {rootElement !== null && createPortal(
        <>
          {content}
        </>,
        rootElement,
      )}
    </>
  ) : content;
});

export default Drawer;

export {};
