import styled from 'styled-components';
import { space } from 'styled-system';
import { toNumber } from 'lodash-es';

const getSizeAsNumber = (size: string) => toNumber(size.replace('px', ''));

const DotsLoader = styled.div<{ size: string, color: string }>`
    display: flex;
    flex-shrink: 0;
    gap: ${({ size }) => `${getSizeAsNumber(size) * 0.12}px`};
    ${space};

    > div {
      background-color: ${({ color }) => color};
      border-radius: 100%;
      width: ${({ size }) => `${getSizeAsNumber(size) * 0.35}px`};
      height: ${({ size }) => `${getSizeAsNumber(size) * 0.35}px`};
      animation: dot-beat 0.9s 0s infinite linear;

      &:nth-child(2n-1) {
        animation-delay: -0.35s !important;
      }
    }

    @keyframes dot-beat {
      50% {
        opacity: 0.2;
        transform: scale(0.75);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
`;

const OvalLoader = styled.div<{ size: string, color: string }>`
  border:  ${({ theme, size }) => `${getSizeAsNumber(size) * 0.15}px solid ${theme.colors.lighterGray}`};
  border-right-color: ${({ color }) => color};
  width: ${({ size }) => size};
  aspect-ratio: 1;
  border-radius: 50%;
  animation: spin 1s infinite linear;
  ${space};

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg);
  }
`;
export const Styled = {
  DotsLoader,
  OvalLoader,
};
