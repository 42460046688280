/**
 * Copies parent styles & appends to iframe..
 * @param iFrameDocument
 */
export const copyStyles = (iFrameDocument: Document) => {
  const styleElements = document.querySelectorAll('style');

  for (let i = 0, headElsLen = styleElements.length; i < headElsLen; ++i) {
    const node = styleElements[i];
    if (node.tagName.toLowerCase() === 'style') {
      const newHeadElement = document.createElement(node.tagName);
      const sheet = (node as HTMLStyleElement).sheet as CSSStyleSheet;

      if (sheet) {
        let styleCSS = '';
        try {
          const cssLength = sheet.cssRules.length;
          for (let j = 0; j < cssLength; ++j) {
            const type = sheet.cssRules[j].type;

            if (type === 1) {
              styleCSS += `${sheet.cssRules[j].cssText}\r\n`;
            }
          }
        } catch (error) {
          console.error(error);
        }

        newHeadElement.setAttribute('id', `react-print-${i}`);
        newHeadElement.appendChild(document.createTextNode(styleCSS));
        iFrameDocument.head.appendChild(newHeadElement);
      }
    }
  }
};

/**
 * Appends page styles.
 * @param document
 * @param styles
 */
export const appendStyles = (document: Document, styles: string) => {
  const newHeadEl = document.createElement('style');
  newHeadEl.appendChild(document.createTextNode(styles));
  document.head.appendChild(newHeadEl);
};

/**
 * Creates print iFrame.
 * @param id
 */
export const createPrintIframe = (id: string) => {
  const iFrame = document.createElement('iframe');
  iFrame.style.position = 'absolute';
  iFrame.style.top = '-1000px';
  iFrame.style.left = '-1000px';
  iFrame.id = `react-print-${id}`;
  iFrame.srcdoc = '<!DOCTYPE html>';

  return iFrame;
};
