import { darken, rgba } from 'polished';
import { colors } from '../colors';

const variants = {
  default: {
    color: darken(0.5, colors.gray),
    backgroundColor: 'rgb(234 234 234)',
  },
  primary: {
    color: '#496c0a',
    backgroundColor: 'rgb(225 235 207)',
  },
  secondary: {
    color: darken(0.20, colors.blue),
    backgroundColor: rgba(colors.blue, 0.2),
  },
  error: {
    color: '#600',
    backgroundColor: 'rgb(239 198 198);',
  },
  highlighted: {
    color: colors.white,
    backgroundColor: colors.blue,
  },
  border: {
    color: colors.navy,
    backgroundColor: 'white',
    padding: '4px 8px',
    borderRadius: '15px',
    border: '1px solid rgb(204, 204, 204)',
  },
  borderHighlighted: {
    color: colors.navy,
    backgroundColor: 'white',
    padding: '4px 8px',
    borderRadius: '15px',
    border: '2px solid #102c58',
  },
};

const sizes = {
  small: {
    fontSize: '10px',
    height: '16px',
  },
  medium: {
    fontSize: '11px',
    height: '20px',
  },
  large: {
    fontSize: '12px',
    height: '24px',
  },
};

const chip = {
  variants,
  sizes,
};

export default {
  chip,
};
