import React from 'react';
import { useTranslation } from 'react-i18next';
import { indexOf, partition } from 'lodash-es';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Tooltip from '@ess/ui/Tooltip';

import Chip, { ChipGroup } from '@ess/ui/Chip';

type AttributesProps = {
  attributes: string[];
}

const Attributes = ({ attributes }: AttributesProps) => {
  const { t } = useTranslation();
  const [featured, rest] = partition(attributes, (item) => indexOf(attributes, item) < 2);

  return (
    <ChipGroup>
      {featured.map((item, index) => (
        <Chip
          key={`attributes-${index}`}
          variant="border"
          label={t(item)}
        />
      ))}

      {rest.length > 0 && (
        <Tooltip
          theme="bubble-wide"
          showOnMobile
          disabled={!rest.length}
          content={(
            <FlexBox
              p="tiny"
              flexDirection="column"
              width="200px"
              boxShadow={1}
            >
              {rest.map((item, index) => (
                <FlexBox
                  key={`rest-attributes-${index}`}
                  height={30}
                  alignItems="center"
                >
                  <Text>{t(item)}</Text>
                </FlexBox>
              ))}
            </FlexBox>
          )}
        >
          <Chip variant="border" label={`+${rest.length}`}/>
        </Tooltip>
      )}
    </ChipGroup>
  );
};

export {
  Attributes,
};
