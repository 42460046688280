import { SettingsSection, SettingsType } from '../types';

export const basket: SettingsSection = {
  openBasketOnHover: {
    label: 'lbl_open_basket_on_hover',
    type: SettingsType.Switch,
    defaultValue: false,
  },
  overwriteBasket: {
    label: 'lbl_overwrite_basket',
    type: SettingsType.Switch,
    defaultValue: false,
  },
  basketPosition: {
    label: 'lbl_basket_position',
    type: SettingsType.Select,
    defaultValue: 'top',
    options: [
      {
        label: 'lbl_basket_position_top',
        value: 'top',
      },
      {
        label: 'lbl_basket_position_right',
        value: 'right',
      },
      {
        label: 'lbl_basket_position_bottom',
        value: 'bottom',
      },
      {
        label: 'lbl_basket_position_left',
        value: 'left',
      },
    ],
  },
  basketOfferView: {
    label: 'lbl_offers_view',
    type: SettingsType.Select,
    defaultValue: 'list',
    options: [
      {
        label: 'lbl_list_view',
        value: 'list',
      },
      {
        label: 'lbl_grid_view',
        value: 'grid',
      },
    ],
  },
};
