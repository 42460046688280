import { css } from 'styled-components';

const CSSVariables = css`
  :root {
    /* Layout */

    --page-width: 1349px;
    --top-bar-height: 52px;
    --breadcrumbs-height: 36px;
    --header-height: calc(var(--top-bar-height) + var(--breadcrumbs-height));

    /* Colors */

    --white-color: #fff;
    --primary-color: #60910b;

    --text-color: #102c58;
    --text-secondary-color: #838383;

    --background-color: #fff;
    --background-secondary-color: #f6f6f6;

    --separator-color: #e1e3e6;

    --placeholder-color: #838383;

    --text-price-color: #006400;
    --text-total-price-color: #006400;
    --text-person-price-color: #990000;
    --text-section-header-color: #fff;

    --section-header-background-color: #1a9fff;

    --text-accent-color: var(--text-price-color);
    --button-background-color: var(--primary-color);

    /* Border */

    --default-border-radius: 8px;
    --modal-border-radius: 6px;
    --button-border-radius: 6px;
    --form-element-border-radius: 4px;

    /* Form */

    --form-element-height: 38px;

    /* Overrides */

    --toastify-text-color-light: var( --text-color);
    --toastify-z-index: 99999;
  }
`;

export default CSSVariables;
