import Modal from '@ess/ui/Modal';
import React, { useEffect, useMemo, useState } from 'react';
import Select from '@ess/ui/Form/Select';
import FlexBox from '@ess/ui/FlexBox';
import Anchor from '@ess/ui/Anchor';
import Text from '@ess/ui/Text';
import { useTranslation } from 'react-i18next';
import { Button } from '@ess/ui/Button';

type IbeLinkProps = {
  affiliates: any[]
  defaultAffiliate: string
  hashId: string
  onClose: () => void
}

const IbeLink = ({
  affiliates, defaultAffiliate, hashId, onClose,
}: IbeLinkProps) => {
  const { t } = useTranslation();
  const [currentAffiliate, setCurrentAffiliate] = useState<any>(null);

  const options = useMemo(() => affiliates.map((item, index) => ({
    label: item.Domain,
    value: item.Url,
  })), [affiliates]);

  const url = useMemo(() => {
    const affiliate = currentAffiliate?.value ?? currentAffiliate;
    return `${affiliate}${hashId}`;
  }, [currentAffiliate, hashId]);

  const onChange = (selected: any) => {
    setCurrentAffiliate(selected?.value ?? null);
  };

  useEffect(() => {
    const { hostname } = new URL(defaultAffiliate);
    const selectedOption = options.find((option) => option.label === hostname);

    setCurrentAffiliate(selectedOption ? selectedOption.value : '');
  }, [defaultAffiliate, options]);

  return (
    <Modal
      isOpen
      width={500}
      title={t('lbl_ibe_link')}
      onClose={onClose}
      controls={(
        <FlexBox p="small" width="100%" alignItems="center" justifyContent="flex-end">
          <Button
            label={t('lbl_close')}
            size="small"
            onClick={onClose}
          />
        </FlexBox>
      )}
    >
      <FlexBox p="small" flexDirection="column">
        <FlexBox width="100%" mb="small" flexDirection="column">
          <Text mb="tiny">{`${t('lbl_affiliate')}:`}</Text>
          <Select
            onChange={onChange}
            options={options}
            value={currentAffiliate}
            isClearable={false}
          />
        </FlexBox>
        <FlexBox>
          <Anchor
            onClick={() => window.open(url, '_blank')}
            href={url}
            style={{
              wordBreak: 'break-all',
            }}
          >
            {url}
          </Anchor>
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export {
  IbeLink,
};
