import { useCallback, useEffect, useState } from 'react';
import { useEvent, useLatest, useDebounce } from 'react-use';

export enum ScrollDirection {
  Idle = 'idle',
  Top = 'top',
  Bottom = 'bottom'
}

interface ScrollState {
  scrollLeft: number;
  scrollTop: number;
  scrollHeight: number;
  scrollWidth: number;
  clientHeight: number;
  clientWidth: number;
}

type ScrollOptions = {
    onDirectionChange?: (direction: string) => void;
    onReachEnd?: () => void;
    onScroll?: (scroll: ScrollState) => void;
}

const useScrollBar = (scrollElement: HTMLDivElement, options?: ScrollOptions) => {
  const [scrollState, setScrollState] = useState({
    direction: ScrollDirection.Idle,
    progress: 0,
  });

  const latest = useLatest(scrollState);

  const onScrollHandler = useCallback((event: any) => {
    const {
      scrollTop,
      scrollHeight,
      scrollWidth,
      clientHeight,
      clientWidth,
      scrollLeft,
    } = event.target as ScrollState;
    const height = scrollHeight - clientHeight;
    const progress = Math.round((scrollTop / height) * 100);

    const isIdle = progress === 0;
    const isMinScrollHeight = Math.abs(scrollHeight - clientHeight) > 250;

    const direction = isIdle
      ? ScrollDirection.Idle
      : progress > latest.current.progress
        ? ScrollDirection.Bottom
        : ScrollDirection.Top;

    if (options?.onScroll) {
      options.onScroll({
        scrollTop,
        scrollLeft,
        scrollHeight,
        scrollWidth,
        clientWidth,
        clientHeight,
      });
    }

    setScrollState((state) => ({
      ...state,
      progress,
      ...progress !== latest.current.progress && isMinScrollHeight ? {
        direction,
      } : {},
    }));
  }, [latest]);

  const onDirectionChange = (direction: ScrollDirection) => {
    if (options?.onDirectionChange) {
      options.onDirectionChange(direction);
    }
  };

  useDebounce(
    () => {
      onDirectionChange(scrollState.direction);
    },
    200,
    [scrollState.direction],
  );

  useEvent('scroll', onScrollHandler, scrollElement);

  useEffect(() => {
    if (!scrollElement) {
      return;
    }

    const {
      scrollTop,
      scrollHeight,
      scrollWidth,
      clientHeight,
      clientWidth,
      scrollLeft,
    } = scrollElement as ScrollState;

    if (options?.onScroll) {
      options.onScroll({
        scrollTop,
        scrollLeft,
        scrollHeight,
        scrollWidth,
        clientWidth,
        clientHeight,
      });
    }
  }, [scrollElement]);

  useEffect(() => {
    if (scrollState.progress !== null && scrollState.progress >= 99 && options?.onReachEnd) {
      options.onReachEnd();
    }
  }, [scrollState.progress]);
};

export {
  useScrollBar,
};
