import React from 'react';

import Text from '@ess/ui/Text';
import Tooltip from '@ess/ui/Tooltip';

import { Styled } from './HotelAttribute.styles';

type HotelAttributeProps = {
  icon: React.ReactNode;
  isHighlighted: boolean;
  descriptionMode: string | undefined;
  label: string;
};

const HotelAttribute = ({
  icon,
  isHighlighted,
  descriptionMode,
  label,
}: HotelAttributeProps) => {
  const isTooltipMode = descriptionMode && descriptionMode === 'tooltip';

  const AttributeElement = (
    <Styled.HotelAttribute
      isHighlighted={isHighlighted}
      withLabel={!isTooltipMode}
    >
      <Styled.HotelAttribute__Icon>
        {icon}
      </Styled.HotelAttribute__Icon>
      {!isTooltipMode && (<Text fontSize="12px" ml="tiny">{label}</Text>)}
    </Styled.HotelAttribute>
  );

  return (
    <>
      {isTooltipMode ? (
        <Tooltip showOnMobile content={label}>
          {AttributeElement}
        </Tooltip>
      ) : (
        AttributeElement
      )}
    </>
  );
};

export {
  HotelAttribute,
};
