import React, { useEffect, useState } from 'react';

/**
 * useWindowSize hook.
 * Returns current window size.
 *
 * @returns {{width: (number), height: (number)}}
 */

const useWindowSize = () => {
  const [height, setHeight] = useState<number>(window.innerHeight);
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = (): void => {
      setHeight(() => window.innerHeight);
      setWidth(() => window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { height, width };
};

export default useWindowSize;
