export const CONTENT_WINDOW_DEFAULT_PAGE = 'Pictures';
export const CONTENT_WINDOW_NAME = 'CONTENT_WINDOW';

interface IContentWindowOptions {
  top: number,
  screenX: number,
  screenY: number,
  fullscreen: string,
  channelmode: number,
  menubar: number,
  resizeable: number,
  scrollbars: number,
  titlebar: number,
  status: number,
  directories: number,
  height: number,
}

export const CONTENT_WINDOW_OPTIONS = {
  top: 1,
  screenX: 1,
  screenY: 1,
  fullscreen: 'yes',
  channelmode: 0,
  menubar: 0,
  resizeable: 1,
  scrollbars: 1,
  titlebar: 0,
  status: 0,
  directories: 0,
} as IContentWindowOptions;

export enum SECTIONS {
  MultiRoomFinder,
  AlternativeOffers,
  Description,
  Pictures,
  MapInfo,
  TripAdvisor,
  RegionalInfo,
  PriceChart,
  AgentAttributes,
  WeatherStatic,
  VisaInfo,
  AirportsNearby,
  BeachesNearby,
}

export type TSections = keyof typeof SECTIONS;

export enum EPictureSizes {
  full,
  large = 640,
  medium = 320,
  small = 160,
}
