import React, { useCallback } from 'react';

import { ILiveRoomOffers } from '@liveroom/types';

import useFavourites from '@ess/hooks/useFavourites';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

import { useTranslation } from 'react-i18next';
import { Offer } from './Offer';

import OfferListSkeleton from './OfferListSkeleton';

import { Styled } from './OfferList.styles';

type OfferListProps = {
  offers: ILiveRoomOffers;
  isFavouritesEnabled?: boolean;
  goToOffer?: (data: any) => void;
}

const OfferList = ({
  offers,
  goToOffer,
  isFavouritesEnabled = true,
}: OfferListProps) => {
  const {
    favourites,
    add,
    remove,
  } = useFavourites<number>('LiveRoom', false);
  const { t } = useTranslation();

  const onClick = useCallback((rowId: number) => false, []);

  const onFavouriteClick = useCallback((rowId: number) => (
    !favourites.includes(rowId) ? add(rowId) : remove(rowId)
  ), [favourites]);

  if (['loading', 'idle'].includes(offers?.status)) {
    return (
      <OfferListSkeleton/>
    );
  }

  if (offers?.status === 'error') {
    return (
      <FlexBox
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize="24px">{t('lbl_liveroom_error')}</Text>
      </FlexBox>
    );
  }

  return (
    <Styled.OffersList__Grid>
      {offers?.items?.map((item: any) => (item?.offer ? (
        <Offer
          key={item.rowid}
          data={item}
          onClick={onClick}
          onFavouriteClick={onFavouriteClick}
          goToOffer={goToOffer}
          isFavourite={favourites.includes(item.rowid)}
          isFavouritesEnabled={isFavouritesEnabled}
        />
      ) : null))}
    </Styled.OffersList__Grid>
  );
};

export {
  OfferList,
};
