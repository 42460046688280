import { toString } from 'lodash-es';

import { OptionTypeBase } from 'react-select';

/**
 * Returns selected option object from options array.
 * Used in Select Component.
 */

export const findSelectOption = (options: OptionTypeBase[] = [], propValue: string, propName: string = 'value') => (
  options.find((option) => toString(option[propName]) === toString(propValue))
);
