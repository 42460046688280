import React, {
  forwardRef, memo, useImperativeHandle, useRef,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { TippyProps } from '@tippyjs/react';

import Box from '@ess/ui/Box';

import LazyTooltip from './LazyTooltip';

import 'tippy.js/animations/shift-away.css';
import 'tippy.js/animations/shift-toward.css';
import 'tippy.js/animations/perspective.css';

type TooltipProps = {
  children: React.ReactElement
  content: string | React.ReactElement
  animation?: string
  interactive?: boolean
  disabled?: boolean
  showOnMobile?: boolean
  contentPadding?: number
  appendTo?: HTMLElement | null,
  trigger?: string
  theme?: string
  zIndex?: number
  isInitialMount?: boolean
} & TippyProps;

const defaultProps = {
  animation: 'shift-away',
  trigger: 'mouseenter',
  theme: 'navy',
  appendTo: undefined,
  interactive: false,
  disabled: false,
  showOnMobile: false,
  contentPadding: 8,
  zIndex: 99999,
  isInitialMount: false,
};

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(({
  children,
  content,
  animation,
  interactive,
  disabled,
  contentPadding,
  trigger,
  theme,
  zIndex,
  appendTo,
  isInitialMount,
  ...props
}, ref) => {
  const tooltipRef = useRef<any>(null);
  const isDisabled = (isMobileOnly && !props.showOnMobile) || disabled;

  useImperativeHandle(ref, () => tooltipRef.current);

  return (
    <LazyTooltip
      ref={tooltipRef}
      theme={theme}
      disabled={isDisabled}
      isInitialMount={!!isInitialMount}
      content={(
        <Box p={`${contentPadding}px`}>
          {content}
        </Box>
      )}
      {...(props.visible !== undefined ? {} : { trigger })}
      {...(appendTo ? { appendTo } : {})}
      interactive={interactive}
      animation={animation}
      zIndex={zIndex}
      {...props}
    >
      {children}
    </LazyTooltip>
  );
});

Tooltip.defaultProps = defaultProps;

export default memo(Tooltip);
