import Cookies from 'js-cookie';

import { initI18n } from '@tourop/config/i18n';
import { CLOUD_ADDRESS } from './constants';

const language = window.location.pathname.split('/')[1];
const url = `${CLOUD_ADDRESS}/wcs/ReactTextsCache`;

Cookies.set('language', language);

initI18n({
  language,
  modules: ['common', 'ibe'],
  unstableConnectionSupport: false,
  serviceUrl: url,
  maxRetries: 3,
});
