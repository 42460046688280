import axios, { CancelTokenSource } from 'axios';
import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';
import { includes } from 'lodash-es';

import { IDictionary } from '@ess/types';

import { SEARCH_TIMEOUT } from '@ess/constants/api';

import {
  axiosInstance,
  refreshToken,
  refreshTokenLegacy,
} from '@ess/auth-provider';

const isReactWithAuth = () => window?.auth;

const retryDelayMs = 100;
const defaultServiceTimeout = SEARCH_TIMEOUT * 1000;

type cancelFunc = () => void

type sendRequestProps = {
  baseurl: string
  action: string | null
  postData: any
  onSuccess: (data: any) => void
  onError: (e: any) => void
  onCancel?: () => void
  maxRetryCount?: number
  retryCount: number
  getTokenUrl: string
  cancelTokenSource: CancelTokenSource | undefined
  serviceTimeout?: number | undefined
  headers?: any
};

const reqTimes = <any[]>[];

const tokens: { [index: string]: string } = {};

const getTokenActive: {
  [index: string]: { onSuccess: () => void, onFail: (error: any) => void }[]
} = {};

const logout = () => {
  window.location.href = isReactWithAuth() ? '/login?session-expired=1' : '/logon.html';
};

const getToken = (tokenUrl: string, onSuccess: () => void, onFail: (error: any) => void) => {
  if (getTokenActive[tokenUrl] === undefined) {
    getTokenActive[tokenUrl] = [];
  }
  const currentList = getTokenActive[tokenUrl];

  currentList.push({ onSuccess, onFail });
  if (currentList.length > 1) {
    return;
  }

  const request = isReactWithAuth() ? refreshToken() : refreshTokenLegacy(tokenUrl);

  request.then((response) => {
    getTokenActive[tokenUrl] = [];
    const token = isReactWithAuth() ? response?.data?.data?.token : response?.data?.token;

    if (token) {
      tokens[tokenUrl] = token;
      currentList.forEach(((value1) => value1.onSuccess()));
    } else {
      const error = new Error('error getting token');
      currentList.forEach(((value1) => value1.onFail(error)));
    }
  }).catch((error) => {
    if (Number(error?.response?.status) === 401) {
      logout();
      return;
    }

    getTokenActive[tokenUrl] = [];
    currentList.forEach(((value1) => value1.onFail(error)));
  });
};

const initializeTimeout = (timeout: number, cancelSource: any) => setTimeout(() => {
  cancelSource.cancel(`AxiosError: timeout of ${timeout}ms exceeded`);
}, timeout);

const resetRequestTimeout = (timeout: any) => {
  clearTimeout(timeout);
};

// simple sendRequest to silently handle retry
const sendRequest = ({
  baseurl,
  action,
  postData,
  onSuccess,
  onError,
  retryCount,
  maxRetryCount = 3,
  onCancel,
  getTokenUrl,
  cancelTokenSource,
  serviceTimeout,
  headers,
}: sendRequestProps): cancelFunc => {
  const url = action ? `${baseurl}/${action}` : baseurl;
  const cancelSource = cancelTokenSource ?? axios.CancelToken.source();
  const timeoutDuration = serviceTimeout ? serviceTimeout * 1000 : defaultServiceTimeout;
  const timeout = initializeTimeout(timeoutDuration, cancelSource);

  const requestHeaders: any = {
    'x-client-trace-id': uuidv4(),
    ...headers ? {
      ...headers,
    } : {},
  };

  Sentry.addBreadcrumb({
    level: 'info',
    category: 'getToken',
    data: {
      getTokenUrl,
      tokens: JSON.stringify(tokens),
      headers: JSON.stringify(requestHeaders),
      timeout: timeoutDuration,
    },
  });

  const perfStart = performance.now();

  axiosInstance.request({
    url,
    data: postData,
    method: postData ? 'POST' : 'GET',
    timeout: 0,
    headers: requestHeaders,
    cancelToken: cancelSource.token,
    onDownloadProgress: () => {
      resetRequestTimeout(timeout);
    },
  }).then((value) => {
    resetRequestTimeout(timeout);

    const duration = Math.floor(performance.now() - perfStart);
    const rdur = parseInt(value?.headers?.['x-envoy-upstream-service-time']) ?? 0;
    reqTimes.push({
      lat: rdur ? duration - rdur : null,
      url,
      bdur: duration,
      rdur,
      date: new Date().toISOString(),
    });
    if (reqTimes.length > 40) {
      reqTimes.splice(-30);
    }

    if (maxRetryCount === 999) {
      window.dispatchEvent(new CustomEvent('UnstableConnectionLoaderHide', {
        detail: { initiator: 'configFetch' },
      }));
    }

    if (retryCount > 0 && retryCount < maxRetryCount) {
      if (sessionStorage?.getItem('unstableConnection') === 'true') {
        sessionStorage?.setItem('unstableConnection', 'false');
      }
    }
    onSuccess(value.data);
  }).catch((error: any) => {
    const status = Number(error?.response?.status);

    if (status === 401) { // Session Timeout (handled)
      logout();
      return;
    }

    if (error?.message === 'Request aborted') { // Request aborted (handled)
      return;
    }

    if (status === 400) { // Bad Requests
      niceSentryError({
        url, error, postData, isRetry: false,
      });

      onError(error);

      return;
    }

    const isCancel = axios.isCancel(error);

    if (error?.message === 'caller') { // Handled
      onCancel?.();
      return;
    }

    const isInvalidToken = (
      Number(error?.response?.status) === 412
      || includes(error?.response?.data?.Error, 'INVALID TOKEN')
      || 'X-Token' in requestHeaders
    );

    if (isInvalidToken) {
      Sentry.addBreadcrumb({
        level: 'info',
        category: 'getToken',
        message: 'resp: 412',
      });
      getToken(getTokenUrl, () => {
        sendRequest({
          baseurl, action, postData, onSuccess, onError, retryCount, maxRetryCount, getTokenUrl, cancelTokenSource, headers: requestHeaders,
        });
      }, (error) => {
        const shouldRetry = error?.response || error?.request || isCancel;

        if (shouldRetry && retryCount > 0) {
          if (retryCount === maxRetryCount) {
            niceSentryError({
              url, error, postData, isRetry: retryCount === maxRetryCount,
            });
          }

          setTimeout(() => sendRequest({
            baseurl, action, postData, onSuccess, onError, retryCount: retryCount - 1, maxRetryCount, getTokenUrl, cancelTokenSource, headers: requestHeaders,
          }), retryDelayMs);
        } else {
          onError(error);

          niceSentryError({
            url, error, postData,
          });
        }
      });
      return;
    }

    const shouldRetry = error?.response || error?.request || isCancel;

    if (shouldRetry && retryCount > 0) {
      if (retryCount === maxRetryCount) {
        niceSentryError({
          url, error, postData, isRetry: retryCount === maxRetryCount,
        });
      }

      setTimeout(() => sendRequest({
        baseurl, action, postData, onSuccess, onError, retryCount: retryCount - 1, maxRetryCount, getTokenUrl, cancelTokenSource, headers: requestHeaders,
      }),
      retryDelayMs);

      if (maxRetryCount === 999) {
        window.dispatchEvent(new CustomEvent('UnstableConnectionLoaderShow', {
          detail: { initiator: 'configFetch' },
        }));
      }
    } else {
      console.error(error);
      onError(error);

      if (maxRetryCount === 999) {
        window.dispatchEvent(new CustomEvent('UnstableConnectionLoaderHide', {
          detail: { initiator: 'configFetch' },
        }));
      }

      niceSentryError({
        url, error, postData,
      });
    }
  });

  return () => cancelSource.cancel('caller');
};

// TODO: retryCount to calling places
export const promiseRequest = <T = any>(
  baseurl: string,
  postData: IDictionary<any> | null,
  retryCount = 0,
  cancelTokenSource: CancelTokenSource | undefined = undefined,
  serviceTimeout: number | undefined = undefined,
  headers: any = undefined,
) => new Promise<T>((resolve, reject) => {
  const rqParams = {
    headers,
    baseurl,
    action: null,
    postData,
    onSuccess: (data: any) => {
      resolve(data);
    },
    onError: (error: any) => {
      reject(error);
    },
    retryCount,
    maxRetryCount: retryCount,
    cancelTokenSource,
    onCancel: () => {
      reject();
    },
    getTokenUrl: '/trip/search/gettoken/',
    serviceTimeout,
  };
  Sentry.addBreadcrumb({
    level: 'info',
    category: 'http',
    data: Object.fromEntries(
      Object.entries(rqParams).map(
        ([k, v], i) => [k, JSON.stringify(v)],
      ),
    ),
  });
  return sendRequest(rqParams);
});

type SentryErrorProps = {
  url: string
  error: any
  postData: any
  isRetry?: boolean
  isDirectUrl?: boolean
}

const niceSentryError = ({
  url, error, postData, isRetry = false,
}: SentryErrorProps) => {
  Sentry.withScope((scope) => {
    if (postData) {
      const data = JSON.stringify(postData);

      scope.setContext('Post Data', {
        data,
        sizeInBytes: Buffer.from(data).length,
      });
    }

    scope.setContext('Error Full', {
      errorFull: JSON.stringify(error),
      request: error?.request ? JSON.stringify(error?.request) : {},
    });

    scope.setContext('Error', {
      message: error?.message ?? 'empty',
      status: error?.response?.status ?? 'empty',
      clientTraceId: error?.config?.headers['x-client-trace-id'] ? error.config.headers['x-client-trace-id'] : 'empty',
      response: error?.response?.data ? JSON.stringify(error.response.data) : 'empty',
      reqTimes: reqTimes.map((value) => (JSON.stringify(value))),
      token: error?.config?.headers['X-TOKEN'] ?? 'empty',
      tokens: JSON.stringify(tokens),
    });

    const code = String((error?.response?.status) ?? '');
    const message = String((error?.message) ?? 'no message');

    if (isRetry) {
      scope.setTransactionName(`Request Retry Needed: ${url} ${code} ${message}`);
      scope.setFingerprint([message, url, code]);
    } else {
      scope.setTransactionName(`HTTP error: ${url} ${code} ${message}`);
      scope.setFingerprint([message, url, code]);
    }

    Sentry.captureException(new Error(error));
  });
};

export const simpleRequest = <T = any>(
  url: string,
  postData: IDictionary<any> | null,
  headers: any = undefined,
) => new Promise<T>((resolve, reject) => {
  const simppleAxiosInsntace = axios.create({
    responseType: 'json',
  });
  simppleAxiosInsntace.request({
    url,
    data: postData,
    method: postData ? 'POST' : 'GET',
    timeout: 0,
  }).then((value) => {
    resolve(value.data);
  }).catch((ex) => {
    reject(ex);
  });
});

export default sendRequest;
