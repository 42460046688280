import {
  has, includes, isEmpty, merge, set, unset,
} from 'lodash-es';
import { useMemo } from 'react';

import { IConditions } from '@ess/types';

import { useAppDispatch, useSelector } from '@ess/store/core';

import { UNGROUPED_VIEW } from '@ess/constants/search';

import { formValuesToSearchRequestSchema, areHookInputsEqual } from '@ess/utils';

import useBreakpoints from '@ess/hooks/useBreakpoint';

import { replaceGeoWithCountry } from '@ess/store/slices/searchFormSlice';

import {
  useConditionsSelector,
  useV5Engine,
  useV5OffersList,
  useV5RegionsList,
} from '@ess/v5-data-provider/components';

import {
  IParamOptions,
  IV5DataGroupedList,
  IV5DataOfferList,
  IV5DataSet, priorityOrder,
  View,
} from '@ess/v5-data-provider/interfaces';

export const useMWStourop = () => {
  const v5engine = useV5Engine();
  const dispatch = useAppDispatch();

  return useMemo(() => ({
    setFilter: (values: any) => v5engine.SetFilterParams(formValuesToSearchRequestSchema(values)),
    resetFilter: () => v5engine.SetFilterParams({}),
    setFilterRegions: (values: string[]) => {
      if (values.length) {
        const countryList = new Set<string>();
        values.forEach((v) => {
          if (String(v).indexOf('_') > 0) {
            countryList.add(`${v.substr(0, v.indexOf('_'))}:`);
          } else {
            countryList.add(String(v));
          }
        });
        dispatch(replaceGeoWithCountry(values));
        const event = new CustomEvent('MXSearchFormGeoInteract',
          { detail: { geo: null, country: Array.from(countryList), submit: false } });
        window.dispatchEvent(event);
        v5engine.UpdateSearchParams((draft) => {
          if (has(draft, 'Base.DestinationLocation.Geo')) {
            unset(draft, 'Base.DestinationLocation.Geo');
            set(draft, 'Base.DestinationLocation.Id', Array.from(countryList));
          }
        });
      }
      v5engine.SetRegions({ Base: { DestinationLocation: { Id: values } } });
    },
    setFilterSelectedXCode: (xcode:number|null) => {
      if (xcode !== null) {
        v5engine.SetFilterParams({ Accommodation: { XCode: [Number(xcode)] } });
      } else {
        v5engine.SetFilterParams({});
      }
    },
    resetFilterRegions: () => v5engine.SetRegions({}),
    setSelectedObject: (groupKey: string | null) => v5engine.SetSelectedObject(groupKey),
    resetSelectedObject: () => v5engine.SetSelectedObject(null),
    setSelectedObjectToAll: () => v5engine.SetSelectedObject({}),
    setObjectsOrder: (order: string) => v5engine.SetOrder(View.GroupedList, [order]),
    setOffersOrder: (order: string) => v5engine.SetOrder(View.OfferList, [order]),
    setOffersPriorityOrder: (priorityOrder: priorityOrder) => v5engine.SetPriorityOrder(View.OfferList, priorityOrder),
    revert: () => v5engine.Revert(),
    apply: (noReset?:boolean) => v5engine.Commit(undefined, undefined, noReset),
    postSearch: (postData:any): Promise<any> => v5engine.postSearch(postData),
  }), []);
};

export const useSelectedViewDataState = (view: any, autoDataFetch = true): IV5DataSet => {
  const paramOptions = { ignoreObject: view === UNGROUPED_VIEW };
  const dataSetType = (view === UNGROUPED_VIEW ? View.OfferList : View.GroupedList);
  return useV5OffersList(dataSetType, autoDataFetch, paramOptions);
};

export const useSearchConditionsSelector = (
  ps: (e: IConditions) => any = (v: IConditions) => v,
  def: any = undefined, uncommitted = false, deps:any[] = [],
) => useConditionsSelector('search', ps, def, uncommitted, deps);

export const useFilterConditionsSelector = (
  ps: (e: IConditions) => any = (v: IConditions) => v,
  def: any = undefined, uncommitted = false, deps:any[] = [],
) => useConditionsSelector('filter', ps, def, uncommitted, deps);

export const useGroupedList = (autoFetch = true, paramOptions:IParamOptions = {}):
  IV5DataGroupedList => useV5OffersList(View.GroupedList, autoFetch, paramOptions) as IV5DataGroupedList;

export const useRegionList = (autoDataFetch = true, paramOptions:IParamOptions = {}): IV5DataSet => {
  const skiRegions = useSearchConditionsSelector(
    (state) => includes(state?.Accommodation?.Attributes, 'location_ski_resorts'),
    undefined, (paramOptions?.uncommitted?.regions ?? false), [],
  );
  return useV5RegionsList(skiRegions ? View.SkiRegionList : View.RegionList, autoDataFetch, paramOptions);
};

export const useObjectListOrder = ():[string, (neworder:string)=>void] => {
  const { apply, setObjectsOrder } = useMWStourop();
  return [useConditionsSelector(
    'orderGroups',
    ((e) => [e]),
    undefined,
    false,
    [],
  ), (order: string) => {
    setObjectsOrder(order);
    apply();
  }];
};

export const useSelectedObject = () => useConditionsSelector('selectedObject', ((e) => (isEmpty(e) ? '' : e)),
  undefined, false, []);

export const useIsSelectedObject = (groupKeyValue:string) => useConditionsSelector('selectedObject',
  ((e) => (groupKeyValue === e)), undefined, false, [groupKeyValue]);

export const useFilterRegion = (uncommitted = false) => useConditionsSelector('regions', (e) => (
  { 'Base.DestinationLocation': (e.Base?.DestinationLocation as any)?.Id ?? [] }
), undefined, uncommitted, []);

export const useIsSearchByMap = () => useConditionsSelector('search', (e) => (
  e.Base?.DestinationLocation?.Geo !== undefined
), undefined, false, []);

export const useSearchConditionsParticipants = () => useSearchConditionsSelector(
  (conditions) => conditions.Base?.ParticipantsList, undefined, false, [],
);

export const useListGroupedByOperator = (paramOptions:IParamOptions):IV5DataGroupedList => useV5OffersList(
  View.GroupedList,
  true,
  merge({}, paramOptions, { applyparams: { groupType: 'Base.Operator' } }),
) as IV5DataGroupedList;

export const useOfferListGroupedByOperator = (paramOptions:IParamOptions):
  IV5DataGroupedList => useV5OffersList(
    View.GroupedList,
    true,
    merge({}, paramOptions, { useObjectForFilters: true, applyparams: { groupType: 'Base.Operator' } }),
  ) as IV5DataGroupedList;

export const useFilterConditionsFlat = (uncommitted = false) => useConditionsSelector('filter', (e) => ({
  'Base.StartDate': (e.Base?.StartDate?.After ?? ''),
  'Base.StartDateDayOfWeek': (e.Base?.StartDate?.DayOfWeek?.[0] ?? ''),
  'Base.NightsBeforeReturn': (e.Base?.NightsBeforeReturn?.max?.toString() ?? -1),
  'Base.XCity': (e.Base?.XCity ?? ''),
  'Base.DepartureLocation': (e.Base?.DepartureLocation?.Id ?? ''),
  'Accommodation.Room': (e.Accommodation?.Room ?? ''),
  'Accommodation.Category': (e.Accommodation?.Category?.min?.toString() ?? ''),
  'Base.Catalog': (e.Base?.Catalog ?? ''),
  'Base.Operator': (e.Base?.Operator ?? ''),
  'Accommodation.XService': (e.Accommodation?.XService?.toString() ?? ''),
  'Accommodation.XCode': (e.Accommodation?.XCode ?? ''),
  'Accommodation.BuildRenovationYear': (e.Accommodation?.BuildRenovationYear?.min?.toString() ?? ''),
  'Accommodation.Attributes': (e.Accommodation?.Attributes ?? []),
  'Accommodation.Camp.Theme': (e.Accommodation?.Camp?.Theme ?? []),
}), undefined, uncommitted, []);

export const useIsExtendedViewDetails = ():{isExtendedViewDetails:boolean, selectedObject:any } => {
  const [view, extendedViewDetails] = useSelector(
    (state) => [state.searchResults.view, state.searchResults.extendedViewDetails],
    areHookInputsEqual as any,
  );

  const breakpoint = useBreakpoints();
  const isNotMobile = !includes(['xxs', 'xs', 'sm', 'md'], breakpoint);
  const checkXcode = view === 'GROUPED_EXTENDED_VIEW' && isNotMobile && extendedViewDetails;
  const xcode = useConditionsSelector('selectedObject', ((e) => (checkXcode ? (isEmpty(e) ? '' : e) : '')),
    undefined,
    false,
    [checkXcode]);
  return {
    isExtendedViewDetails: !isEmpty(xcode),
    selectedObject: xcode,
  };
};

export const useFilterFetchConditions = (uncommited:boolean):IConditions => {
  const conditions = useConditionsSelector('filter', (v) => v, undefined, uncommited, []);
  const { isExtendedViewDetails, selectedObject } = useIsExtendedViewDetails();
  if (isExtendedViewDetails) {
    return { ...conditions, Accommodation: { ...conditions.Accommodation, XCode: [Number(selectedObject)] } };
  }
  return conditions;
};

export const useOfferListData = (autoFetch = true, paramOptions: IParamOptions = {}):
  IV5DataOfferList => useV5OffersList(View.OfferList, autoFetch, paramOptions);

export const useOfferListOrder = (): [string, (neworder:string)=>void] => {
  const { apply, setOffersOrder } = useMWStourop();
  return [useConditionsSelector('orderOffers', ((e) => [e]), undefined, false, []), (order: string) => {
    setOffersOrder(order);
    apply();
  }];
};

export const useOfferListPriorityOrder = (): [priorityOrder, (newPriorityOrder: priorityOrder) => void] => {
  const { apply, setOffersPriorityOrder } = useMWStourop();
  return [useConditionsSelector('orderPriorityOffers', ((e) => [e]), undefined, false, []), (orderPriority) => {
    setOffersPriorityOrder(orderPriority);
    apply();
  }];
};
