import React, { useEffect } from 'react';

import { IDictionary } from '@ess/types';

import useOfferContentService from '@ess/hooks/useOfferContentService';

import FlexBox from '@ess/ui/FlexBox';
import Loader from '@ess/ui/Loader';

import { PriceTimeLine } from '@ess/components/ContentSections/PriceTimeLine';

type AlternativeDatesProps = {
  offerData: any
  onChange: any
  isBasket?: boolean
}

const cache: IDictionary<any> = {};

const AlternativeDates = ({ offerData, onChange, isBasket = false }: AlternativeDatesProps) => {
  const {
    results,
    errors,
    setOffer,
    fetchSections,
    requestParams,
    pendingSections,
  } = useOfferContentService();

  const data = offerData?.Base?.OfferId in cache
    ? cache[offerData?.Base.OfferId]
    : results?.Sections?.PriceChart;

  const onContainerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const onToggle = () => {
    setTimeout(() => {
      onChange();
    }, 100);
  };

  useEffect(() => {
    setOffer({ offer: offerData } as any);
  }, []);

  useEffect(() => {
    if (!(requestParams?.OfferId in cache) && requestParams?.OfferId) {
      (async () => {
        const response = await fetchSections({
          sections: ['PriceChart'],
        });

        cache[requestParams?.OfferId] = response?.Sections?.PriceChart ?? {};
      })();
    }
  }, [requestParams?.OfferId]);

  return (
    <FlexBox
      mt="small"
      mr="-10px"
      ml="-10px"
      mb="-10px"
      backgroundColor="#f6f6f6"
      onClick={onContainerClick}
    >
      {pendingSections.includes('PriceChart') ? (
        <FlexBox
          width="100%"
          height="180px"
          alignItems="center"
          justifyContent="center"
        >
          <Loader type="dots" size="20px"/>
        </FlexBox>
      ) : (
        <PriceTimeLine
          isLoading={pendingSections.includes('PriceChart')}
          isBasket={isBasket}
          height={180}
          otherOperatorsEnabled={false}
          onToggle={onToggle}
          data={data}
        />
      )}
    </FlexBox>
  );
};

export {
  AlternativeDates,
};
