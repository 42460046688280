import React from 'react';
import { useTranslation } from 'react-i18next';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Tooltip from '@ess/ui/Tooltip';

type NoteOnlyProps = {
  data: any
  onClick: () => void
}

const NoteOnly = ({ data, onClick }: NoteOnlyProps) => {
  const { t } = useTranslation();
  const { rating } = data;

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Tooltip content={t('tripadvisor_note')}>
      <FlexBox
        width="24px"
        height="22px"
        borderRadius={6}
        mr="small"
        justifyContent="center"
        alignItems="center"
        onClick={onClickHandler}
      >
        <Text fontSize="11px" fontWeight="bold">{rating}</Text>
      </FlexBox>
    </Tooltip>
  );
};

export default NoteOnly;
