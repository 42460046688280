import React, { CSSProperties, forwardRef } from 'react';
import {
  ColorProps, FontSizeProps, SpaceProps, FontStyleProps, TypographyProps,
} from 'styled-system';
import { isString } from 'lodash-es';

import Icon from '@ess/ui/Icon';
import FlexBox from '@ess/ui/FlexBox';

import { Styled } from './Anchor.styles';

type AnchorProps = {
  children: React.ReactNode
  onClick?: (event: React.MouseEvent) => void
  startIcon?: string | React.ReactNode,
  endIcon?: string | React.ReactNode,
  color?: string
  disabled?: boolean
  href?: string
  target?: string
  style?: CSSProperties,
} & ColorProps & FontSizeProps & SpaceProps & FontStyleProps & TypographyProps;

const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(({
  children,
  disabled = false,
  onClick = undefined,
  href = undefined,
  startIcon = '',
  endIcon = '',
  target = '_self',
  color = 'green',
  style = {},
}: AnchorProps, ref) => {
  const icon = startIcon || endIcon;
  const iconNode = icon ? isString(icon) ? (
    <Icon
      {...(startIcon ? { pr: 5 } : { pl: 5 })}
      iconName={icon}
      size="inherit"
    />
  ) : (
    <FlexBox as="span" {...(startIcon ? { pr: 5 } : { pl: 5 })}>
      {icon}
    </FlexBox>
  ) : false;

  const onClickHandler = (event: React.MouseEvent) => {
    if (!disabled && onClick) {
      event.preventDefault();
      onClick(event);
    }
  };

  return (
    <Styled.Anchor
      ref={ref}
      onClick={onClickHandler}
      disabled={disabled}
      target={target}
      style={style}
      color={color}
      {...href ? { href } : {}}
    >
      {startIcon && iconNode}
      {typeof children === 'string' ? (
        <Styled.AnchorText>
          {children}
        </Styled.AnchorText>
      ) : children}
      {endIcon && iconNode}
    </Styled.Anchor>
  );
});

export default Anchor;
