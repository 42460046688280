import styled from 'styled-components';
import { motion } from 'framer-motion';

const Switch__Label = styled.label`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin: 0 !important;
    min-height: 100% !important;
`;

const Switch__Base = styled(motion.div)`
    display: flex;
    align-items: inherit;
    justify-content: inherit;
    position: absolute;
    width: 14px;
    height: 14px;
    z-index: 1;
    margin: 0 !important;
`;

const Switch__Track = styled.span`
    width: 100%;
    height: 100%;
    border-radius: 52px;
    box-shadow: inset 1px 1px 1px rgb(0 0 0 / 20%);
    opacity: 0.85;
    transition: all .25s ease-in;
`;

const Switch__Thumb = styled.span`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 100%;
    box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 30%);
`;

const Switch = styled.span<{ checked: boolean, disabled: boolean }>`
    display: inline-flex;
    align-items: center;
    width: 40px;
    height: 20px;
    z-index: 0;
    position: relative;
    box-sizing: border-box;
    flex-shrink: 0;
    vertical-align: middle;
    cursor: pointer;

    ${Switch__Track} {
      background-color: ${({ theme, checked }) => (checked ? theme.colors.green : theme.colors.gray)};
    }

   ${({ disabled }) => disabled && `
      ${Switch__Track} {
        background-color: #a0a0a0;
        cursor: not-allowed;
      }

      ${Switch__Thumb} {
        background-color: #dadada;
      }
   `};
`;

const Switch__Input = styled.input`
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    z-index: 1;
    position: absolute;
`;

export const Styled = {
  Switch,
  Switch__Base,
  Switch__Label,
  Switch__Track,
  Switch__Input,
  Switch__Thumb,
};
