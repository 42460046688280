import { useTranslation } from 'react-i18next';

import FlexBox from '@ess/ui/FlexBox';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <FlexBox
      p="large"
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
    >
      {t('lbl_ibelink_disclaimer')}
    </FlexBox>
  );
};

export {
  Footer,
};
