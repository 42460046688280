import React, {
  CSSProperties,
  forwardRef,
  useEffect,
  useState,
  memo,
} from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import useWindowSizeSelector from '@ess/hooks/useWindowSizeSelector';

import ModalOverlay from '../../ModalOverlay';

import { Styled } from '../Modal.styles';

export type ModalApi = {
  maxModalContentHeight: number;
};

export type ModalProps = {
  title?: string;
  id?: string;
  children: React.ReactNode | ((api: ModalApi) => React.ReactNode);
  onClose?: (event?: React.MouseEvent) => void;
  onOpen?: (event?: React.MouseEvent) => void;
  controls?: React.ReactElement | undefined;
  positionedByPopper?: boolean;
  isOpen?: boolean;
  showOverlay?: boolean;
  maxHeight?: number | string;
  onClick?: () => void;
  showCloseIcon?: boolean;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  appendTo?: Element | null;
  width?: number | string;
  theme?: string;
  search?: boolean;
  zIndex?: number;
};

const Modal = forwardRef<
  HTMLDivElement | React.SetStateAction<HTMLDivElement | null>,
  ModalProps
>(
  (
    {
      id = undefined,
      title = undefined,
      width = 400,
      children,
      controls = undefined,
      onClose = undefined,
      onOpen = undefined,
      onClick = undefined,
      showOverlay = true,
      showCloseIcon = true,
      isOpen = false,
      appendTo = undefined,
      maxHeight = 0,
      positionedByPopper = false,
      theme = 'white',
      search = false,
      contentStyle = {},
      zIndex = 99999,
      style = {},
      ...props
    },
    ref,
  ) => {
    const height = useWindowSizeSelector(({ height }) => height, [], isOpen);
    const [maxModalContentHeight, setMaxModalContentHeight] = useState<number>(0);

    /**
     * Modal body click handler.
     * @param event
     */
    const onClickHandler = (event: React.MouseEvent) => {
      event.stopPropagation();

      if (onClick) {
        onClick();
      }
    };

    /**
     * Modal close button click handler.
     * @param event
     */
    const closeBtnClickHandler = (event: React.MouseEvent) => {
      event.stopPropagation();

      if (onClose) {
        onClose();
      }
    };

    useEffect(() => {
      const maxModalHeight = positionedByPopper ? maxHeight : height;

      if (maxModalHeight) {
        const contentHeight = Number(maxModalHeight) - 50;
        const controlsHeight = controls ? 50 : 0;
        const searchHeight = search ? 70 : 0;

        setMaxModalContentHeight(contentHeight - controlsHeight - searchHeight);
      }
    }, [maxHeight, height]);

    useEffect(() => {
      if (isOpen && onOpen) {
        onOpen();
      }
    }, [isOpen]);

    return (
      <>
        {createPortal(
          <>
            {isOpen && (
              <>
                {/* @ts-ignore */}
                <Styled.Modal
                  ref={ref}
                  key="modal"
                  maxHeight={height}
                  onClick={onClickHandler}
                  themeType={theme}
                  width={width}
                  style={style}
                  zIndex={zIndex}
                  {...{ ...(id ? { id } : {}) }}
                  {...props}
                >
                  {title && (
                    <Styled.Modal__Header themeType={theme}>
                      {title && (
                        <Styled.Modal__Title>{title}</Styled.Modal__Title>
                      )}
                      {showCloseIcon && (
                        <Styled.Modal__CloseIcon onClick={closeBtnClickHandler}>
                          <FontAwesomeIcon
                            icon={faTimes}
                            color="inherit"
                            size="1x"
                          />
                        </Styled.Modal__CloseIcon>
                      )}
                    </Styled.Modal__Header>
                  )}
                  <Styled.Modal__Content style={contentStyle}>
                    {typeof children === 'function'
                      ? children({ maxModalContentHeight })
                      : children}
                  </Styled.Modal__Content>
                  {controls && (
                    <Styled.Modal__Controls>{controls}</Styled.Modal__Controls>
                  )}
                </Styled.Modal>
                {showOverlay && <ModalOverlay zIndex={zIndex} onClick={onClose} />}
              </>
            )}
          </>,
          (!appendTo
            ? document.querySelector('.modal-root')
            : appendTo) as HTMLElement,
        )}
      </>
    );
  },
);

export default memo(Modal);
