import React from 'react';

import { NO_PRINT_CLASS } from './constants';

export type NoPrintProps = {
  children: React.ReactElement
}

const NoPrint = ({ children }: NoPrintProps) => (
  <>
    {React.Children.map(children, (child) => React.cloneElement(child, {
      className: `${child.props.className} ${NO_PRINT_CLASS}`,
    }))}
  </>
);

export { NoPrint };
