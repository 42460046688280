import React from 'react';

import { PRINT_ONLY } from './constants';

export type NoPrintProps = {
  children: React.ReactElement
}

const PrintOnly = ({ children }: NoPrintProps) => (
  <>
    {React.Children.map(children, (child) => React.cloneElement(child, {
      className: `${child.props.className} ${PRINT_ONLY}`,
    }))}
  </>
);

export { PrintOnly };
