import Cookies from 'js-cookie';
import { axiosInstance } from '@ess/auth-provider';
import { CONTENT_SERVICE_URL } from '@ess/constants/api';

import { ACCESS_TOKEN_KEY } from '../constants';

const storeNewToken = (token: string) => {
  Cookies.set(ACCESS_TOKEN_KEY, token);
};

const refreshToken = async () => {
  let request = null;

  request = await axiosInstance.post(`${CONTENT_SERVICE_URL}Login`, {
    Query: 'TokenRefresh',
  });

  const { data } = request ?? {};
  const token = data?.data?.token ?? '';

  storeNewToken(token);

  return request;
};

const refreshTokenLegacy = async (tokenUrl: string, timeout = 10000) => {
  let request = null;

  request = await axiosInstance.post(tokenUrl, {}, { timeout });

  const { data } = request ?? {};
  const token = data?.token ?? '';

  storeNewToken(token);

  return request;
};

export {
  refreshToken,
  refreshTokenLegacy,
};
