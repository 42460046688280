import styled from 'styled-components';
import {
  space, flexbox, layout, color, border, position, shadow, boxShadow, borderRadius,
} from 'styled-system';

const FlexBox = styled.div`
  display: flex;
  ${position}
  ${space}
  ${flexbox}
  ${layout}
  ${color}
  ${border}
  ${shadow}
  ${boxShadow}
  ${borderRadius}
`;

export const Styled = {
  FlexBox,
};
