export enum SettingsType {
  Select = 'select',
  Switch = 'switch',
}

interface Option {
  label: string;
  value: any;
}

export enum DetailsSectionState {
  Expanded = 'expanded',
  Reduced = 'reduced',
  Hidden = 'hidden',
}

export interface Setting {
  label: string;
  type: SettingsType;
  defaultValue: any;
  options?: Option[];
  searchFormField?: string;
  visible?: boolean;
}

export type SettingsSection = Record<string, Setting>;
export type DisplaySection = any;
