import { components, OptionTypeBase } from 'react-select';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { compact } from 'lodash-es';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Drawer from '../../../../Drawer';
import TextInput from '../../../TextInput';
import { Button } from '../../../../Button';
import FlexBox from '../../../../FlexBox';

const Menu = ({
  children,
  ...props
}: any) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState<HTMLInputElement | null>(null);
  const [fixedSize, setFixedSize] = useState<{ width: string, height: string } | undefined>(undefined);
  const close = () => {
    if (props.selectProps.passRef.current) {
      props.selectProps.passRef.current.blur();
    }
  };

  const groupContentStyles = {
    display: 'grid',
    gridTemplateColumns: `${100 / props.selectProps.columns}% `.repeat(props.selectProps.columns),
  };

  useEffect(() => {
    if (props.selectProps.menuIsOpen && props.selectProps.passRef.current.select.menuListRef && !fixedSize) {
      setFixedSize({
        height: `${props.selectProps.passRef.current.select.menuListRef.clientHeight}px`,
        width: `${(props.selectProps.passRef.current.select.menuListRef.clientWidth + 30)}px`,
      });
    }
  }, [props.selectProps.menuIsOpen, props.selectProps.passRef.current.select.menuListRef]);

  const wrapperOnClick = () => {
    if (isMobileOnly && searchInput) {
      searchInput.blur();
    }
  };

  return (
    isMobileOnly
      ? (
        <Drawer
          top={0}
          position="bottom"
          showOverlay
          title={props.selectProps.label}
          isOpen
          onClose={close}
          fixHeight
          contentStyles={{ overflow: 'hidden auto', height: '100%' }}
          clickOutsideIgnoreElements={[props.selectProps.passRef.current.select.controlRef]}
          controls={(
            <FlexBox
              alignItems="center"
              justifyContent="flex-end"
              p="small"
            >
              <Button
                width="90px"
                variant="secondary"
                size="small"
                mr="small"
                label={t('clear')}
                {...{
                  onTouchEnd: (event) => {
                    props.selectProps.onClear(event);
                    props.selectProps.setInputValue('');
                  },
                  onClick: isMobileOnly ? () => {} : props.selectProps.onClear,
                }}
              />
              <Button
                variant="primary"
                size="small"
                label={t('lbl_apply')}
                width="90px"
                {...{
                  onTouchEnd: props.selectProps.onConfirm,
                  onClick: isMobileOnly ? () => {} : props.selectProps.onConfirm,
                }}
              />
            </FlexBox>
        )}
        >
          <FlexBox flexDirection="column" onClick={wrapperOnClick} height="100%">
            {isMobileOnly && props.selectProps.isSearchable && (
            <FlexBox p="10px">
              <TextInput
                ref={setSearchInput}
                onChange={(event) => {
                  props.selectProps.setInputValue(event.target.value);
                  const newOptions = compact(props.selectProps.ungroupedOptions.map(
                    (option: OptionTypeBase) => (props.selectProps.filterOption(option, event.target.value) ? option : undefined),
                  ));
                  props.selectProps.setSelectOptions(newOptions);
                }}
                value={props.selectProps.inputValue}
                unControlled
                isClearable
                onClear={() => {
                  props.selectProps.setInputValue('');
                }}
                onClick={(event) => {
                  if (searchInput) {
                    searchInput.focus();
                  }
                  event.stopPropagation();
                }}
                onFocus={() => props.selectProps.setIsOpen(true)}
                onBlur={() => props.selectProps.passRef.current && props.selectProps.passRef.current.focus()}
                startIcon={<FontAwesomeIcon icon={faSearch} />}
              />
            </FlexBox>
            )}
            <FlexBox flexDirection="column" style={{ height: '100%', overflow: 'hidden auto' }}>
              {children}
            </FlexBox>
          </FlexBox>
        </Drawer>
      )
      : (
        <>
          <FlexBox flexDirection="column" style={{ ...fixedSize || {}, minWidth: '100%' }}>
            <components.MenuList {...props}>
              {!props.selectProps.hasGroups ? (
                <div>
                  <div style={groupContentStyles}>
                    {children}
                  </div>
                </div>
              ) : children}
            </components.MenuList>
          </FlexBox>
          <FlexBox backgroundColor="white" p="5px" flexDirection="column" style={{ borderTop: '1px solid gray' }}>
            <FlexBox width="100%">
              <Button
                mr="small"
                variant="secondary"
                label={t('clear')}
                size="small"
                width="50%"
                {...{
                  onTouchEnd: props.selectProps.onClear,
                  onClick: isMobileOnly ? () => {} : props.selectProps.onClear,
                }}
              />
              <Button
                variant="primary"
                label={t('lbl_apply')}
                size="small"
                width="50%"
                {...{
                  onTouchEnd: props.selectProps.onConfirm,
                  onClick: isMobileOnly ? () => {} : props.selectProps.onConfirm,
                }}
              />
            </FlexBox>
          </FlexBox>
        </>
      )
  );
};

export default Menu;
