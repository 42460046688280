import styled from 'styled-components';

const HotelAttribute = styled.div<{ isHighlighted: boolean, withLabel: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${({ withLabel }) => (withLabel ? '3px 7px' : '0')};
    width: ${({ withLabel }) => (withLabel ? 'auto' : '26px')};
    height: ${({ withLabel }) => (withLabel ? 'auto' : '26px')};
    font-size: ${({ withLabel }) => (withLabel ? '18px' : '20px')};
    border-radius: 15px;
    border: ${({ isHighlighted }) => (isHighlighted ? '2px solid #102c58' : '1px solid rgb(204,204,204)')};
`;

const HotelAttribute__Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
`;

const HotelAttributesGroup = styled.div<{ withLabel: boolean }>`
    display: flex;
    flex-wrap: wrap;
    margin: ${({ withLabel }) => (withLabel ? '-3px' : '-1.5px')};

    ${HotelAttribute} {
        margin: ${({ withLabel }) => (withLabel ? '3px' : '1.5px')};
    }
`;

export const Styled = {
  HotelAttribute,
  HotelAttribute__Icon,
  HotelAttributesGroup,
};
