import styled from 'styled-components';

const OffersList__Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;

export const Styled = {
  OffersList__Grid,
};
