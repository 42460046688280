import React, { createContext, useReducer, useMemo } from 'react';

import basketReducer, { IBasketContext } from './reducer';

const initialState = {
  basketId: null,
  basketItems: [],
};

type BasketProviderProps = {
  children: React.ReactNode,
}

const BasketContext = createContext<{
  state: IBasketContext;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const BasketProvider = ({ children }: BasketProviderProps) => {
  const [state, dispatch] = useReducer(basketReducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <BasketContext.Provider value={value}>
      {children}
    </BasketContext.Provider>
  );
};

export default BasketProvider;
export {
  BasketContext,
};
