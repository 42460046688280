import styled from 'styled-components';
import { isNumber } from 'lodash-es';

const ScrollBar = styled.div<{ width?: number | string, height?: number | string}>`
  width: ${({ width }) => `${width ? isNumber(width) ? `${width}px` : width : '100%'}`};
  height: ${({ height }) => `${height ? isNumber(height) ? `${height}px` : height : '100%'}`};
  overflow: auto;

  &::-webkit-scrollbar {
    height: 5px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track:hover {
    background-color:  transparent;
  }

  &::-webkit-scrollbar-track:active {
    background-color:  transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #B5B5B5;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #9A9A9A;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: #BFBFBF;
  }

`;

export const Styled = {
  ScrollBar,
};
