import { includes, toNumber } from 'lodash-es';

/**
 * Returns hotel XCode from Hotel.Name AutoSuggest value.
 *
 * @example
 * getHotelXcodeFromValue('x:15665|Amaya Hills');
 * // -> 15665
 */

export const getHotelXcodeFromValue = (value: string): number | null => {
  const valueArray = value ? includes(value, '|') ? value.split('|') : [value] : [];
  const [UniqueObjectId] = valueArray;
  const xCode = includes(UniqueObjectId, 'x:') ? UniqueObjectId.split(':')[1] : UniqueObjectId;

  return valueArray.length ? toNumber(xCode) : null;
};
