import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faAngleDown } from '@fortawesome/pro-solid-svg-icons';

const DropdownIndicator = (props) => {
  const {
    children = (
      <FontAwesomeIcon
        icon={props.isDisabled ? faLock : faAngleDown}
        {...{ ...props.isDisabled ? { size: 'sm' } : {} }}
      />
    ),
    getStyles,
    innerProps: { ref },
  } = props;

  return (
    <div onClick={undefined} ref={ref} style={getStyles('dropdownIndicator', props)}>
      {children}
    </div>
  );
};

export default DropdownIndicator;
