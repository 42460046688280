import { IDictionary } from '@ess/types';

/**
 * Participants type codes.
 */

export const ADULT_CODE: string = 'ADULT';
export const CHILD_CODE: string = 'CHILD';
export const INFANT_CODE: string = 'INFANT';
export const SENIOR_CODE: string = 'SENIOR';

/**
 * Participants translation keys.
 */

export const PARTICIPANT_TEXTS: IDictionary<string> = {
  ADULT: 'sb_field_adult',
  CHILD: 'sb_field_child',
  INFANT: 'sb_field_infant',
};
