export const ACCESS_TOKEN_KEY = 'accessToken';
export const TOUROP_LOGIN_ENDPOINT = '/tourop/crosslogon/';
export const TOUROP_LOGOUT_ENDPOINT = '/tourop/crosslogout/';

/* Auth Errors */
export const AUTH_ERROR: Record<string, string> = {
  ErrorGeneral: 'ErrorGeneral',
  ErrorMissingUser: 'ErrorMissingUser',
  ErrorResetAlreadySet: 'ErrorResetAlreadySet',
  ErrorUserSecuredPinInvalid: 'ErrorUserSecuredPinInvalid',
  ErrorUserLoginOrPasswordInvalid: 'ErrorUserLoginOrPasswordInvalid',
  ErrorMissingCredentials: 'ErrorMissingCredentials',
  ErrorMissingCode: 'ErrorMissingCredentials',
  ErrorUserLoginNotActive: 'ErrorUserLoginNotActive',
  ErrorUserPasswordExpired: 'ErrorUserPasswordExpired',
  ErrorUserSecuredPinExpired: 'ErrorUserSecuredPinExpired',
};

export const AUTH_ERROR_TRANSLATIONS: Record<string, string> = {
  ErrorGeneral: 'lbl_auth_error',
  ErrorMissingUser: 'lbl_invalid_login',
  ErrorResetAlreadySet: 'lbl_reset_already_set',
  ErrorUserSecuredPinInvalid: 'lbl_error_invalid_secure_code',
  ErrorUserLoginOrPasswordInvalid: 'lbl_error_invalid_credentials',
  ErrorMissingCredentials: 'requiredErrorMessage',
  ErrorUserLoginNotActive: 'lbl_error_user_not_active',
  ErrorUserPasswordExpired: 'lbl_error_password_expired',
  ErrorUserSecuredPinExpired: 'lbl_error_secure_code_expired',
  ErrorMissingCode: 'requiredErrorMessage',
};

/* Validation Errors */

export const VALIDATION_ERROR: Record<string, string> = {
  ErrorUserLoginOrPasswordInvalid: 'lbl_error_invalid_credentials',
  ErrorMissingCredentials: 'lbl_error_missing_credentials',
  ErrorUserLoginNotActive: 'lbl_error_user_not_active',
  ErrorUserPasswordExpired: 'lbl_error_password_expired',
  ErrorUserSecuredPinExpired: 'lbl_error_secure_code_expired',
};

export const VALIDATION_ERROR_TRANSLATIONS: Record<string, string> = {
  RequiredField: 'requiredErrorMessage',
  PasswordToShort: 'lbl_password_too_short',
  PasswordRequiresNumber: 'lbl_password_requires_number',
  PasswordRequiresLowercaseLetter: 'lbl_password_requires_lowercase_letter',
  PasswordRequiresUppercaseLetter: 'lbl_password_requires_uppercase_letter',
  PasswordsDoNotMatch: 'lbl_password_do_not_match',
};

/* URL */
export const LOGIN_URL = '/login';
export const AFTER_LOGIN_URL = '/trip/search/';
export const SECURE_URL = '/secure';
export const PASSWORD_RECOVERY_URL = '/password-recovery';
