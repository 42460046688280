const space: any = ['5px', '10px', '15px', '20px', '25px'];

space.tiny = space[0];
space.small = space[1];
space.medium = space[2];
space.large = space[3];
space.xLarge = space[4];

export default {
  space,
};
