import React, { useCallback, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import { nanoid } from 'nanoid';

import { COMMON_PAGE_STYLES } from './constants';

import { appendStyles, copyStyles, createPrintIframe } from './utils';
import ReactPrintProvider, { ReactPrintContext } from './context';

export interface PrintReactApi {
  triggerPrint: () => void
}

export type PrintReactProps = {
  children: React.ReactNode | ((api: PrintReactApi) => React.ReactNode)
  customPageStyles?: string
  copyParentStyles?: boolean
  content: React.RefObject<any>
}

const defaultProps = {
  customPageStyles: undefined,
  copyParentStyles: true,
};

const PrintReact = ({
  children,
  content,
  customPageStyles,
  copyParentStyles,
}: PrintReactProps) => {
  const printIframeId = useRef<string>(nanoid());
  const printIframe = useRef<HTMLIFrameElement | null>(null);

  /**
   * Prints iFrame content.
   */
  const print = () => {
    printIframe.current = createPrintIframe(printIframeId.current);

    printIframe.current.onload = () => {
      const iFrame = printIframe?.current?.contentWindow || printIframe;
      const document = (printIframe?.current?.contentDocument || printIframe?.current?.contentWindow?.document) as Document;

      // eslint-disable-next-line react/no-find-dom-node
      const contentNodes = findDOMNode(content.current);
      const clonedContentNodes = contentNodes?.cloneNode(true);
      const renderComponentImgNodes = (clonedContentNodes as Element).querySelectorAll('img');

      for (let i = 0; i < renderComponentImgNodes.length; i++) {
        const imgNode = renderComponentImgNodes[i];
        const imgSrc = imgNode.getAttribute('src');

        if (imgSrc) {
          const img = new Image();
          img.src = imgSrc;
        }
      }

      document?.body.appendChild(clonedContentNodes as Node);

      appendStyles(document, COMMON_PAGE_STYLES);

      if (customPageStyles) {
        appendStyles(document, customPageStyles);
      }

      if (copyParentStyles) {
        copyStyles(document);
      }

      printIframe?.current?.focus();

      setTimeout(() => {
        if ('print' in iFrame) {
          iFrame.print();
        }
      }, 1000);
    };

    removeIframe();
    document.body.appendChild(printIframe.current);
  };

  /**
   * Removes iFrame from DOM.
   */
  const removeIframe = () => {
    const documentIframe = document.getElementById(`react-print-${printIframeId.current}`);

    if (documentIframe) {
      document.body.removeChild(documentIframe);
    }
  };

  /**
   * Triggers print function.
   */
  const triggerPrint = useCallback(() => {
    print();
  }, [printIframe.current]);

  return (
    <ReactPrintProvider triggerPrint={triggerPrint}>
      {typeof children === 'function'
        ? children({
          triggerPrint,
        })
        : children}
    </ReactPrintProvider>
  );
};

PrintReact.defaultProps = defaultProps;

export {
  PrintReact,
  ReactPrintContext,
};
