import * as Sentry from '@sentry/react';

const RELEASE = process.env.NX_RELEASE || 'dev';
const isDev = process.env.NODE_ENV === 'development';

const IBE_LINK_DEV = 'IBE_LINK_DEV';
const IBE_LINK_PREPROD = 'IBE_LINK_PREPROD';
const IBE_LINK_PROD = 'IBE_LINK_PROD';

const HOSTNAMES: Record<string, string> = {
  'ibelinkdev.merlinx.pl': IBE_LINK_DEV,
  'ibelink.merlinx.pl': IBE_LINK_PREPROD,
  'ibelink.merlinx.eu': IBE_LINK_PROD,
};

const ENVIRONMENTS: Record<string, string> = {
  [IBE_LINK_DEV]: 'dev',
  [IBE_LINK_PREPROD]: 'pre-production',
  [IBE_LINK_PROD]: 'production',
};

const getEnvironment = () => {
  const pathname = window.location.hostname;

  if (pathname in HOSTNAMES) {
    return ENVIRONMENTS[HOSTNAMES[pathname]];
  }

  return 'devlocal';
};

Sentry.init({
  dsn: 'https://6e8e80541407c24935ac0f12aaa23bef@sentrelay.merlinx.eu/4505997151436800',
  release: isDev ? 'dev' : RELEASE,
  environment: getEnvironment(),
  sampleRate: 1.0,
  beforeSend: isDev ? (event, hint) => {
    setTimeout(() => console.error(hint?.originalException || hint?.syntheticException), 0);
    return event;
  } : undefined,
});
