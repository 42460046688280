import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

const ClearIndicator = (props) => {
  const {
    children = <FontAwesomeIcon size="1x" icon={faTimes}/>,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      {children}
    </div>
  );
};

export default ClearIndicator;
