import React, { CSSProperties } from 'react';
import { SpaceProps } from 'styled-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTriangleExclamation,
  faCircleExclamation,
  faCircleCheck,
  faCircleInfo,
} from '@fortawesome/pro-regular-svg-icons';

import { Styled } from './Alert.styles';

type SeverityEnum = 'error' | 'warning' | 'info' | 'success';
export type SizeEnum = 'small' | 'medium' | 'large';

type AlertProps = {
  children: string | React.ReactNode
  severity: SeverityEnum
  size: SizeEnum
  showBorder?: boolean
  showIcon?: boolean
  styles?: CSSProperties,
} & SpaceProps;

const severityIcons = {
  error: faCircleExclamation,
  warning: faTriangleExclamation,
  success: faCircleCheck,
  info: faCircleInfo,
};

const Alert = ({
  children,
  showIcon = true,
  showBorder = true,
  styles = {},
  ...props
}: AlertProps) => (
  <Styled.Alert
    style={styles}
    showBorder={showBorder}
    {...props}
  >
    {showIcon && (
    <Styled.Alert__Icon>
      <FontAwesomeIcon icon={severityIcons[props.severity]}/>
    </Styled.Alert__Icon>
    )}
    <Styled.Alert__Message>
      {children}
    </Styled.Alert__Message>
  </Styled.Alert>
);

export default Alert;
