import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { FavouritesContainer } from '@liveroom/containers';

import { LiveRoom } from './pages/LiveRoom';
import { IbeLink } from './pages/IbeLink';
import { NotFound } from './pages/NotFound';

const App = () => (
  <Routes>
    <Route
      path="/:language/:id"
      element={(
        <FavouritesContainer>
          <LiveRoom/>
        </FavouritesContainer>
      )}
    />
    <Route
      path="/:language/:affiliate/:id"
      element={(
        <FavouritesContainer>
          <IbeLink/>
        </FavouritesContainer>
      )}
    />
    <Route path="/:language/" element={<NotFound/>}/>
    <Route path="*" element={<Navigate to="/pl"/>}/>
  </Routes>
);

export default App;
