import React from 'react';
import { CommonProps, OptionTypeBase, GroupProps } from 'react-select';


const Group = (props: GroupProps<OptionTypeBase, true> & CommonProps<OptionTypeBase, true>
) => {
  const { getStyles, children } = props;

  const groupContentStyles = {
    display: 'grid',
    gridTemplateColumns: `${100 / props.selectProps.columns}% `.repeat(props.selectProps.columns),
  }

  return (
    <div style={getStyles('group', props)}>
      {!props.selectProps.searchActive && (
        <div style={getStyles('groupHeading', props)} id={props.headingProps.id}>
          {props.headingProps.data.label}
        </div>
      )}
      <div style={groupContentStyles}>
        {children}
      </div>
    </div>
  );
};

export default Group;
