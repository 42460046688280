import React from 'react';

import Tooltip from '@ess/ui/Tooltip';
import { Styled } from './SupplementaryHotel.styles';

type SupplementaryProps = {
  data: any
  width?: string
  height?: string
}

const defaultProps = {
  width: '120px',
  height: '70px',
};
const SupplementaryHotel = ({ data, width, height }: SupplementaryProps) => (
  <Styled.SupplementaryHotel width={width} height={height}>
    <Tooltip content={data.Name} isInitialMount>
      <Styled.SupplementaryHotel__Img
        alt={data.Name}
        src={data.ThumbUrl}
      />
    </Tooltip>
  </Styled.SupplementaryHotel>
);

SupplementaryHotel.defaultProps = defaultProps;

export {
  SupplementaryHotel,
};
