import React, { forwardRef, useCallback, useState } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';

type LazyTooltipProps = TippyProps & { isInitialMount: boolean }

const LazyTooltip = forwardRef<any, LazyTooltipProps>((props, ref) => {
  const [isMounted, setIsMounted] = useState(props.isInitialMount);
  const [renderContent, setRenderContent] = useState(false);
  const computedProps = { ...props };

  const lazyPlugin = {
    fn: () => ({
      onMount: () => setRenderContent(true),
      onHidden: () => setRenderContent(false),
    }),
  };

  const onMouseOver = useCallback(() => {
    setIsMounted(true);
  }, []);

  const childElement = React.cloneElement(computedProps.children as any, {
    onMouseOver,
  });

  computedProps.plugins = [lazyPlugin, ...(props.plugins || [])];

  if (props.render) {
    const render = props.render;
    computedProps.render = (...args) => (renderContent ? render(...args) : null);
  } else {
    computedProps.content = renderContent ? props.content : '';
  }

  if (props.visible === undefined) {
    if (!isMounted && !props.disabled) {
      return childElement;
    }
  }

  return <Tippy ref={ref} {...computedProps} />;
});

export default LazyTooltip;
