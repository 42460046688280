import { OFFER_RESERVATION_ENDPOINT, MERLINX_MASK_ENDPOINT } from '@ess/constants/api';

type Url = 'booking' | 'details' | 'mask';
type Endpoint = (searchType: string) => string;

const isCloud = window?.isCloudVersion;

const endpoints: Record<Url, Endpoint> = {
  booking: (searchType: string) => `${isCloud ? 'tourop/' : ''}${searchType}/${OFFER_RESERVATION_ENDPOINT}`,
  mask: (searchType: string) => `${isCloud ? 'tourop/' : ''}${searchType}/${MERLINX_MASK_ENDPOINT}`,
  details: (searchType: string) => `${searchType}/details/`,
};

const getOfferUrl = (step: Url, searchType: string, params?: string) => {
  const queryParams = params ? params.replace('?', '') : '';
  const baseUrl = `${window.location.origin}`;
  const endpoint = endpoints[step](searchType);

  return `${baseUrl}/${endpoint}${queryParams ? `?${queryParams}` : ''}`;
};

export {
  getOfferUrl,
};
