import React, { CSSProperties, forwardRef, memo } from 'react';
import {
  TypographyProps, ColorProps, SpaceProps, FontStyleProps, LayoutProps, TextStyleProps,
} from 'styled-system';

import { Styled } from './Text.styles';

type StyledSystemProps = ColorProps & SpaceProps & TypographyProps & LayoutProps & TextStyleProps;

type CustomTextStyles = {
  whiteSpace?: string,
  style?: StyledSystemProps
}
type TextProps = {
  children?: React.ReactNode | React.ReactElement,
  style?: CSSProperties
  id?: string
  as?: any,
} & StyledSystemProps & FontStyleProps & CustomTextStyles;

const defaultProps = {
  as: 'div',
  id: undefined,
  children: null,
  fontSize: 'body',
  lineHeight: 1,
  whiteSpace: 'normal',
  style: {},
};

const Text = forwardRef<HTMLDivElement, TextProps>(({
  children,
  id,
  ...props
}, ref) => (
  <Styled.Text
    ref={ref}
    {...{ ...id ? { id } : {} }}
    {...props}
  >
    {children}
  </Styled.Text>
));

Text.defaultProps = defaultProps;

export default memo(Text);
