import { CSSProperties, useContext } from 'react';
import {
  has, includes, isEmpty, isUndefined,
} from 'lodash-es';

import {
  IOfferBase,
  IOfferBaseAvailability,
} from '@ess/types';

import { CONFIRMED_TOUROPERATOR_OFFER } from '@ess/constants/status';

import { SearchBaseContext } from '@ess/context/SearchBaseContext';

import Tooltip from '@ess/ui/Tooltip';
import Chip, { SizeEnum, VariantEnum } from '@ess/ui/Chip';
import Text from '@ess/ui/Text';
import FlexBox from '@ess/ui/FlexBox';

import { CHECK_TYPE_FUNC } from '@tourop/components/OfferList/Offer/CheckTypeFunc';

import { Styled } from '../OfferAttributes.style';

type OfferAttributesTagsProps = {
  offerAttributes: any[];
  enableWrapping?: boolean
  isHighlighted?: boolean;
  maxVisibleAttributes?: number;
  size?: SizeEnum;
  exclude?: string[]
  include?: string[];
  style?: CSSProperties;
}

interface customOptionInterface {
  name?: string,
  label: string,
  visible: boolean
  tooltip?: any
  variant?: VariantEnum
}

const RestAttributesTooltip = ({ attributes }: { attributes: any[] }) => (
  <>
    {attributes.map((item: any) => (
      <FlexBox
        key={item.name}
        height={30}
        alignItems="center"
      >
        <Text key={item.name}>
          {item.label}
        </Text>
      </FlexBox>
    ))}
  </>
);

const isVisibleFunc = (statement: any, fieldNameString: string, validValue: any, conditions: any, enableAdditionalConditions: boolean | undefined) => {
  const isAvailAble = includes(conditions.limitedDisplayedList, fieldNameString);
  let booleanValue = false;
  if (enableAdditionalConditions) {
    if (isAvailAble) {
      conditions.limitedDisplayedListWithValue?.map((item: any) => {
        const value = item.split('/');
        if (value[0] === fieldNameString && value[1] === validValue) {
          booleanValue = statement;
        }
      });
    }
  } else {
    booleanValue = statement;
  }
  return booleanValue;
};

const getCandieNames = (attributes: any, searchBase: any) => {
  let parsedAttributes = [...attributes];
  const hasCandies = attributes?.find((item: any) => item.name.includes('candy-'));

  if (hasCandies) {
    try {
      const searchBaseCandies = searchBase['Base.Candy'].list.values;

      parsedAttributes = parsedAttributes.map((item) => (item.name.includes('candy-') ? ({
        ...item,
        label: searchBaseCandies[item.name.split('-')[1]],
      }) : item));
    } catch (error) {

    }
  }

  return parsedAttributes;
};

const createConditionsListFunc = (offerAttributesPriority: any, offerData: any) => {
  if (!isEmpty(offerAttributesPriority)) {
    const typeOfSearch = window.location.href.split('/')[3];
    const offerType = CHECK_TYPE_FUNC(offerData);
    const limitedDisplayedList: any = [];
    const limitedDisplayedListWithValue: any = [];
    if (offerType !== undefined && typeOfSearch !== undefined) {
      const additionalConditionsList = offerAttributesPriority[`${typeOfSearch}`]?.[`${offerType}`] ?? [];
      additionalConditionsList?.map((condition: any) => {
        Object.keys(condition).map((item: any) => {
          limitedDisplayedList.push(`${item}`);
          limitedDisplayedListWithValue.push(`${item}/${condition[item]}`);
        });
      });

      return {
        limitedDisplayedList,
        limitedDisplayedListWithValue,
      };
    }
  }
  return {};
};

export const OfferAttributesTagsFunc = (t: any, offerData: any, offerAttributesPriority?: any, enableAdditionalConditions?: boolean, customOption?: customOptionInterface | null) => {
  if (!isEmpty(offerData)) {
    const { Base, Transport, Accommodation } = offerData;

    const flightsOptions = ['flightPackage', 'flightOnly'];

    const isFlight = includes(flightsOptions, CHECK_TYPE_FUNC(offerData));

    const { Availability, Candy } = Base as IOfferBase;

    const { extended = undefined } = Availability || {} as IOfferBaseAvailability;

    const conditions: any = createConditionsListFunc(offerAttributesPriority, offerData);

    const promoTextsMap: any = {
      LAST: t('lbl_last_minute'),
      FIRS: t('lbl_first_minute'),
      PROM: t('lbl_promotion'),
      HTYG: t('lbl_week_hit'),
    };

    const offerAttributes: any = [
      {
        name: 'transfer_not_included',
        label: t('lbl_transfer_not_included'),
        visible: !isEmpty(Base?.Transfer)
          && isVisibleFunc(!isEmpty(Base?.Transfer) && Base?.Transfer === 'no', 'Base.Transfer', 'no', conditions, enableAdditionalConditions),
      },
      {
        name: 'flex',
        label: 'fleX',
        visible: isVisibleFunc(!isEmpty(Base?.Refundable) && Base?.Refundable === 'flex', 'Base.Refundable', 'flex', conditions, enableAdditionalConditions),
      },
      {
        name: 'private',
        label: t('lbl_private'),
        visible: isVisibleFunc(Base?.DatasetInfo?.private, 'Base.DatasetInfo.private', '', conditions, enableAdditionalConditions),
      },
      {
        name: 'resident',
        label: t(`lbl_resident_${Base?.Resident}`),
        visible: isVisibleFunc(includes(['local', 'remote', 'no'], Base?.Resident), 'Base.Resident', ['local', 'remote', 'no'], conditions, enableAdditionalConditions),
      },
      {
        name: 'transfer',
        label: t('lbl_transfer_included'),
        visible: isVisibleFunc(!isEmpty(Base?.Transfer) && Base?.Transfer === 'yes', 'Base.Transfer', 'yes', conditions, enableAdditionalConditions),
      },
      {
        name: 'luggage',
        label: t('lbl_luggage_included'),
        visible: isVisibleFunc(isFlight && !isEmpty(Transport?.Flight?.Luggage) && Transport?.Flight?.Luggage === 'yes', 'Transport.Flight.Luggage', 'yes', conditions, enableAdditionalConditions),
      },
      {
        name: 'flight',
        label: t('lbl_direct_flight'),
        visible: isVisibleFunc(isFlight && !isUndefined(Transport?.Flight?.Stops) && Transport.Flight.Stops === 'no', 'Transport.Flight.Stops', 'no', conditions, enableAdditionalConditions),
      },
      {
        name: 'promotion',
        label: Base?.Promotion ? promoTextsMap[Base?.Promotion] : '',
        visible: isVisibleFunc(
          Base?.Promotion !== undefined && !isEmpty(Base?.Promotion) && has(promoTextsMap, Base?.Promotion),
          'Base.Promotion', promoTextsMap, conditions, enableAdditionalConditions),
      },
      {
        name: 'combined-offer-77',
        label: '2w1',
        visible: isVisibleFunc(Accommodation?.Type?.Id === '7+7', 'Accommodation.Type.Id', '7+7', conditions, enableAdditionalConditions),
        tooltip: `${t('lbl_zw_offer')}`,
      },
      {
        name: 'combined-offer-zw',
        label: 'Z+W',
        visible: isVisibleFunc(Accommodation?.Type?.Id === 'Z+W', 'Accommodation.Type.Id', 'Z+W', conditions, enableAdditionalConditions),
        tooltip: `${t('lbl_zw_offer')}`,
      },
      {
        name: 'confirmed-offer',
        label: t('lbl_confirmed'),
        visible: isVisibleFunc(includes(extended, CONFIRMED_TOUROPERATOR_OFFER), 'confirmed-offer', extended, conditions, enableAdditionalConditions),
        variant: 'border' as VariantEnum,
      },
    ];

    if (Candy?.Types?.length) {
      let allowedCandies: any[] = [];

      conditions?.limitedDisplayedListWithValue?.map((item: any) => {
        const name = item.split('/')[0];
        const value = item.split('/')[1];
        if (name === 'Base.Candy') {
          allowedCandies = value.split('-');
        }
      });

      Candy.Types.map((item) => {
        offerAttributes.push(
          {
            name: `candy-${item.Id}`,
            label: item.Name,
            visible: enableAdditionalConditions ? includes(allowedCandies, `${item.Id}`) : true,
          },
        );
      });
    }

    if (!isEmpty(customOption)) {
      offerAttributes.unshift(customOption);
    }

    return offerAttributes.filter((attribute: any) => attribute.visible);
  }
  return [];
};

const OfferAttributesTags = ({
  enableWrapping = false,
  offerAttributes,
  isHighlighted = false,
  size = 'small' as SizeEnum,
  maxVisibleAttributes = undefined,
  exclude = [],
  include = [],
  style = {},
}: OfferAttributesTagsProps) => {
  const { state: SBContext = undefined } = useContext(SearchBaseContext) ?? {};

  let attributes = offerAttributes.filter((attribute) => attribute.visible);

  attributes = getCandieNames(attributes, SBContext ?? {});

  if (exclude?.length) {
    attributes = attributes.filter((attribute) => !includes(exclude, attribute.name));
  } else if (include?.length) {
    attributes = attributes.filter((attribute) => includes(include, attribute.name));
  }

  let visibleAttributes = attributes;
  let hidden: any[] = [];

  if (maxVisibleAttributes) {
    visibleAttributes = attributes.slice(0, maxVisibleAttributes);
    hidden = attributes.slice(maxVisibleAttributes);
  }

  return attributes.length ? (
    <Styled.OfferAttributes
      enableWrapping={enableWrapping}
      style={style}
    >
      {visibleAttributes.map((attribute) => {
        const element = (
          <Chip
            key={attribute.name}
            variant={isHighlighted ? 'border' : attribute?.variant ? attribute.variant as VariantEnum : 'border'}
            label={attribute.label}
            size={size}
          />
        );

        if (attribute?.tooltip) {
          return (
            <Tooltip
              key={attribute.name}
              theme="bubble-wide"
              content={attribute.tooltip}
              allowHTML
            >
              {element}
            </Tooltip>
          );
        }
        return element;
      })}
      {hidden.length > 0 && (
        <Tooltip content={<RestAttributesTooltip attributes={hidden}/>} theme="bubble-wide" allowHTML>
          <Chip
            variant={isHighlighted ? 'highlighted' : 'border'}
            label={`+${hidden.length}`}
            size={size}
          />
        </Tooltip>
      )}
    </Styled.OfferAttributes>
  ) : null;
};

export default OfferAttributesTags;
