import React from 'react';

type LayeredIconsProps = {
  children: React.ReactNode
}

const LayeredIcons = ({ children }: LayeredIconsProps) => (
  <span className="fa-layers fa-fw fa-2x">
    {children}
  </span>
);

export {
  LayeredIcons,
};
