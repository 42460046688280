import React, { useEffect, useState, memo } from 'react';
import { includes, cloneDeep } from 'lodash-es';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faSolidStar } from '@fortawesome/pro-solid-svg-icons';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { OptionTypeBase } from 'react-select';

import useBreakpoint from '@ess/hooks/useBreakpoint';
import useWindowSize from '@ess/hooks/useWindowSize';

import Text from '@ess/ui/Text';
import FlexBox from '@ess/ui/FlexBox';
import Checkbox from '@ess/ui/Form/Checkbox';
import { Col, Row } from '@ess/ui/FlexGrid';

import { FontAwesomeIconOnHover, FlexboxWithHover } from '../AdvancedSelect.styles';
import { TOptions } from '../AdvancedSelect';

type MenuGridSectionProps = {
  name: string
  groupedOptions: any
  checkedOptions: string[]
  maxModalContentHeight: number
  searchActive?: boolean,
  hasFavorites?: boolean,
  onFavoritesClick: (option: OptionTypeBase, event: React.MouseEvent) => void,
  columns: number
  rows: {
    xxs: number,
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number,
  },
  gapX: number
  gapY: number
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
  theme?: string
  favorites?: TOptions
}

const defaultProps = {
  theme: 'default',
  searchActive: false,
  hasFavorites: true,
  favorites: [],
};

const MenuGridSection = ({
  name,
  groupedOptions,
  columns,
  rows,
  gapX,
  gapY,
  changeHandler,
  checkedOptions,
  maxModalContentHeight,
  searchActive,
  hasFavorites,
  onFavoritesClick,
  theme,
  favorites = [],
}: MenuGridSectionProps) => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const [listHeight, setListHeight] = useState<number>(0);

  const inFavorites = (option: OptionTypeBase) => Boolean(
    hasFavorites && favorites.filter((item: OptionTypeBase) => item.value === option.value).length,
  );

  const getColumn = (searchActive: boolean, rows: any, length: number) => {
    const cols = cloneDeep(rows);

    if ((!searchActive) || length > 35) {
      return cols;
    }

    cols.lg = cols.xl = cols.md = (Math.floor(length / 8) + 1);
    return cols;
  };

  const { height } = useWindowSize();

  const getItems = (items: any) => {
    let columnInfo = cloneDeep(rows);

    if (name === 'Base.Operator') {
      columnInfo = getColumn(!!searchActive, rows, groupedOptions?.length ?? 1);
    }

    return (
      <Row gapX={gapX} gapY={gapY} flexWrap="wrap">
        {items?.map((item: OptionTypeBase) => {
          const itemInFavorites = inFavorites(item);
          return (
            <Col
              key={item.value}
              width={{
                xxs: (columns / rows.xs) / columns,
                xs: (columns / rows.xs) / columns,
                sm: (columns / rows.sm) / columns,
                md: (columns / columnInfo.md) / columns,
                lg: (columns / columnInfo.lg) / columns,
                xl: (columns / columnInfo.xl) / columns,
              }}
            >
              <FlexboxWithHover>
                {hasFavorites && (
                  <FlexBox
                    as="span"
                    onClick={(e) => onFavoritesClick && onFavoritesClick(item, e)}
                    pr="5px"
                    width="20px"
                    flexShrink="0"
                    style={{ cursor: 'pointer' }}
                  >
                    {isMobile || itemInFavorites
                      ? <FontAwesomeIcon icon={itemInFavorites ? faSolidStar : faStar}/>
                      : (
                        <FontAwesomeIconOnHover
                          onClick={(e: React.MouseEvent) => onFavoritesClick && onFavoritesClick(item, e)}
                          icon={faStar}
                        />
                      )}
                  </FlexBox>
                )}
                <FlexBox
                  width="calc(100% - 32px)"
                  flexShrink="0"
                  flexGrow="0"
                >
                  <Checkbox
                    spacing="10px"
                    onChange={changeHandler}
                    checked={includes(checkedOptions, item.value)}
                    name={item.value}
                    label={item.label}
                    style={{ width: '100%' }}
                    disabled={item.disabled}
                  />
                </FlexBox>
              </FlexboxWithHover>
            </Col>
          );
        })}
      </Row>
    );
  };

  useEffect(() => {
    let height = 0;
    groupedOptions.forEach((group: any) => {
      if (group.options && group.options.length > 0) {
        const headerHeight = 38;

        const itemsRowsCount = group.options.length / rows[breakpoint];

        height += headerHeight + (Math.ceil(itemsRowsCount) * 29) + 7.8;
      }
    });
    setListHeight((height > maxModalContentHeight ? maxModalContentHeight : height));
  }, [maxModalContentHeight, breakpoint, rows]);

  const hasGroupedOptions = groupedOptions[0]?.options?.length;

  return groupedOptions.length ? (
    <>
      {!hasGroupedOptions ? (
        <FlexBox flexDirection="column">
          <FlexBox width="100%" p="small">
            {getItems(groupedOptions)}
          </FlexBox>
        </FlexBox>
      ) : groupedOptions.map((group: any) => (
        <FlexBox flexDirection="column">
          {favorites.length > 0 && group?.label && (
          <FlexBox
            bg={theme === 'default' ? 'lighterGray' : ''}
            p="small"
            height="38px"
          >
            <Text fontWeight="bold">{group.label}</Text>
          </FlexBox>
          )}
          <FlexBox width="100%" p="small">
            {getItems(group.options)}
          </FlexBox>
        </FlexBox>
      ),
      )}
    </>
  ) : (
    <FlexBox
      width="100%"
      height={isMobileOnly ? height - 150 : listHeight - 20}
      alignItems="center"
      justifyContent="center"
      p="10px"
    >
      <span>{t('no_results')}</span>
    </FlexBox>
  );
};

MenuGridSection.defaultProps = defaultProps;

export default memo(MenuGridSection);
