import axios from 'axios';
import * as Sentry from '@sentry/react';

import { BASKET_SERVICE_URL } from '@liveroom/constants';
import { getCurrentLanguage } from '@ess/utils';
import { promiseRequest } from '@ess/v5-data-provider/request';

interface BasketServiceConfig {
  autoRefreshEnabled: false,
}

class BasketService {
  config: BasketServiceConfig;

  constructor(config: BasketServiceConfig) {
    this.config = config;
  }

  async fetchShortLinks(data: any, host: string) {
    const { items = [], id = null } = data ?? {};

    try {
      const rowId = items?.map((item: any) => item.rowid);

      const postData = {
        request: {
          method: 'shortlink',
          level: 'item',
          conditions: {
            agencyShare: 1,
            basketId: id,
            currency: 'PLN',
            language: getCurrentLanguage(),
            rowId,
            affiliate: host,
          },
        },
      };

      return promiseRequest(`${BASKET_SERVICE_URL}/Public/shortlink/`, postData, 2);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * Makes api call to retrieve offers.
   */
  async fetchOffers(params: any, host: string) {
    let response = null;

    try {
      const request = await axios.get(`${BASKET_SERVICE_URL}/Public/Details/${params?.id}`);
      const { status = undefined, details = undefined } = request?.data ?? {};
      const shortLinksRequest = await this.fetchShortLinks(details ?? {}, host);

      response = {
        ...request?.data?.details,
        items: request?.data?.details?.items?.map((item: any, index: number) => {
          const { details } = shortLinksRequest;
          const shortLink = details?.items?.find((link: any) => link.rowid === item.rowid) ?? undefined;

          return {
            ...item,
            extra: {
              ...item.extra,
              shortLink: shortLink ? shortLink.shortLinkUrl : '',
            },
          };
        }),
      };

      if (status === 'ERROR') {
        throw new Error(request?.data?.error);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }

    return response;
  }
}

export {
  BasketService,
};
