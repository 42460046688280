import { useShallow } from 'zustand/react/shallow';

import { Store } from '../store';

import { useAgentSettingsStore } from './useAgentSettingsStore';

const useAgentSettings = <T>(selector: (state: Store) => T) => useAgentSettingsStore(useShallow(selector));

export {
  useAgentSettings,
};
