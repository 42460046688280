import moment from 'moment/moment';

import { IOfferBaseAvailability } from '@ess/types';

export const getBookingModeDate = (offerAvailability?: IOfferBaseAvailability, mode: 'option' | 'quota' = 'option') => {
  const item = offerAvailability?.[mode];

  if (item?.possible && item?.date) {
    return moment(item.date).utc().format('DD.MM.YYYY HH:mm');
  }

  return false;
};
