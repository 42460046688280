export const boxShadow: any = [
  'rgb(0 0 0 / 15%) 0 2px 4px 0px',
  'rgb(0 0 0 / 15%) 0 4px 8px 2px',
  'rgb(0 0 0 / 15%) 0 8px 10px 4px',
  'rgb(0 0 0 / 15%) 0 10px 12px 8px',
];

export default {
  boxShadow,
};
