import { CSSProperties } from 'react';
import { get } from 'lodash-es';

import { IDictionary } from '@ess/types';

import { BasketPosition } from './types';

export const BASKET_ID = 'Basket-Drawer';

export const BASKET_API_URL = get(window, 'GlobalVariables.basketGoUrl', 'https://merlinx-cloud.com/basket');

export const BASKET_EXTERNAL_URL = window?.isCloudVersion ? '/tourop/basket/' : '/basket/';

export const BASKETS_LIST = {
  AgencyShare: 1,
  Count: 15,
  Offset: 0,
};

export const TRANSLATION_KEYS: any = {
  swap: {
    success: 'items_sort_success',
    fail: 'items_sort_fail',
  },
  addBasket: {
    success: 'new_basket_added',
    fail: 'new_basket_added_fail',
  },
  changeItemName: {
    success: 'lbl_change_item_name_success',
    fail: 'lbl_change_item_name_fail',
  },
  changeBasketName: {
    success: 'change_basket_name_success',
    fail: 'change_basket_name_fail',
  },
  addItem: {
    success: 'basket_offer_added',
    fail: 'basket_offer_add_fail',
  },
  deleteItem: {
    success: 'basket_offer_deleted',
    fail: 'basket_offer_delete_fail',
  },
  deleteBasket: {
    success: 'lbl_basket_deleted',
    fail: 'lbl_basket_delete_fail',
  },
  blockBasket: {
    success: 'lbl_basket_blocked_message',
    fail: 'lbl_basket_block_fail',
  },
  unblockBasket: {
    success: 'lbl_basket_is_unblocked',
    fail: 'lbl_basket_unblock_fail',
  },
  publishBasket: {
    success: 'lbl_basket_publish_success',
    fail: 'lbl_basket_publish_fail',
  },
  unPublishBasket: {
    success: 'lbl_basket_unpublish_success',
    fail: 'lbl_basket_unpublish_fail',
  },
  ibeLinkStaticMessage: {
    success: 'lbl_ibe_link_static_success',
    fail: 'lbl_ibe_link_static_fail',
  },
  liveRoom: {
    success: 'lbl_liveroom_success',
    fail: 'lbl_liveroom_fail',
  },
  cloneOffer: {
    success: 'lbl_clone_offer_success',
    fail: 'lbl_clone_offer_fail',
  },
  /* WCS errors */
  BASKET_NOT_EXISTS_ERROR: 'lbl_basket_not_exists_error',
  BASKET_BLOCKED_ERROR: 'lbl_basket_blocked_error',
  BASKET_CAPACITY_MAX_ERROR: 'lbl_basket_capacity_max_error',
  NAME_LENGTH_TOO_LONG_ERROR: 'lbl_basket_name_too_long_error',
};

export const BASKET_DROPPABLE_AREA: IDictionary<CSSProperties> = {
  [BasketPosition.right]: {
    position: 'fixed',
    right: 10,
    top: 99,
    height: 'calc(100% - 109px)',
    width: 'calc((100vw - 1349px - 80px) / 2)',
    minWidth: '150px',
  },
  [BasketPosition.left]: {
    position: 'fixed',
    left: 10,
    top: 99,
    height: 'calc(100% - 109px)',
    width: 'calc((100vw - 1349px - 80px) / 2)',
    minWidth: '150px',
  },
  [BasketPosition.top]: {
    position: 'fixed',
    top: 10,
    left: 'calc((100vw - 1349px - 40px ) / 2)',
    width: 'calc(100vw - (100vw - 1349px - 40px))',
    height: '150px',
  },
  [BasketPosition.bottom]: {
    position: 'fixed',
    bottom: 10,
    left: 'calc((100vw - 1349px - 40px ) / 2)',
    width: 'calc(100vw - (100vw - 1349px - 40px))',
    height: '150px',
  },
};

export const STATUS_MAP: IDictionary<string> = {
  available: 'OK',
  onrequest: 'RQ',
  notavailable: 'XX',
  unknown: '?',
};

export const ALLOWED_STATUS_UPDATE = ['OK', 'RQ', 'XX', 'OP'];

export const CSV_EXPORT_OPTIONS = {
  fieldSeparator: ';',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
};

export const BASKET_ICON_CLASS = 'js-basket-react-icon';

export const BASKET_STORAGE_SECTION = 'BasketReact';

const YOUTUBE_URL = 'https://www.youtube.com/watch?v=';
const VIMEO_URL = 'https://vimeo.com/';
export const VIDE_SERVICE_URL: IDictionary<string> = {
  vimeo: VIMEO_URL,
  youtube: YOUTUBE_URL,
};
