import { useContext, useRef } from 'react';
import { useMatch } from 'react-router-dom';
import { find } from 'lodash-es';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';

import { SearchTypesEnum } from '@ess/types';

import { fetchConfig } from '@tourop/app/appInit';

import { useNavigateWithParams } from '@ess/hooks/useNavigateWithParams';

import { AppConfigContext } from '@ess/context/AppConfigContext';
import { SearchBaseContext } from '@ess/context/SearchBaseContext';

import { PERSISTED_CONFIG_ITEM } from '@ess/constants/api';

const singleOperator = Cookies.get('login_start_config')?.toUpperCase();

interface ISetSearchTypeConfig {
  searchFormConfigModifier?: (config: any) => any
}

/**
 * useSearchType hook.
 * Manages search type.
 */
const useSearchType = () => {
  const navigate = useNavigateWithParams();
  const currentType = useRef<string>();
  const isSearchPage = useMatch('/:searchType/search/');
  const { dispatch: SBDispatch } = useContext(SearchBaseContext);
  const { state: SFContext, dispatch: SFDispatch } = useContext(AppConfigContext);
  const { searchFormList, type, configOper } = SFContext;

  currentType.current = type;

  /**
   * Set new search type.
   * @param newSearchType
   * @param config
   */
  const set = async (newSearchType: string, config?: ISetSearchTypeConfig) => {
    const path = `/${newSearchType}/${isSearchPage ? 'search' : 'results'}/`;
    const searchType = find(searchFormList, (item) => item.value === newSearchType);
    const { url = '', oldForm = false } = searchType ?? {};

    if (currentType.current && currentType.current === newSearchType) {
      return;
    }

    if (oldForm) {
      window.location.href = url;
      return;
    }

    setTimeout(() => {
      SFDispatch({ type: 'FETCH_SEARCH_FORM_CONFIG' });
      SFDispatch({
        type: 'SET_SEARCH_TYPE',
        payload: newSearchType,
      });
    }, 0);

    navigate(path, { include: ['sf'] });

    const searchBaseParams = {
      Operators: singleOperator ? [singleOperator] : [],
      Transport: [],
      Accommodation: [],
    };

    let data = null;

    try {
      fetchConfig(newSearchType as SearchTypesEnum, searchBaseParams).then((response: any) => {
        data = response;
        const {
          searchForm,
          config: AppConfig,
          searchBase,
        } = data;

        setTimeout(() => {
          if (AppConfig?.configOper) {
            SFDispatch({
              type: 'UPDATE_APP_CONFIG',
              payload: {
                configOper: {
                  ...configOper,
                  ...AppConfig?.configOper,
                },
              },
            });
          }

          SBDispatch({
            type: 'UPDATE_SEARCH_BASE',
            payload: searchBase,
          });

          SFDispatch({
            type: 'UPDATE_SEARCH_FORM_CONFIG',
            payload: config?.searchFormConfigModifier
              ? config?.searchFormConfigModifier(searchForm)
              : searchForm,
          });
          sessionStorage.setItem(PERSISTED_CONFIG_ITEM, JSON.stringify(AppConfig));
        }, 0);
      });
    } catch (error) {
      Sentry.captureException(error);
    }

    // eslint-disable-next-line consistent-return
    return data;
  };

  return {
    type,
    set,
  };
};

export default useSearchType;
