import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showHotkeysDrawer: false,
  showSettingsDrawer: false,
  isMapSearchDisabled: false,
  showMap: false,
};

export const base = createSlice({
  name: 'base',
  initialState,
  reducers: {
    showHotkeysDrawer: (state, action) => ({
      ...state,
      showHotkeysDrawer: action.payload,
    }),
    showSettingsDrawer: (state, action) => ({
      ...state,
      showSettingsDrawer: action.payload,
    }),
    setMapSearchDisabled: (state, action) => ({
      ...state,
      isMapSearchDisabled: action.payload,
    }),
    showMap: (state, action) => ({
      ...state,
      showMap: action.payload,
    }),
    toggleMap: (state) => ({
      ...state,
      showMap: !state.showMap,
    }),
  },
});

export const {
  showHotkeysDrawer,
  showSettingsDrawer,
  setMapSearchDisabled,
  showMap,
  toggleMap
} = base.actions;
export default base.reducer;
