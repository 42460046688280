import { includes, indexOf, partition } from 'lodash-es';

type ExtraHotelData = {
  id: string
  code: string
}

type OfferExtraKey = {
  offerId: string
  key: string
}

/**
 * Returns key & base offer id from combined offer id.
 * @param id
 */
export const getOfferExtraKey = (id: string): OfferExtraKey => {
  const elementsArray = id.split('|');
  const [offerId, key] = partition(elementsArray, (item: string) => {
    const index = indexOf(elementsArray, item);
    return index < 5;
  });

  return {
    offerId: offerId.join('|'),
    key: key.join('|'),
  };
};

/**
 * Returns hotelCode & hotelId from given key.
 * @param key
 */
export const getHotelExtra = (key: string | undefined): ExtraHotelData => {
  let data: ExtraHotelData = {
    id: '',
    code: '',
  };

  if (key) {
    const parsedKey = includes(key, '|') ? key.split('|')[0] : key;
    const [id, code] = parsedKey.split('_');

    data = {
      id,
      code,
    };
  }

  return data;
};
