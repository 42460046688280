import styled from 'styled-components';

const HotelStars = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    height: 15px;
`;

export const Styled = {
  HotelStars,
};
