import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash-es';
import moment from 'moment';

import { DATE_DISPLAY_FORMAT, DATE_REQUEST_FORMAT } from '@ess/constants/api';

import useBasket, { BASKET_ACTION_ADD, BASKET_ACTION_DELETE } from '@ess/hooks/useBasket';
import { useReactBasket } from '@basket/hooks/useReactBasket';

import Tooltip from '@ess/ui/Tooltip';
import FlexBox from '@ess/ui/FlexBox';
import { Button } from '@ess/ui/Button';
import Text from '@ess/ui/Text';

import { OfferUrl, useOfferPage } from '@ess/hooks/useOfferPage';

type TooltipProps = {
  children: React.ReactElement
  isSelected: boolean
  offerData: any
  visible: boolean
  appendTo?: any
}

type TooltipContentProps = {
  offerData: any
  isSelected: boolean
}

const TooltipContent = ({ offerData, isSelected }: TooltipContentProps) => {
  const { t } = useTranslation();
  const { goToOfferPage } = useOfferPage();

  const {
    basketRequest,
    isInBasket,
    isLoading: basketIsLoading,
  } = useBasket();

  const {
    isOfferInBasket,
  } = useReactBasket();

  const isOfferOInBasket = () => {
    if (window?.GlobalVariables?.isBasketGoOn) {
      return isOfferInBasket(offerData);
    }
    return isInBasket(offerData);
  };

  const goToDetails = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    goToOfferPage({ page: OfferUrl.Details, offerData });
  };

  const addToBasket = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    if (!isOfferOInBasket()) {
      basketRequest({
        actionType: BASKET_ACTION_ADD,
        offer: offerData,
      });
    } else {
      basketRequest({
        actionType: BASKET_ACTION_DELETE,
        offer: offerData,
      });
    }
  };

  return (
    <FlexBox p="small" width="250px" flexDirection="column">
      <FlexBox mb="medium" flexDirection="column">
        <FlexBox mb="small" justifyContent="space-between">
          <Text fontWeight="bold">{`${t('lbl_filters_startdate')}:`}</Text>
          <FlexBox>
            <Text>{moment(offerData?.Base?.StartDate, DATE_REQUEST_FORMAT).format(DATE_DISPLAY_FORMAT)}</Text>
          </FlexBox>
        </FlexBox>
        <FlexBox justifyContent="space-between">
          <Text fontWeight="bold">{`${t('lbl_price')}:`}</Text>
          <FlexBox>
            <Text mr="tiny">{Math.round(toNumber(offerData?.Base?.Price?.FirstPerson?.amount ?? 0))}</Text>
            <Text>{offerData?.Base?.Price?.FirstPerson?.currency}</Text>
          </FlexBox>
        </FlexBox>

      </FlexBox>
      {!isSelected && (
        <Button
          mb="small"
          label={t('offer_details')}
          onClick={goToDetails}
        />
      )}
      <Button
        isLoading={basketIsLoading}
        label={t(isOfferOInBasket() ? 'delete_from_basket' : 'add_to_basket')}
        onClick={addToBasket}
      />
    </FlexBox>
  );
};

const TooltipContainer = forwardRef<any, TooltipProps>(({
  children,
  offerData,
  isSelected,
  visible,
  appendTo = undefined,
}, ref) => (
  <Tooltip
    ref={ref}
    showOnMobile
    appendTo={appendTo}
    contentPadding={0}
    visible={visible}
    trigger="none"
    placement="bottom"
    content={<TooltipContent offerData={offerData} isSelected={isSelected}/>}
    theme="bubble-wide"
    zIndex={998}
  >
    {children}
  </Tooltip>
));

export {
  TooltipContainer,
};
