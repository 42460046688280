import { Styled } from './Loader.styles';

type DotsLoaderProps = {
  color: string;
  size: string;
}

const DotsLoader = ({ color, size, ...props }: DotsLoaderProps) => (
  <Styled.DotsLoader size={size} color={color} {...props}>
    <div />
    <div />
    <div />
  </Styled.DotsLoader>
);

export {
  DotsLoader,
};
