import { IDictionary } from '@ess/types';
import { isFunction } from 'lodash-es';

/**
 * Returns fields list, by given viewListType.
 * Usage: API - search request.
 */

type FilterListArg = boolean | ((item: string) => boolean)

export const getAllFieldsList = ():any => ({
  skiRegionList: {
    fieldList: [
      'Base.Price',
      'Accommodation.Location',
      'Accommodation.ExtMonthlyWeather',
    ],
  },
  regionList: {
    fieldList: [
      'Base.Price',
      'Base.EGuide',
      'Accommodation.Location',
      'Accommodation.ExtMonthlyWeather',
    ],
  },
  fieldValues: {
    fieldList: [
      'Base.StartDate',
      'Base.NightsBeforeReturn',
      'Base.XCity',
      'Base.DepartureLocation',
      'Accommodation.Room',
      'Accommodation.Category',
      'Base.Catalog',
      'Base.Operator',
      'Accommodation.XService',
      'Accommodation.XCode',
      'Accommodation.Attributes',
      'Accommodation.BuildRenovationYear',
    ],
  },
  unfilteredFieldValues: {
    fieldList: [
      'Base.StartDate',
      'Base.NightsBeforeReturn',
      'Base.XCity',
      'Base.DepartureLocation',
      'Accommodation.Room',
      'Accommodation.Category',
      'Base.Catalog',
      'Base.Operator',
      'Accommodation.XService',
      'Accommodation.XCode',
      'Accommodation.BuildRenovationYear',
    ],
  },
  groupedList: {
    limit: 60,
    fieldList: [
      'Transport.*',
      'Base.UniqueObjectId',
      'Base.Operator',
      'Base.OperatorDesc',
      'Base.XCountry',
      'Base.XRegion',
      'Base.XCity',
      'Base.XCode',
      'Base.Omnibus',
      'Base.Price',
      'Base.ThumbUrl',
      'Base.Promotion',
      'Base.Transfer',
      'Accommodation.Category',
      'Accommodation.Rating',
      'Accommodation.XCode',
      'Accommodation.Camp',
      'Accommodation.Attributes',
      'Accommodation.DistanceToBeach',
      'Accommodation.DistanceToSlope',
      'Accommodation.ExtTripAdvisor',
      'Accommodation.ExtAgentAttributes',
      'Accommodation.Location',
      'Accommodation.DistanceToCityCenter',
      'Accommodation.DistanceToAirport',
      'Accommodation.BuildRenovationYear',
      'Accommodation.Room',
      'Accommodation.Service',
    ],
  },
  offerList: {
    limit: 30,
    fieldList: [
      'Base.OriginalPrice',
      'Base.DatasetInfo',
      'Transport.*',
      'Base.Availability',
      'Base.ThumbUrl',
      'Base.DepartureLocation',
      'Base.DestinationLocation',
      'Base.UniqueObjectId',
      'Base.OfferId',
      'Base.Operator',
      'Base.OperatorDesc',
      'Base.StartDate',
      'Base.ReturnDate',
      'Base.Duration',
      'Base.NightsBeforeReturn',
      'Base.XCountry',
      'Base.XRegion',
      'Base.XCity',
      'Base.XCode',
      'Base.Catalog',
      'Base.Omnibus',
      'Base.Price',
      'Base.Transfer',
      'Base.Candy',
      'Base.Promotion',
      'Base.Refundable',
      'Base.Resident',
      'Accommodation.Nights',
      'Accommodation.Category',
      'Accommodation.Room',
      'Accommodation.Service',
      'Accommodation.XService',
      'Accommodation.Rating',
      'Accommodation.Attributes',
      'Accommodation.Type',
      'Accommodation.DistanceToBeach',
      'Accommodation.DistanceToSlope',
      'Accommodation.ExtTripAdvisor',
      'Accommodation.ExtAgentAttributes',
      'Accommodation.BuildRenovationYear',
    ],
  },
  accommodationSupplementary: {
    limit: 100,
    fieldList: [
      'Base.Availability',
      'Base.Price',
      'Base.ThumbUrl',
      'Base.XCode',
      'Base.OfferId',
      'Base.Refundable',
      'Base.Transfer',
      'Base.UniqueObjectId',
      'Base.Operator',
      'Accommodation.Category',
      'Accommodation.Rating',
      'Accommodation.XCode',
      'Accommodation.Code',
      'Accommodation.Nights',
      'Accommodation.Name',
      'Accommodation.ExtTripAdvisor',
      'Accommodation.DistanceToCityCenter',
      'Accommodation.DistanceToAirport',
      'Accommodation.Room',
      'Accommodation.Service',
      'Accommodation.Supplementary',
    ],
  },
  offerDetails: {
    limit: 1,
    fieldList: [
    ],
  },
});

export const getFieldsList = (values: IDictionary<string>, viewListType: string, filterList: FilterListArg = false) => {
  const viewList = getAllFieldsList()[viewListType];

  if (isFunction(filterList) && viewList) {
    viewList.fieldList = viewList.fieldList.filter((field: string) => filterList(field));
  }

  return {
    [viewListType]: viewList,
  };
};
