import { TypedUseSelectorHook, useSelector as nativeSelector, useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';


const createStore = (preloadedState: any = {}, isDev = false) => configureStore({
  reducer: rootReducer,
  preloadedState,
  devTools: isDev,
});

const store = createStore();

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default createStore;

export const useSelector: TypedUseSelectorHook<RootState> = nativeSelector;

export const useAppDispatch: () => AppDispatch = useDispatch;
