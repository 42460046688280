export const NO_PRINT_CLASS = 'no-print';
export const PRINT_ONLY = 'print-only';

export const COMMON_PAGE_STYLES = `
    body {
     -webkit-print-color-adjust: exact;
     print-color-adjust: exact;
     width: 1200px;
    }

    html {
      box-sizing: border-box;
    }

    *, *:before, *:after {
      box-sizing: inherit;
    }

    .${PRINT_ONLY} {
       display: none !important;
    }

    @media print {
      @page {
        margin: 0;
        margin-top: 10mm;
        margin-bottom: 10mm;
      }

      body {
        padding: 5mm 10mm;
        max-width: 1200px;
      }

      #details-sections-wrapper [class^="SectionBox-sc"] {
        page-break-inside: avoid !important;
      }

      img,
      div[class^="FlexBox-sc"],
      div[class=^="Row-sc"],
      div[class=^="Col-sc"] {
        page-break-inside: avoid !important;
      }

      img {
         -webkit-print-color-adjust: exact;
      }

      .${NO_PRINT_CLASS} {
        display: none !important;
      }

      .${PRINT_ONLY} {
        display: block !important;
      }
    }
`;
