import { CSSProperties } from 'react';

export const CLOUD_ADDRESS = 'https://merlinx-cloud.com';

export const CONTENT_SERVICE_URL = `${CLOUD_ADDRESS}/wcs/Public/`;
export const BASKET_SERVICE_URL = `${CLOUD_ADDRESS}/basket`;
export const REFRESH_INTERVAL = 2000;

export const ERROR_CODES = {
  NotFound: 'NotFound',
};

export const CARD_STYLES: CSSProperties = {
  minWidth: '300px',
  maxWidth: '100%',
  width: '100%',
  height: '520px',
};
