import { RefObject } from 'react';
import useCustomEventListener from './useCustomEventListener';

/**
 * useEventListener hook.
 * Creates event listener.
 * @param eventName
 * @param handler
 * @param element
 */
const useEventListener = <T extends HTMLElement = HTMLDivElement>(
  // eslint-disable-next-line no-undef
  eventName: keyof WindowEventMap,
  handler: (event: Event) => void,
  element?: RefObject<T>,
) => useCustomEventListener(eventName, handler, element);

export default useEventListener;
