import styled from 'styled-components';
import {
  space, system, color, layout, textStyle,
} from 'styled-system';

const Text = styled('div')(
  color,
  space,
  layout,
  textStyle,
  system({
    fontSize: {
      property: 'fontSize',
      scale: 'fontSizes',
    },
    lineHeight: {
      property: 'lineHeight',
      scale: 'lineHeights',
    },
    textAlign: true,
    letterSpacing: true,
    textTransform: true,
    fontWeight: true,
    whiteSpace: true,
    textOverflow: true,
    overflow: true,
  }),
);

export const Styled = {
  Text,
};
