import styled from 'styled-components';
import {
  space, flexbox, layout,
} from 'styled-system';

const Row = styled.div`
    ${space}
    ${layout}
    ${flexbox}
    
    display: flex;
    flex: 1 1 0;
`;

export const Styled = {
  Row,
};
