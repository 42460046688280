import styled from 'styled-components';

const SupplementaryHotel = styled.div<{ width?: string, height?: string}>`
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 10;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const SupplementaryHotel__Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 3px solid #fff;
  border-radius: ${({ theme }) => theme.radii['br-8']};
  box-shadow: ${({ theme }) => theme.boxShadow[0]};
`;

export const Styled = {
  SupplementaryHotel,
  SupplementaryHotel__Img,
};
