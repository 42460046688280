// @ts-nocheck

import i18n from 'i18next';
import moment from 'moment/moment';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { CONTENT_SERVICE_URL } from '@ess/constants/api';
import * as Sentry from '@sentry/react';

const defaultMaxRetries = 999;
const defaultLanguage = 'en';
const defaultUrl = `${CONTENT_SERVICE_URL}ReactTextsCache`;

type FetchTranslations = {
  url: string
  callback: any
  unstableConnectionSupport: boolean
}

type InitI18n = {
  language?: string
  modules: string[]
  unstableConnectionSupport?: boolean
  serviceUrl?: string
  maxRetries?: number
}

/**
 * Fetch translations request;
 * @param url
 * @param callback
 * @param unstableConnectionSupport
 */
const fetchTranslations = ({ url, callback, unstableConnectionSupport }: FetchTranslations) => {
  fetch(url)
    .then((response) => response.json())
    .then((response) => {
      callback(null, {
        status: 200,
        data: response,
      });

      if (unstableConnectionSupport) {
        window.dispatchEvent(new CustomEvent('UnstableConnectionLoaderHide', {
          detail: { initiator: 'translations' },
        }));
      }
    }).catch((error) => {
      if (unstableConnectionSupport) {
        window.dispatchEvent(new CustomEvent('UnstableConnectionLoaderShow', {
          detail: { initiator: 'translations' },
        }));
      }
      Sentry.captureException(error);
      callback(error, null);
    });
};

export const initI18n = ({
  language = defaultLanguage,
  modules,
  unstableConnectionSupport = false,
  serviceUrl = defaultUrl,
  maxRetries = defaultMaxRetries,
}: InitI18n): Promise<any> => {
  moment.locale(language);

  // eslint-disable-next-line consistent-return
  (async () => {
    try {
      const request = i18n
        .use(HttpApi)
        .use(initReactI18next)
        .use(intervalPlural)
        .init({
          backend: {
            loadPath: `${serviceUrl}/{{lng}}/${modules.join(',')}`,
            request: (options, url, payload, callback) => {
              fetchTranslations({ url, callback, unstableConnectionSupport });
            },
          },
          retryTimeout: 100,
          maxRetries,
          lng: language,
          fallbackLng: false,
          debug: false,
          interpolation: {
            escapeValue: false,
          },
          react: {
            useSuspense: !!language,
          },
        });

      const instance = await request;

      window.i18n = instance;

      return instance;
    } catch (error) {
      console.error(error);
    }
  })();
};
