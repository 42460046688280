import React from 'react';

import { Page } from '@liveroom/layout';

import OfferListSkeleton from '@liveroom/components/OfferList/OfferListSkeleton';

const LoadScreen = () => (
  <Page isLoading>
    <OfferListSkeleton/>
  </Page>
);

export {
  LoadScreen,
};
