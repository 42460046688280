import { SettingsSection, SettingsType } from '../types';

export const searchForm: SettingsSection = {
  dateType: {
    label: 'lbl_form_date',
    type: SettingsType.Select,
    defaultValue: 'Custom.StartDateRange.startDateTo',
    options: [
      {
        label: 'lbl_departure_date',
        value: 'Custom.StartDateRange.startDateTo',
      },
      {
        label: 'lbl_stay_date',
        value: 'Custom.StartDateRange.returnDateTo',
      },
    ],
  },
  priceType: {
    label: 'lbl_form_price',
    searchFormField: 'Custom.FullPrice',
    type: SettingsType.Switch,
    defaultValue: false,
  },
};
