import React, { CSSProperties } from 'react';

import FlexBox from '@ess/ui/FlexBox';

import { Header } from './Header';
import { Footer } from './Footer';

type PageProps = {
  children: React.ReactNode;
  isLoading?: boolean | undefined;
  showFooter?: boolean | undefined;
  pageStyle?: CSSProperties | undefined;
}

const Page = ({
  children,
  isLoading = false,
  showFooter = true,
  pageStyle = {},
}: PageProps) => (
  <FlexBox
    width="100%"
    height="100%"
    minWidth={320}
    flexDirection="column"
    style={{
      background: 'var(--background-color)',
    }}
  >
    <Header/>
    <FlexBox
      p="large"
      flexDirection="column"
      width="100%"
      flexGrow={1}
      style={pageStyle}
    >
      {children}
    </FlexBox>
    {!isLoading && showFooter && <Footer/>}
  </FlexBox>
);

export {
  Page,
};
