/**
 * Locale based array sort function.
 * @param array
 * @param objectProperty
 */

const collator = new Intl.Collator();

export const sortArray = (array: any[], objectProperty?: string) => array.sort((a, b) => {
  const itemA = objectProperty ? a[objectProperty] : a;
  const itemB = objectProperty ? b[objectProperty] : b;

  return collator.compare(itemA, itemB);
});
