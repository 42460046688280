import styled from 'styled-components';

const ModalOverlay = styled.div<{ zIndex: number }>`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: ${({ zIndex }) => zIndex - 1};
`;

export const Styled = {
  ModalOverlay,
};
