import { createStore } from 'zustand';

import { flattenObject } from '@ess/utils';

import { AffiliateConfigService, BasketService } from '@liveroom/services';

import { setCssVariables } from '@liveroom/utils/setCssVariables';

import { Store, Status } from './types';

const createLiveRoomStore = (initProps?: Partial<Store>) => {
  const DEFAULT_PROPS = {
    cssVariablesContainer: '',
    offers: {
      status: 'idle' as Status,
      description: '',
      error: '',
      items: [],
    },
    config: {
      status: 'idle' as Status,
      host: '',
      agency: null,
      theme: null,
      settings: null,
    },
  };

  return createStore<Store>((set, getState) => ({
    ...DEFAULT_PROPS,
    ...initProps,
    setOffers: (offers: any) => set({ offers }),
    fetchConfig: async (affiliateId: string) => {
      if (affiliateId) {
        try {
          const container = getState().cssVariablesContainer;
          const service = new AffiliateConfigService(affiliateId);

          set((state) => ({
            config: {
              ...state.config,
              status: 'loading',
            },
          }));

          const { affiliateConfig, IbeHost } = await service.fetch();

          setCssVariables({ params: affiliateConfig.theme.colors, suffix: 'color', container });
          setCssVariables({ params: flattenObject(affiliateConfig.theme.border), container });

          set((state) => ({
            config: {
              ...state.config,
              ...affiliateConfig,
              host: IbeHost,
              status: 'ready',
            },
          }));
        } catch (error) {
          console.error(error);
          set((state) => ({
            config: {
              ...state.config,
              status: 'error',
            },
          }));
        }
      }
    },
    fetchOffers: async ({ params, config }) => {
      try {
        const host = getState().config.host;
        const basket = new BasketService(config);

        set((state) => ({
          offers: {
            ...state.offers,
            status: 'loading',
          },
        }));

        const data = await basket.fetchOffers(params, host);

        set((state) => ({
          offers: {
            ...state.offers,
            items: data?.items ?? [],
            description: (params.compareOffers ? '' : data?.description) ?? '',
            compareOffers: params.compareOffers,
            name: data?.name ?? '',
            status: 'ready',
          },
        }));
      } catch (error) {
        console.error(error);
        set((state) => ({
          offers: {
            ...state.offers,
            status: 'error',
          },
        }));
      }
    },

  }));
};

export {
  createLiveRoomStore,
};
